import {AdInputPartial, InputValues, Platform, QuoraCreativeType} from 'titan-ads/lib/types';
import {getInputsByPlatform, getPartialKey, getPartialKeyByTitle} from 'titan-ads/lib/utils';
import {IAdInputProps} from '../types';
import {AdInput} from './AdInput';
import './InputGroup.scss';
import style from './InputGroup.scss.json';
import { useContext } from 'react';
import { InputsContext } from '../contexts/AdInputs';

interface InternalVariables
  extends Omit<IAdInputProps, 'className' | 'id' | 'inputData' | 'disabled'> {
  creatingAdSet?: boolean;
  creatingCampaign?: boolean;
  initialValues?: InputValues;
  platform?: Platform;
}

export function InternalVariables({
  creatingAdSet = false,
  creatingCampaign = false,
  initialValues,
  platform = 'quora',
  ...inputVariables
}: InternalVariables) {
  const inputOptions = getInputsByPlatform(platform);
  const {inputGroups, handleEditInput} = useContext(InputsContext);

  // get creative type
  const creativeType: QuoraCreativeType = inputGroups[inputVariables.groupId].inputs[
    getPartialKeyByTitle(platform, 'Creative Type')
  ] as QuoraCreativeType;

  // show Answer and Question fields if creative type is 'PA'
  if (creativeType !== 'PA') {
    inputOptions.forEach((partial) => {
      if (partial.title === 'Answer' || partial.title === 'Question') {
        partial.internal = false;
      }
    });
  }

  return (
    <>
      <div className={style.subGrid}>
        {inputOptions.map((partial: AdInputPartial, i) => {
          if (!partial.internal) return <></>;

          const belongsToPreexistingEntity =
            (partial.column === 'adset' && !creatingAdSet) ||
            (partial.column === 'campaign' && !creatingCampaign);

          let initialValue;
          if (initialValues) {
            initialValue = initialValues[getPartialKey(partial)];
          }

          return (
            <AdInput
              {...inputVariables}
              disabled={belongsToPreexistingEntity}
              key={`${platform}-${i}`}
              className={style[partial.column]}
              id={getPartialKey(partial)}
              inputData={partial}
              initialValue={initialValue}
            />
          );
        })}
      </div>
    </>
  );
}
