import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import './ActionDropdown.scss';
import style from './ActionDropdown.scss.json';
import { ExecutableAction } from 'titan-ads/lib/shapes/ExecutableAction';
import { Person } from 'lincd-schema/lib/shapes/Person';
import { URI } from 'lincd/lib/utils/URI';
import { NamedNode } from 'lincd/lib/models';
import { Thing } from 'lincd-schema/lib/shapes/Thing';
import {Shape} from 'lincd/lib/shapes/Shape';
import {ActionConfig} from '../hooks/useBatchActions';
import {getPersonFromUsername} from '../utils/person';
import {DynamicObject} from '../utils';

export interface SelectedAction {
  value: string;
  method: string;
  label: string;
}

interface ActionDropDownProps {
  actions: ActionConfig;
  additionalData?: any; // New prop to accept additional data
  selectedRowData?: DynamicObject[];
  onComplete?: () => void;
}

const ActionDropdown: React.FC<ActionDropDownProps> = ({ actions, additionalData, selectedRowData, onComplete }) => {
  const [selectedBatchAction, setSelectedBatchAction] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<SelectedAction>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  const loadFormDataFromLocalStorage = (selectedAction) => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      const parsedData = JSON.parse(savedFormData);

      setFormData((prev) => ({
        ...prev,
        [selectedAction]: {
          ...prev[selectedAction] || {},
          userName: parsedData.userName,
        },
      }));
    }
  };

  const handleActionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;

    // Parse the JSON string into an object
    const selectedAction = JSON.parse(selectedValue);

    setSelectedAction(selectedAction);
    setSelectedBatchAction(selectedValue); // Set selected value
    loadFormDataFromLocalStorage(selectedAction.value); // Load saved form data
    setShowModal(true);
  };


  const handleInputChange = (actionValue: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    let value:any = e.target.value;
    //handle checkbox -> boolean
    if(e.target.type =='checkbox') {
      value = (e.target as HTMLInputElement).checked;
    }
    setFormData((prev) => ({
      ...prev,
      [actionValue]: {
        ...prev[actionValue],
        [name]: value,
      },
    }));
  };
  

  const handleSubmit = () => {
    const action = actions.actions.find((a) => a.value === selectedAction?.value);

    if (action) {
       const { notes, userName,...restFormData} = formData[action.value];

       const execAction = new ExecutableAction();
       selectedRowData.forEach(row => {
         const objectNode = NamedNode.getOrCreate(row.id)
         const newThing = new Thing(objectNode);
         newThing.save();
         execAction.objects.add(newThing);
       });
        execAction.agent = getPersonFromUsername(userName);
        execAction.method = selectedAction.method;
        execAction.startTime = new Date();
        execAction.description = notes ?? '';
        execAction.actionStatus = 0 //zero for pontential status;
        if(actions.targetShape)
        {
          execAction.targetShape = actions.targetShape;
        }

        if(action.onSubmit)
        {
          action.onSubmit(restFormData,execAction);
        }
        execAction.save();

        if (onComplete) {
          onComplete(); // Trigger onComplete after successful submit
        }
    }

    // Destructuring to exclude statusGuidance and notes when saving to localStorage
    const filteredLocalData = {
        userName: formData[action.value]?.userName
    };
    localStorage.setItem('formData', JSON.stringify(filteredLocalData));

    // Reset state after handling all actions
    setSelectedAction(null);
    setFormData({});
    setShowModal(false);
    setSelectedBatchAction('');
    alert('Your request is successfully created!');
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAction(null);
    setFormData({});
    setSelectedBatchAction('');
  };

  const currentActions = actions.actions.filter((a) => selectedAction?.value === a.value);

  return (
    <>
      <select className={style.batchActions} onChange={handleActionChange} value={selectedBatchAction}>
        <option value="">Select Action</option>
        {actions.actions.map((action) => (
          // Serialize the action object as a JSON string
          <option key={action.value} value={JSON.stringify({ value: action.value, label: action.label, method: action.method })}>
            {action.label}
          </option>
        ))}
      </select>

      {showModal && currentActions.length > 0 && (
        <Modal show={showModal} onClose={handleCloseModal} title={selectedAction.label}>
          <form className={style.actionForm} onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            {currentActions.map((currentAction) => (
              <div key={currentAction.value}>
                {/* <h3>{currentAction.label}</h3> */}
                {currentAction.fields.map((field) => {
                  let currentValue;
                  //get current value from form data if exists
                  if(typeof formData[currentAction.value]?.[field.name] !== 'undefined')
                  {
                    currentValue = formData[currentAction.value]?.[field.name];
                  } else if (selectedRowData.length === 1) {
                    //if there is only one row selected, get the value from the selected row data (this is the current value in the database)
                    currentValue = selectedRowData[0][field.name];
                  } else {
                    currentValue = '';
                  }
                  return (
                  <div key={field.name} className={style.formGroup}>
                    <label htmlFor={field.name}>{field.label}</label>
                    <div>
                      {field.type === 'select' && (
                        <select
                          id={field.name}
                          name={field.name}
                          className={style.selectInput}
                          value={currentValue}
                          onChange={(e) => handleInputChange(currentAction.value, e)}
                          required
                        >
                          <option value="">Select {field.label}</option>
                          {field.options?.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}

                      {field.type === 'textarea' && (
                        <textarea
                          className={style.textareaInput}
                          id={field.name}
                          name={field.name}
                          value={currentValue}
                          onChange={(e) => handleInputChange(currentAction.value, e)}
                          placeholder={field.placeholder || 'Type here'}
                        />
                      )}

                      {(field.type === 'text' ||  field.type === 'number') && (
                        <input
                          id={field.name}
                          name={field.name}
                          type={field.type}
                          placeholder={field.placeholder || 'Type here'}
                          value={currentValue}
                          onChange={(e) => handleInputChange(currentAction.value, e)}
                          className={style.textInput}
                          required
                        />
                      )}
                      {field.type === 'boolean' && (
                        <input
                          id={field.name}
                          name={field.name}
                          type="checkbox"
                          checked={currentValue && true}
                          onChange={(e) => handleInputChange(currentAction.value, e)}
                        />
                      )}
                    </div>
                  </div>
                )})}
              </div>
            ))}
            <div className={style.buttonGroup}>
              <button type="button" onClick={handleCloseModal}>
                Cancel
              </button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default ActionDropdown;
