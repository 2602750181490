import { Thing } from 'lincd-schema/lib/shapes/Thing';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';
import {NamedNode} from 'lincd/lib/models';

function getAutomation(id: string) {
  //force temporary node (not saving updates)
  let node = NamedNode.getOrCreate(process.env.DATA_ROOT + '/automations/' + id,true);
  node.isTemporaryNode = true;
  return new Thing(node);
}
export const automation1 = getAutomation('1');
automation1.identifier = 'automation1';
automation1.name = 'A1 - Reset bid amounts';
automation1.description = '1am - 6am: Increase bid with 10% if clicks = 0. Decrease bid with 10% if clicks > 25 & sales = 0';
automation1.disambiguatingDescription = 'adset'; //to who this automation is intended for

export const automation2 = getAutomation('2')
automation2.identifier = 'automation2';
automation2.name = 'A2 - Limit Ad Spend';
automation2.description = 'Pause ads if spend is 90% of payout';
automation2.disambiguatingDescription = 'ad';

export const automations = new ShapeSet<Thing>([
    automation1,
    automation2
])
