import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {Suspense, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {QUORA_OPT_CTA, QuoraAdType, QuoraCTA} from 'titan-ads/lib/types';
import AdsPreview from '../components/AdsPreview';
import ConstraintComponent from '../components/ConstraintComponent';
import InternalReport from '../components/InitialReport';
import MediaUploader from '../components/MediaUploader';
import VariationComponent from '../components/VariationComponent';
import {DefaultLayout} from '../layout/DefaultLayout';
import './Quicklaunch.scss';
import style from './Quicklaunch.scss.json';
import {MasterURL} from '../components/MasterURL';
import {VariationContent} from '../types';
import {BrandAccountSelector} from '../components/RefreshQuoraData';
import {useSuspense} from '../utils';
import {BrandAccount} from 'titan-ads/lib/shapes/TitanShapes';
import {DashboardContext} from '../contexts/Dashboard';
import { QUICKLAUNCH_DATA } from '../utils/data';
import { ImageObject } from 'lincd-schema/lib/shapes/ImageObject';

const initialAccounts = useSuspense(BrandAccount.loadAll());

export default function QuickLaunch() {
  const [media, setMedia] = useState<any>([]);
  const [brandProperty, setBrandProperty] = useState<string>('');
  const [businessName, setBusinessName] = useState<string>('');
  const [sourceLogo, setSourceLogo] = useState<string>('');
  const [answerOnline, setAnswerOnline] = useState<string[]>(['']);
  // const [campaignAds, setCampaignAds] = useState({campaign: null, adset: null});
  const [mediaType, setMediaType] = useState<QuoraAdType[]>(['image_ad']);
  const [headlines, setHeadlines] = useState<{text; description}[]>([
    {text: '', description: ''},
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [subHeadlines, setSubHeadlines] = useState<{text; description}[]>([
    {text: '', description: ''},
  ]);
  const [dataForm, setFormData] = useState({});
  const [ctaOptions, setCtaOptions] = useState<QuoraCTA[]>(['Learn More']);
  const [saving, setSaving] = useState(false);
  const {curAccount, masterUrl, setMasterUrl} = useContext(DashboardContext);
  const [variant, setVariant] = useState<string>('1'); // default variant for test data
  const accountsResource = initialAccounts;

  const [constraintAdSets, setConstraintAdSets] = useState<ShapeSet<AdSet>>(
    new ShapeSet(),
  );

  function createPayload(
    mediaAds,
    headlinesAds,
    subHeadlinesAds,
    ctaOptionsAds,
    answerAds,
  ) {
    const flattenedMedia = mediaAds.flat();
    const maxLength = Math.max(
      mediaAds.length,
      headlinesAds.length,
      subHeadlinesAds.length,
      ctaOptionsAds.length,
      answerAds.length,
    );

    const variationContent: VariationContent = {
      media: [],
      logo: [],
      headlines: [],
      subHeadlines: [],
      cta: [],
      answer: [],
    };

    for (let i = 0; i < maxLength; i++) {
      const mediaItem = flattenedMedia[i];
      const headlineItem = headlinesAds[i];
      const subHeadlinesItem = subHeadlinesAds[i];
      const ctaOptionItem = ctaOptionsAds[i];
      const answerItem = answerAds[i];

      if (mediaItem && mediaItem?.data) {
        if (mediaItem.data.usageInfo === 'logo') {
          variationContent.logo.push({
            id: i + 1,
            type: mediaItem.data.usageInfo,
            uri: mediaItem.data.uri || '',
            quoraCDN: mediaItem.data.url || '',
            logoId: mediaItem.data.identifier || '',
            name: mediaItem.data.name || '',
          });
        } else {
          variationContent.media.push({
            id: i + 1,
            type: mediaItem.data.usageInfo || '',
            uri: mediaItem.data.uri || '',
            quoraCDN: mediaItem.data.url || '',
            // Maybe don't need logoId?
            logoId: mediaItem.data.identifier || '',
            name: mediaItem.data.name || '',
          });
        }
      }
      if (headlineItem) {
        variationContent.headlines.push({
          id: i + 1,
          ...headlineItem,
        });
      }
      if (subHeadlinesItem) {
        variationContent.subHeadlines.push({
          id: i + 1,
          ...subHeadlinesItem,
          businessName,
        });
      }
      if (ctaOptionItem) {
        variationContent.cta.push({
          id: i + 1,
          text: ctaOptionItem || 'Learn More',
        });
      }
      if (answerItem) {
        variationContent.answer.push({
          id: i + 1,
          text: answerItem || '',
        });
      }
    }

    return [variationContent];
  }

  // useEffect(() => {
  //   Campaign.loadAll().then((campaign) => {
  //     if (campaign) {
  //       setCampaignAds({campaign: campaign, adset: {}});
  //       AdSet.loadAll().then((adset) => {
  //         if (adset) {
  //           setCampaignAds((prevAds) => ({...prevAds, adset: adset}));
  //         }
  //       });
  //     }
  //   });
  // }, []);

  function generateOutput(variations: VariationContent[]) {
    const output = [];
    const videoHashRegex = /-(\w+)\./g;

    variations.forEach((adVariation) => {
      const logoId = parseInt(adVariation.logo.map((item) => item.logoId)[0]);

      adVariation.headlines.forEach((headlineItem) => {
        adVariation.subHeadlines.forEach((subHeadlineItem) => {
          adVariation.cta.forEach((ctaItem) => {
            if (adVariation.media.length > 0) {
              adVariation.media.forEach((mediaItem) => {
                output.push({
                  adSetId: 316659353890846,
                  name: 'Example Ad Name',
                  adType:
                    mediaItem.type === 'image'
                      ? 'image_ad'
                      : mediaItem.type === 'video'
                        ? 'video_ad'
                        : mediaItem.type === 'text'
                          ? 'text_ad'
                          : 'promoted_answer',
                  businessName,
                  title: headlineItem.text,
                  contentText: subHeadlineItem.text,
                  cta: ctaItem.text.toLowerCase().replace(' ', '_'),
                  urlTemplate: 'https://example.com',
                  answerLinkUtmsTemplate: '',
                  imageUrl:
                    mediaItem.type === 'image' ? mediaItem.quoraCDN : null,
                  videoHash:
                    mediaItem.type === 'video'
                      ? videoHashRegex.exec(mediaItem.quoraCDN)[1]
                      : null,
                  answerUrl: '',
                  logoId,
                  leadGenFormId: null,
                  thirdPartyTrackerList: [],
                });
              });
            } else {
              output.push({
                adSetId: 316659353890846,
                name: 'Example Ad Name',
                adType: 'image_ad', // Default ad type if no media
                businessName,
                title: headlineItem.text,
                contentText: subHeadlineItem.text,
                cta: ctaItem.text.toLowerCase().replace(' ', '_'),
                urlTemplate: 'https://example.com',
                answerLinkUtmsTemplate: '',
                logoId,
                leadGenFormId: null,
                thirdPartyTrackerList: [],
              });
            }
          });
        });
      });

      if (adVariation.answer.length > 0) {
        adVariation.answer.forEach((answerItem) => {
          output.push({
            adSetId: 316659353890846,
            name: 'Example Ad Name',
            adType: 'promoted_answer',
            businessName,
            urlTemplate: 'https://example.com',
            answerLinkUtmsTemplate: '',
            answerUrl: answerItem.text,
            logoId,
            leadGenFormId: null,
            thirdPartyTrackerList: [],
          });
        });
      }
    });

    return output;
  }

  // Generate the output
  const variationContent = createPayload(
    media,
    headlines,
    subHeadlines,
    ctaOptions,
    answerOnline,
  );
  // detect changes on media type and set a preview of ads
  let output = [];
  if (media || headlines || ctaOptions || subHeadlines || answerOnline) {
    output = generateOutput(variationContent);
  }

  const handleAddHeadline = () => {
    setHeadlines((prevHeadlines) => [
      ...prevHeadlines,
      {text: '', description: ''},
    ]);
  };

  const handleRemoveHeadline = (index: number) => {
    setHeadlines((prevHeadlines) =>
      prevHeadlines.filter((_, i) => i !== index),
    );
  };
  const handleRemoveBodyText = (index: number) => {
    setSubHeadlines((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveAnswer = (index: number) => {
    setAnswerOnline((prevAnswer) => prevAnswer.filter((_, i) => i !== index));
  };

  const handleHeadlineChange = (
    index: number,
    value: string,
    isDescription: boolean = false,
  ) => {
    const newHeadlines = [...headlines];
    if (!(index in newHeadlines)) {
      newHeadlines[index] = {text: '', description: ''};
    }
    if (isDescription) {
      newHeadlines[index] = {...newHeadlines[index], description: value};
    } else {
      newHeadlines[index] = {...newHeadlines[index], text: value};
    }
    setHeadlines(newHeadlines);
  };

  const handleAddBodyText = () => {
    setSubHeadlines((prevBodyTexts) => [
      ...prevBodyTexts,
      {text: '', description: ''},
    ]);
  };

  const handleAddAnswer = () => {
    const lastAnswer = answerOnline[answerOnline.length - 1];
    if (lastAnswer && !lastAnswer.includes('?')) {
      setAnswerOnline((prevAnswer) => [...prevAnswer, lastAnswer + '.']);
    } else {
      setAnswerOnline((prevAnswer) => [...prevAnswer, '']);
    }
  };

  const handleRemoveCtaOption = (index: number) => {
    setCtaOptions((prevCtaOptions) =>
      prevCtaOptions.filter((_, i) => i !== index),
    );
  };

  const handleBodyTextChange = (
    index: number,
    value: string,
    isDescription: boolean = false,
  ) => {
    const newSubHeadlines = [...subHeadlines];
    if (!(index in newSubHeadlines)) {
      newSubHeadlines[index] = {text: '', description: ''};
    }
    if (isDescription) {
      newSubHeadlines[index] = {...newSubHeadlines[index], description: value};
    } else {
      newSubHeadlines[index] = {...newSubHeadlines[index], text: value};
    }
    setSubHeadlines(newSubHeadlines);
  };

  const handleAddCtaOption = () => {
    setCtaOptions((prevCtaOptions) => [
      ...prevCtaOptions,
      QUORA_OPT_CTA.filter((option) => !prevCtaOptions.includes(option)).pop(),
    ]);
  };

  const handleMediaOptionChange = (
    index: number,
    value: QuoraAdType,
    bizName: string,
  ) => {
    const newMediaType = [...mediaType];
    newMediaType[index] = value;
    setMedia([]);
    setHeadlines([{text: '', description: ''}]);
    setSubHeadlines([{text: '', description: ''}]);
    setCtaOptions(['Learn More']);
    setBusinessName(bizName);
    setAnswerOnline(['']);
    setMediaType(newMediaType);
  };

  const handleAnswerChange = (index: number, value: string) => {
    const newAnswerOnline = [...answerOnline];
    newAnswerOnline[index] = value;
    setMedia([]);
    setAnswerOnline(newAnswerOnline);
  };

  const handleCtaOptionChange = (index: number, value: QuoraCTA) => {
    const newCtaOptions = [...ctaOptions];
    newCtaOptions[index] = value;
    setCtaOptions(newCtaOptions);
  };

  const handleSubmit = (mediaUpload) => {
    setMedia(mediaUpload);
    setSourceLogo(mediaUpload[0]?.fileName);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? generateOutput(variationContent).length - 1
        : prevIndex - 1,
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === generateOutput(variationContent).length - 1
        ? 0
        : prevIndex + 1,
    );
  };

  const handleData = (value) => {
    setFormData(value);
  };

  // get random item for generate test data
  const getRandomItem = (items) => items[Math.floor(Math.random() * items.length)];
  
  // sort to get images not duplicated
  const getUniqueRandomItems = (items: string[], count: number) => {
    const shuffled = items.slice().sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  // generate test data for quick launch
  // currently only for LINCD business name
  const handleGenerateTestData = async () => {  
    // choose business name LINCD
    const selectedBusinessName = QUICKLAUNCH_DATA.find((data) => data.businessName === curAccount.shortName);
    setMasterUrl(selectedBusinessName.link + '?');
    setBusinessName(selectedBusinessName.businessName);

    // variant 1: 1 image, 1 headline, 1 subheadline, 1 call to action
    if (variant === '1') {
      const headlines = getRandomItem(selectedBusinessName.headlines);
      if(headlines.headline.length > 65) {
        headlines.headline = headlines.headline.slice(0, 65) + '...';
        alert('WARNING - Headline of test data is too long, it has been truncated to 65 characters.');
      }
      setHeadlines([{text: headlines.headline, description: headlines.description}]);

      const sublines = getRandomItem(selectedBusinessName.sublines);
      if(sublines.subline.length > 105) {
        sublines.subline = sublines.subline.slice(0, 105) + '...';
        alert('WARNING - Subheadline of test data is too long, it has been truncated to 105 characters.');
      }
      setSubHeadlines([{text: sublines.subline, description: sublines.description}]);
      
      const ctaText = getRandomItem(selectedBusinessName.actions) || 'Learn More';
      setCtaOptions([ctaText]);
      
      // get the image from the random item
      const randomImage = getRandomItem(selectedBusinessName.images);
      const image = ImageObject.getLocalInstances().find((img) => img.name === randomImage);
      setMedia(image ? [{ data: image }] : []);
      
      // variant 12: 4 headlines and 3 images, but only 1 subheadline, and 1 call to action.
    } else if (variant === '12') {
      const headlines = Array.from({ length: 4 }, () => getRandomItem(selectedBusinessName.headlines));
      setHeadlines(headlines.map(h => ({ text: h.headline, description: h.description })));

      const subline = getRandomItem(selectedBusinessName.sublines);
      setSubHeadlines([{ text: subline.subline, description: subline.description }]);

      const ctaText = getRandomItem(selectedBusinessName.actions) || 'Learn More';
      setCtaOptions([ctaText]);

      const images = getUniqueRandomItems(selectedBusinessName.images, 3);
      const media = images.map(img => ImageObject.getLocalInstances().find((image) => image.name === img)).filter(Boolean);
      setMedia(media.length ? media.map(img => ({ data: img })) : []);

      // variant 96: not implemented yet
    } else if (variant === '96') {
      alert('Variant 96 not implemented yet.');
    }
  }

  const hasChosenAccount = !!curAccount;

  return (
    <DefaultLayout>
      <h1>Quick Launch</h1>
      <div className={style.quickLaunchContainer}>
        {!saving && (
          <>
            <Suspense fallback={<h1>Loading...</h1>}>
              <BrandAccountSelector qAccounts={accountsResource} />
              {hasChosenAccount && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'remote_dev') && (
                <div className={style.testDataBlock}>
                  <button onClick={handleGenerateTestData} disabled={variant === '96'}>Test Data</button>
                  <div>
                    <label htmlFor="variant">
                      Choose 
                      <select name="variant" id="variant" onChange={(e) => {
                        setVariant(e.target.value);
                      }}>
                        <option value="1">Variant 1 (1 headlines, 1 image, 1 subheadline, 1 CTA)</option>
                        <option value="12">Variant 12 (4 headlines, 3 images, 1 subheadline, 1 CTA)</option>
                        <option value="96">Variant 96 (Not implement yet)</option>
                      </select>
                    </label>
                  </div>
                </div>
              )}
            </Suspense>
            <MasterURL />
            {hasChosenAccount && (
              <>
                <h2>Internal Variables</h2>
                <InternalReport
                  onMediaReady={handleSubmit}
                  // campaignData={campaignAds}
                  source={sourceLogo}
                  cta={ctaOptions}
                  fileInputRef={null}
                  onHandleSubmit={handleData}
                  mediaType={'image_ad'}
                />
                <MediaUploader
                  mediaType={mediaType}
                  handleSubmit={handleSubmit}
                  handleMediaOptionChange={handleMediaOptionChange}
                  media={media}
                  isQuickLaunched={true}
                  onMediaReady={handleSubmit}
                  initBizName={businessName}
                />
                <h2>Variation Content</h2>
                <VariationComponent
                  mediaType={mediaType}
                  headlines={headlines}
                  subHeadlines={subHeadlines}
                  ctaOptions={ctaOptions}
                  answerOnline={answerOnline}
                  handleHeadlineChange={handleHeadlineChange}
                  handleBodyTextChange={handleBodyTextChange}
                  handleCtaOptionChange={handleCtaOptionChange}
                  handleAnswerChange={handleAnswerChange}
                  handleAddHeadline={handleAddHeadline}
                  handleAddBodyText={handleAddBodyText}
                  handleAddCtaOption={handleAddCtaOption}
                  handleAddAnswer={handleAddAnswer}
                  handleRemoveHeadline={handleRemoveHeadline}
                  handleRemoveBodyText={handleRemoveBodyText}
                  handleRemoveCtaOption={handleRemoveCtaOption}
                  handleRemoveAnswer={handleRemoveAnswer}
                />
                <h2>Choose Constraint</h2>
                <p>
                  Want to create a{' '}
                  <Link to={'/admin/templates'}>
                    <b style={{textDecoration: 'underline'}}>new template?</b>
                  </Link>
                </p>
                <ConstraintComponent
                  constraintAdSets={constraintAdSets}
                  setConstraintAdSets={setConstraintAdSets}
                />
              </>
            )}
          </>
        )}

        {output.length > 0 && curAccount && (
          <AdsPreview
            constraintAdSets={constraintAdSets}
            currentIndex={currentIndex}
            data={dataForm}
            businessName={businessName}
            saving={saving}
            setSaving={setSaving}
            brandProperty={curAccount.name}
            variationContent={variationContent}
            generateOutput={generateOutput}
            goToPrev={goToPrev}
            goToNext={goToNext}
          />
        )}
      </div>
    </DefaultLayout>
  );
}
