import {Link,useSearchParams} from 'react-router-dom';
import {ROUTES} from '../routes';
import React,{useEffect,useState} from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import {ExecutableAction} from 'titan-ads/lib/shapes/ExecutableAction';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {DynamicObject} from '../utils';
import ShapeDetail from '../components/ShapeDetail';
import {Spinner} from '../components/Spinner';
import { packageName } from '../package';
import './ApprovalDetailsPage.scss';
import * as style from './ApprovalDetailsPage.scss.json'
import ApprovalActionDropdown from '../components/ApprovalActionDropdown';
import {AdSetDetails} from './AdSetManagementDetail';

function ApprovalDetailsPage()
{
  const [searchparams,setSearchParams] = useSearchParams();
  const id = searchparams.get('id');
  const [identifier,setIdentifier] = useState<string>(id.split('/').pop());
  const [data,setData] = useState<DynamicObject>(null);

  useEffect(() => {
    Server.call(packageName,'loadExecutableAction',id).then((data) => {
      setData(data);
    });
  },[id]);

  return (
    <DefaultLayout>
      <h1><Link to={ROUTES.views.path}>Views</Link> &raquo; <Link to={ROUTES.view_detail.path.replace(':detail','approval-page')}>Approvals</Link> &raquo; {identifier}</h1>
      {data ? <>
        <h2>Details of action with pending approval</h2>
        <ShapeDetail data={data} skip={['objects']} />
        <h2>Pending approval applies to the following items:</h2>
        {data.objects?.map((object) => {
          let content;
          if (object.type === 'ad')
          {
            content = <>
              <h3>Ad</h3>
              <div><ShapeDetail data={object.data} /></div>
            </>;
          }
          if (object.type === 'adset')
          {
            content = <>
              <h3>AdSet</h3>
              <div><AdSetDetails data={object.data} id={object.data.id} /></div>
              {/*<ShapeDetail data={object.data} />*/}
            </>;
          }
          if (object.type === 'campaign')
          {
            content = <>
              <h3>Campaign</h3>
              <div><ShapeDetail data={object.data} /></div>
            </>;
          }
          return <div className={style.object}>{content}</div>;
          })}
        <ApprovalActionDropdown buttons={true} selectedRows={[data.id]} onComplete={() => window.history.go(-1)} />
      </> : <Spinner />}

    </DefaultLayout>
  );
}

export default ApprovalDetailsPage;