import {NamedNode} from 'lincd/lib/models';
import {useContext, useEffect, useState} from 'react';
import {AdSet, Campaign, BaseShape} from 'titan-ads/lib/shapes/TitanShapes';
import {InputType} from 'titan-ads/lib/types';
import {getPartialKeyByTitle} from 'titan-ads/lib/utils';
import {InputsContext} from '../contexts/AdInputs';
import {IAdInputProps} from '../types';
import './AdInput.scss';
import style from './AdInput.scss.json';
import {Placement, DeviceAndBrowser} from './ConstraintInput';

const fallbackDefaults = {
  adSet: {},
  campaign: undefined,
  creatingAdSet: true,
  creatingCampaign: true,
  pasted: false,
  platform: undefined,
};

export function AdInput({
  className,
  disabled,
  groupId,
  handleNextPriority,
  id,
  initialValue,
  inputData,
  keys,
}: IAdInputProps) {
  const {handleEditInput, inputGroups} = useContext(InputsContext);
  const {adSet, campaign, creatingAdSet, creatingCampaign, pasted, platform} =
    inputGroups[groupId] ?? fallbackDefaults;
  const {
    checked,
    column,
    dataKeys,
    date,
    forbidDash,
    hidden,
    options,
    pendValue = () => false,
    shapePath,
    title,
  } = inputData;

  let {type, maxlength} = inputData;

  // const checkCampaignBudget = () => {
  //   const dailyBudget =
  //     inputs[getPartialKeyByTitle(platform, 'Daily Budget ($US)')];
  //   const lifeTimeBudget =
  //     inputs[getPartialKeyByTitle(platform, 'Lifetime Budget ($US)')];
  //   //Enforce user input so that daily budget is always smaller than lifetime budge from campaignInput
  //   if (
  //     parseFloat(dailyBudget as string) > parseFloat(lifeTimeBudget as string)
  //   ) {
  //     setValue('');
  //     // handleEditInput(groupId, inputData, '');
  //     alert('Lifetime Budget should be larger than Daily Budget');
  //     return;
  //   }
  // };
  const checkCampaignBudget = () => {
    const dailyBudget = inputs[getPartialKeyByTitle(platform, 'Daily Budget ($US)')];
    const lifeTimeBudget = inputs[getPartialKeyByTitle(platform, 'Lifetime Budget ($US)')];
  
    // Convert budget values to float
    const dailyBudgetValue = parseFloat(dailyBudget as string);
    const lifeTimeBudgetValue = parseFloat(lifeTimeBudget as string);
  
    // Check if budgets are lower than $5
    if (dailyBudgetValue < 5 || lifeTimeBudgetValue < 5) {
      setValue('');
      alert('Both Daily Budget and Lifetime Budget should be at least $5.');
      return;
    }
  
    // Enforce that daily budget is always smaller than lifetime budget
    if (dailyBudgetValue > lifeTimeBudgetValue) {
      setValue('');
      alert('Lifetime Budget should be larger than Daily Budget.');
      return;
    }
  };

  // Need this logic for when duplicating ads
  const [isChecked, setChecked] = useState<boolean>(
    [true, false].includes(initialValue as any)
      ? Boolean(initialValue)
      : checked,
  );
  const [value, _setValue] = useState<InputType>(
    initialValue || (options ? options[0] : '') || isChecked,
  );

  // separate setValue function for better debugging
  const setValue = (v: any) => {
    // console.log('Set value:',v);
    _setValue(v);
  }

  const isCheckbox = typeof checked === 'boolean';

  useEffect(() => {
    handleEditInput(groupId, inputData, value);

    // Update the global adset for other input groups to read;
    // used for live updates to other input groups - updating one field
    // should reflect across all other input groups using the same adset
    if (creatingAdSet && column === 'adset' && shapePath instanceof NamedNode) {
      if (typeof value === 'undefined') return;

      const propShapes = AdSet.shape
        .getPropertyShapes()
        .addFrom(BaseShape.shape.getPropertyShapes());
      const prop = propShapes.find((ps) => ps.path === inputData.shapePath);
      adSet[prop.label] = value;
    } else if (
      creatingCampaign &&
      column === 'campaign' &&
      shapePath instanceof NamedNode
    ) {
      if (typeof value === 'undefined') return;

      const propShapes = Campaign.shape
        .getPropertyShapes()
        .addFrom(BaseShape.shape.getPropertyShapes());
      const prop = propShapes.find((ps) => ps.path === inputData.shapePath);
      campaign[prop.label] = value;
    }
  }, [value]);

  // To fetch updates from other adsets or campaigns which have just been created
  useEffect(() => {
    if (
      !creatingAdSet &&
      column === 'adset' &&
      shapePath instanceof NamedNode
    ) {
      if (!adSet) return;

      const propShapes = AdSet.shape
        .getPropertyShapes()
        .addFrom(BaseShape.shape.getPropertyShapes());
      const prop = propShapes.find((ps) => ps.path === inputData.shapePath);
      setValue(adSet[prop.label]);
    } else if (
      !creatingCampaign &&
      column === 'campaign' &&
      shapePath instanceof NamedNode
    ) {
      if (!campaign) return;

      const propShapes = Campaign.shape
        .getPropertyShapes()
        .addFrom(BaseShape.shape.getPropertyShapes());
      const prop = propShapes.find((ps) => ps.path === shapePath);
      setValue(campaign[prop.label]);
    }
  }, [inputGroups]);

  if (!type) {
    type = 'text';
    if (isCheckbox) {
      type = 'checkbox';
    } else if (date) {
      type = 'date';
    }
  }

  const currentInput = inputGroups[groupId].inputs[id];
  useEffect(() => {
    // Always formatted yyyy-MM-dd:
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
    if (type == 'date') {
      const nowUTCms = Date.now();
      const nowUTC = new Date(nowUTCms);

      const nowPTdatetime = Intl.DateTimeFormat('en-gb', {
        timeZone: 'America/Los_Angeles', // PT (tz aware)
      }).format(nowUTC);

      // "16/05/1999" => "1999-05-16"
      const nowPT = nowPTdatetime.split('/').reverse().join('-');

      setValue(nowPT);
    }

    if (initialValue) {
      setValue(initialValue);
    }
  }, []);

  useEffect(() => {
    if (hidden && currentInput) {
      setValue(currentInput);
    }
  }, [currentInput]);

  if (hidden) {
    return <></>;
  }

  const {inputs} = inputGroups[groupId];
  const key = `${groupId}-${id}-${title}`;

  const valueToDisplay =
    inputs[id] === undefined
      ? initialValue === undefined
        ? ''
        : initialValue
      : inputs[id];

  return (
    <div
      className={`${style.Dropdown} ${className} ${
        type === 'checkbox' ? style.checkbox : ''
      }`}
      style={{
        backgroundColor: pendValue() ? 'tomato' : 'inherit',
      }}
      title={title}
      id={id}
    >
      <label htmlFor={title}>
        {title === 'Male' || title === 'Female' || title === 'Unknown'
          ? ''
          : title}
      </label>

      {/* Render ConstraintComponent or select/input based on conditions */}
      {options ? (
        title === 'Device & Browser' ? (
          <DeviceAndBrowser
            handleEditInput={(groupId, inputData, value, e) => {
              // e.preventDefault();
              handleEditInput(groupId, inputData, value);
            }}
            groupId={groupId}
            inputData={inputData}
            key={key}
            adSet={adSet as AdSet}
          />
        ) : title === 'Placement' ? (
          <Placement
            handleEditInput={(groupId, inputData, value, e) => {
              // e.preventDefault();
              handleEditInput(groupId, inputData, value);
            }}
            groupId={groupId}
            inputData={inputData}
            key={key}
            adSet={adSet as AdSet}
          />
        ) : (
          <select
            key={key}
            name={title}
            id={title}
            disabled={disabled}
            onChange={(e) => {
              handleEditInput(groupId, inputData, e.target.value);
              setValue(e.target.value);
            }}
            value={valueToDisplay as string | number}
          >
            {!valueToDisplay && <option>Choose an option</option>}
            {options?.map((opt, i) => (
              <option key={keys ? keys[i] : i} value={opt}>
                {opt}
              </option>
            ))}
          </select>
        )
      ) : title === 'Male' || title === 'Unknown' || title === 'Female' ? (
        <></>
      ) : (
        <input
          key={key}
          name={title}
          id={title}
          type={type}
          maxLength={maxlength || undefined}
          disabled={disabled}
          checked={
            typeof valueToDisplay === 'boolean'
              ? valueToDisplay
              : valueToDisplay.toString().toLowerCase() === 'true'
          }
          onBlur={(e) => {
            if (
              title === 'Daily Budget ($US)' ||
              title === 'Lifetime Budget ($US)'
            ) {
              checkCampaignBudget();
            }
            if (type === 'url') {
              const isValid = e.target.checkValidity();

              if (!isValid) {
                alert('Please enter a valid URL');

                // Refocus and don't trigger the onBlur event;
                window.setTimeout(() => {
                  e.target.focus();
                }, 0);
              }
            }
          }}
          onChange={(e) => {
            if (isCheckbox) {
              setChecked(!isChecked);
              setValue(!isChecked);
            } else {
              if (e.target.value.includes('-') && forbidDash) {
                alert("The '-' character is forbidden");
                return;
              }
              setValue(e.target.value);
              handleEditInput(groupId, inputData, e.target.value);
            }
          }}
          min={
            type === 'number' ? (title === 'Daily Budget ($US)' ? 5 : 0) : null
          }
          step={type === 'number' ? 0.01 : null}
          // list={title === 'Landing Page Name' ? `${id}` : null}
          value={valueToDisplay as string | number}
        />
      )}
    </div>
  );
}
