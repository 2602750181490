import React,{useCallback, useEffect,useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import {packageName} from '../package';
import {Spinner} from '../components/Spinner';
import {Ad} from 'titan-ads/lib/shapes/TitanShapes';
import {generatePath,Link,useLocation,useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {AdChart} from '../components/AdChart';
import {TimeRangeProps, TimeRangeValue} from '../types';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import {titanAds} from 'titan-ads/lib/ontologies/titan-ads';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import './AdManagementDetail.scss';
import style from './AdManagementDetail.scss.json';
import AdActionDropdown from '../components/AdActionDropdown';
import {DynamicObject} from '../utils';

// add UTC plugin to dayjs to handle UTC date
dayjs.extend(utc);

function AdManagementDetail()
{
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  //TODO:the undefined part is a quick bug fix, for some reaso the browser redirects to the same page with uri_undefined
  // but that should just be prevented
  const uri = queryParams.get('uri') || queryParams.get('uri_undefined');
  if(!uri) {
    navigate(-1);
  }

  // get query params date for single time range
  const date = queryParams.get('date');

  // get query params start and end date for time range
  const startDate = queryParams.get('start');
  const endDate = queryParams.get('end');

  const [adData,setAdData] = useState<DynamicObject>();
  const [engagementMetric,setEngagementMetric] = useState<string>('dailyClicks');
  const [metricData,setMetricData] = useState<DynamicObject>();

  
  // handle how to get initial time range based on query params
  const getInitialTimeRange = useCallback((): TimeRangeProps => {
    const today = dayjs().utc().format('YYYY-MM-DD');
    const yesterday = dayjs().subtract(1, 'day').utc().format('YYYY-MM-DD');
    const last7days = dayjs().subtract(7, 'day').utc().format('YYYY-MM-DD');
    const last14days = dayjs().subtract(14, 'day').utc().format('YYYY-MM-DD');
    const last30days = dayjs().subtract(30, 'day').utc().format('YYYY-MM-DD');

    if (date) {
      if (date === today) {
        return { value: 'today', date: today };
      } else if (date === yesterday) {
        return { value: 'yesterday', date: yesterday };
      } else {
        return { value: 'customSingleDate', date: date };
      }
    } else if (startDate && endDate) {
      if (startDate === last7days && endDate === today) {
        return { value: 'last7days', startDate: last7days, endDate: today };
      } else if (startDate === last14days && endDate === today) {
        return { value: 'last14days', startDate: last14days, endDate: today };
      } else if (startDate === last30days && endDate === today) {
        return { value: 'last30days', startDate: last30days, endDate: today };
      } else {
        return { value: 'customDateRange', startDate: startDate, endDate: endDate };
      }
    }

    // check if day parting tracker
    if (location.pathname === ROUTES.dayPartingTrackerDetail.path) {
      return { value: 'today', date: today };
    }

    return { value: 'last7days', startDate: last7days, endDate: today };
  }, [date, startDate, endDate]);

  // set default time range to last 7 days
  const [timeRange, setTimeRange] = useState<TimeRangeProps>(getInitialTimeRange);

  const [timeRangeOptions, setTimeRangeOptions] = useState<TimeRangeValue[]>([
      { value: 'yesterday', label: 'Yesterday' },
      { value: 'last7days', label: 'Last 7 days' },
      { value: 'last14days', label: 'Last 14 days' },
      { value: 'last30days', label: 'Last 30 days' },
      { value: 'customSingleDate', label: 'Custom single date' },
    ])

  const fetchSingleAdData = async () => {
    if (!uri) return;
    try {
      const data = await Server.call(packageName, 'loadSingleAdData', uri, timeRange);
      if (data) {
        setAdData(data);
      } else {
        setAdData(null);
      }
    } catch (error) {
      console.error('Error fetching Ad data:', error);
      setAdData(null);
    }
  };

  useEffect(() => {
    fetchSingleAdData();
  },[uri]);

  // fetch engagement metric data by uri
  // useEffect(() => {
  //   if (!adData && !uri) return;
  //
  //   Server.call(
  //     packageName,
  //     'getObservationsByShape',
  //     uri,
  //     Ad,
  //     [
  //       titanAds.dailyClicks,
  //       titanAds.dailyImpressions,
  //       titanAds.dailySpend
  //     ],
  //     timeRange
  //   )
  //     .then(data => {
  //       setMetricData(data)
  //     })
  //     .catch(error => console.error('error fetching metric data:',error));
  // },[timeRange,adData,uri]);

  // handle breadcrumb for detail AOR and DPT pages
  const getBreadcrumb = (pathname: string) => {
    switch (pathname) {
      case ROUTES.adOptimizationReportDetail.path:
        return {
          path: generatePath(ROUTES.view_detail.path, { detail: 'ad-optimization-report' }),
          label: 'Ad Optimization Report'
        };
      default:
        return {
          path: generatePath(ROUTES.view_detail.path, { detail: 'day-parting-tracker' }),
          label: 'Day Parting Tracker'
        };
    }
  };
  const breadcrumb = getBreadcrumb(location.pathname);
  

  return (
    <DefaultLayout>
      <div className={style.headerWrapper}>
        <h1 className={style.header}>
          <Link to={ROUTES.views.path}>Views</Link> &raquo;
          <Link to={breadcrumb.path}> {breadcrumb.label}</Link> &raquo; {adData?.fullAdName}
        </h1>
        <AdActionDropdown selectedRowData={adData ? [adData] : []} onComplete={fetchSingleAdData}/>
      </div>
      {adData ? <ShapeDetail data={adData} /> : <Spinner />}
      {/*{adData && (metricData ? <AdChart*/}
      {/*  data={metricData}*/}
      {/*  metric={engagementMetric}*/}
      {/*  onMetricChange={setEngagementMetric}*/}
      {/*  timeRange={timeRange}*/}
      {/*  timeRangeOptions={timeRangeOptions}*/}
      {/*  onTimeRangeChange={setTimeRange}*/}
      {/*/> : <Spinner />)}*/}
    </DefaultLayout>
  );
}

export default AdManagementDetail;