import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import './ActionDropdown.scss';
import style from './ActionDropdown.scss.json';
import { ExecutableAction } from 'titan-ads/lib/shapes/ExecutableAction';
import { Person } from 'lincd-schema/lib/shapes/Person';
import { URI } from 'lincd/lib/utils/URI';
import { NamedNode } from 'lincd/lib/models';
import { Thing } from 'lincd-schema/lib/shapes/Thing';
import {Shape} from 'lincd/lib/shapes/Shape';
import {ActionConfig} from '../hooks/useBatchActions';
import {getPersonFromUsername} from '../utils/person';
import {DynamicObject} from '../utils';
import { schema } from 'lincd-schema/lib/ontologies/schema';
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import { AdSet } from 'titan-ads/lib/shapes/TitanShapes';
import { getShapeClass } from 'lincd/lib/utils/ShapeClass';
import { automations } from '../data/automations';
import { titanAds } from 'titan-ads/lib/ontologies/titan-ads';
import { packageName } from '../package';
import { Server } from 'lincd-server-utils/lib/utils/Server';

export interface SelectedAction {
  value: string;
  method: string;
  label: string;
}

interface ActionDropDownProps {
  actions: ActionConfig;
  additionalData?: any; // New prop to accept additional data
  selectedRowData?: DynamicObject[];
  onComplete?: (value?:any) => void;
  showActionModal?: {isModalOpen:boolean,batchAction:string, matchedAction: string};
}

const ActionDropdown: React.FC<ActionDropDownProps> = ({ actions, additionalData, selectedRowData, onComplete, showActionModal }) => {
  const [selectedBatchAction, setSelectedBatchAction] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<SelectedAction>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  const loadFormDataFromLocalStorage = (selectedAction) => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      const parsedData = JSON.parse(savedFormData);

      setFormData((prev) => ({
        ...prev,
        [selectedAction]: {
          ...prev[selectedAction] || {},
          userName: parsedData.userName,
        },
      }));
    }
  };

  useEffect(() => {
    if (showActionModal?.isModalOpen) {
      try {
        const matchedAction = JSON.parse(showActionModal.matchedAction);
        setSelectedAction(matchedAction);
        setSelectedBatchAction(showActionModal.matchedAction);
        loadFormDataFromLocalStorage(matchedAction.value);
        setShowModal(showActionModal.isModalOpen);
      } catch (error) {
        console.error("Invalid JSON string in showActionModal.matchedAction", error);
      }
    } 
  }, [showActionModal]);

  const handleActionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;

    // Parse the JSON string into an object
    const selectedAction = JSON.parse(selectedValue);

    setSelectedAction(selectedAction);
    setSelectedBatchAction(selectedValue); // Set selected value
    loadFormDataFromLocalStorage(selectedAction.value); // Load saved form data
    setShowModal(true);
  };

  const handleInputChange = (
    actionValue: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, type, value } = e.target;
    const isCheckbox = type === "checkbox";
    const isRadio = type === "radio";
  
    setFormData((prev) => {
      const updatedData = { ...prev[actionValue] };
  
      if (isCheckbox) {
        const checked = (e.target as HTMLInputElement).checked;
  
        if (checked) {
          // Add the checkbox to the state
          updatedData[name] = {
            value: value || true,
            checked: true,
          };
        } else {
          // Remove the checkbox from the state
          delete updatedData[name];
        }
      } else if (isRadio) {
        updatedData[name] = value; // Store the selected radio button value
         // When bidType changes, reset the bid amount value to empty
        if (name === 'bidType') {
          updatedData['adSetBid'] = "";
          updatedData['bidAmount'] = "";
        }
      } else {
        // Update the state for other fields
        updatedData[name] = value;
      }
  
      // Return the updated state
      return {
        ...prev,
        [actionValue]: updatedData,
      };
    });
  };
  
  const handleSubmit = () => {
    const action = actions.actions.find((a) => a.value === selectedAction?.value);
    let successMessage = "Your request is successfully created!";

    if (action) {    
       const { notes, userName, ...restFormData} = formData[action.value];

       const execAction = new ExecutableAction();
       selectedRowData.forEach(row => {
         const objectNode = NamedNode.getOrCreate(row.id)
         const newThing = new Thing(objectNode);
         newThing.save();
         //  use `.set` to add the object to the action since `.add` is not working
         //  execAction.objects.add(newThing);
         execAction.set(schema.object, newThing.namedNode);
       });

        execAction.agent = getPersonFromUsername(userName);
        execAction.method = selectedAction.method;
        execAction.startTime = new Date();
        execAction.description = notes ?? '';
        execAction.actionStatus = 0 //zero for pontential status;
        if(actions.targetShape)
        {
          execAction.targetShape = actions.targetShape;
        }

        if(action.onSubmit)
        {
          let submitResult = action.onSubmit(restFormData,execAction,selectedRowData);
          if(submitResult && submitResult.message && submitResult.type === 'success') {
            successMessage = submitResult.message;
          }
          if (submitResult && submitResult.message && submitResult.type === 'error') {
            alert(submitResult.message);
            return;
          }
        }
        //always let the server save the action, it will set the time in PST
        //and it will apply the action straight away if requiresApproval is false
        Server.call(packageName,'saveAction',execAction,action.requiresApproval);

        if (onComplete) {
          onComplete(); // Trigger onComplete after successful submit
        }
    }

    // Destructuring to exclude statusGuidance and notes when saving to localStorage
    const filteredLocalData = {
        userName: formData[action.value]?.userName
    };
    localStorage.setItem('formData', JSON.stringify(filteredLocalData));

    // Reset state after handling all actions
    setSelectedAction(null);
    setFormData({});
    setShowModal(false);
    setSelectedBatchAction('');
    alert(successMessage);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAction(null);
    setFormData({});
    setSelectedBatchAction('');
  };

  const currentAction = actions.actions.find((a) => selectedAction?.value === a.value);
  let actionFields;
  if(currentAction){
    if (typeof currentAction.fields === 'function') {
      actionFields=currentAction.fields(selectedRowData);
    } else {
      actionFields = currentAction.fields;
    }
  }
  // useEffect(() => {
  //   //pre-fill formdata based on already checked items / prefilled form
  //   if(actionFields) {
  //     actionFields.forEach(field => {
  //       if(field.checked && field.type === 'boolean') {
  //         handleInputChange(currentAction.value,{target:{type:'checkbox',name:field.name,value:field.value,checked:true}} as any);
  //       }
  //     })
  //   }
  // },[currentAction])

  useEffect(() => {
    if (currentAction && currentAction.fields) {
      // Determine the fields array whether it's static or a function result
      const fields =
        Array.isArray(currentAction.fields)
          ? currentAction.fields
          : currentAction.fields(selectedRowData || []);
  
      // Get current action's form data or create a new object
      const currentFormData = formData[currentAction.value] || {};
      let updated = false;
      
      fields.forEach(field => {
        // If the field has a default value and is not yet defined in the state
        if (field.value !== undefined && currentFormData[field.name] === undefined) {
          currentFormData[field.name] = field.value;
          updated = true;
        }
      });
  
      if (updated) {
        setFormData(prev => ({
          ...prev,
          [currentAction.value]: currentFormData,
        }));
      }
    }
  }, [currentAction, selectedRowData]);
  
  return (
    <>
    {!showActionModal?.isModalOpen && (
      <select className={style.batchActions} onChange={handleActionChange} value={selectedBatchAction}>
        <option value="">Select Action</option>
        {actions.actions.map((action) => (
          // Serialize the action object as a JSON string
          <option key={action.value} value={JSON.stringify({ value: action.value, label: action.label, method: action.method })}>
            {action.label}
          </option>
        ))}
      </select>
    )}

      {showModal && currentAction && (
        <Modal show={showModal} onClose={handleCloseModal} title={selectedAction.label}>
          <form className={style.actionForm} onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <div key={currentAction.value}>
              {/* <h3>{currentAction.label}</h3> */}
              {(Array.isArray(currentAction.fields) 
                  ? currentAction.fields 
                  : typeof currentAction.fields === 'function' 
                    ? currentAction.fields(selectedRowData || []) // Call the function with rows
                    : []
                ).map((field) => {
                  let currentValue: any = '';
                  // Get the current value from form data if it exists
                  if (typeof formData[currentAction.value]?.[field.name] !== 'undefined') {
                    currentValue = formData[currentAction.value]?.[field.name];
                  } else if (selectedRowData.length === 1) {
                    // If there is only one row selected, get the value from the selected row data
                    currentValue = selectedRowData[0][field.name] ?? '';
                  } else if (field.type === 'number' && (currentValue === '' || currentValue === null)) {
                    // Handle special cases for number fields
                    currentValue = 0;
                  } else {
                    currentValue = '';
                  }

                  return (
                    <div key={field.name} className={style.formGroup}>
                      <label htmlFor={field.name}>
                        <span dangerouslySetInnerHTML={{ __html: 
                          typeof field.label === 'function'
                          ? field.label(formData[currentAction.value] || {})
                          : field.label
                         }} />
                      </label>
                      <div>
                        {field.type === 'select' && (
                          <select
                            id={field.name}
                            name={field.name}
                            className={style.selectInput}
                            value={currentValue || ''}
                            onChange={(e) => handleInputChange(currentAction.value, e)}
                            required
                          >
                            <option value="">
                              Select {typeof field.label === 'function' ? 
                                  field.label(formData[currentAction.value] || {})
                                  : field.label}
                            </option>
                            {field.options?.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}

                        {field.type === 'textarea' && (
                          <textarea
                            className={style.textareaInput}
                            id={field.name}
                            name={field.name}
                            value={currentValue || ''}
                            onChange={(e) => handleInputChange(currentAction.value, e)}
                            placeholder={
                              typeof field.placeholder === 'function'
                                ? field.placeholder(formData[currentAction.value] || {})
                                : field.placeholder || 'Type here'
                            }
                          />
                        )}

                        {field.type === 'text' && (
                          <input
                            id={field.name}
                            name={field.name}
                            type={field.type}
                            placeholder={
                              typeof field.placeholder === 'function'
                                ? field.placeholder(formData[currentAction.value] || {})
                                : field.placeholder || 'Type here'
                            }
                            value={currentValue || ''}
                            onChange={(e) => handleInputChange(currentAction.value, e)}
                            className={style.textInput}
                            required
                          />
                        )}
                        {field.type === 'number' && (
                          <div className={style.numberInputGroup}>
                            <input
                              id={field.name}
                              name={field.name}
                              type="number"
                              step={
                                typeof field.step === 'function'
                                  ? field.step(formData[currentAction.value] || {})
                                  : field.step || '1'
                              }
                              placeholder={
                                typeof field.placeholder === 'function'
                                  ? field.placeholder(formData[currentAction.value] || {})
                                  : field.placeholder || 'Type here'
                              }
                              value={
                                typeof currentValue === 'string'
                                  ? (isNaN(parseFloat(currentValue.replace("$", "")))
                                      ? ''
                                      : parseFloat(currentValue.replace("$", "")))
                                  : currentValue || ''
                              }
                              onChange={(e) => handleInputChange(currentAction.value, e)}
                              pattern={
                                typeof field.pattern === 'function'
                                  ? field.pattern(formData[currentAction.value] || {})
                                  : field.pattern
                              }
                              onKeyDown={(e) => {
                                if (field.onKeyDown) {
                                  field.onKeyDown(e, formData[currentAction.value] || {});
                                }
                              }}
                              className={style.textInput}
                              required={field.required}
                            />
                            {typeof field.suffix === 'function' &&
                              (formData[currentAction.value] || {}).bidType === 'percentage' && (
                                <span className={style.suffix}>%</span>
                              )}
                          </div>
                        )}
                        {field.type === 'boolean' && (
                          <input
                            id={field.name}
                            name={field.name}
                            type="checkbox"
                            checked={
                              formData[currentAction.value]?.[field.name]?.checked || false // Fallback to false
                            }
                            value={field.value || null} // Optional: Include the value for reference
                            onChange={(e) => handleInputChange(currentAction.value, e)}
                          />
                        )}
                        {field.type === 'radio' && (
                          <div className={style.radioGroup}>
                            {field.options?.map((option) => (
                              <label key={option.value}>
                                <input
                                  type="radio"
                                  name={field.name}
                                  value={option.value || null}
                                  // checked={formData[currentAction.value]?.[field.name] === option.value}
                                  onChange={(e) => handleInputChange(currentAction.value, e)}
                                  required={field.required}
                                  checked={
                                    (formData[currentAction.value] || {})[field.name] === option.value
                                  }
                                />
                                {option.label}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={style.buttonGroup}>
              <button type="button" onClick={handleCloseModal}>
                Cancel
              </button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default ActionDropdown;
