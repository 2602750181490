import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {NamedNode} from 'lincd/lib/models';
import {Shape} from 'lincd/lib/shapes/Shape';
import {Suspense, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Ad,
  AdSet,
  BrandAccount,
  Campaign,
  BaseShape,
} from 'titan-ads/lib/shapes/TitanShapes';
import {Answer} from 'titan-ads/lib/shapes/Answer';
import {Audience} from 'titan-ads/lib/shapes/Audience';
import {Location} from 'titan-ads/lib/shapes/Location';
import {Question} from 'titan-ads/lib/shapes/Question';
import {QuoraAccount} from 'titan-ads/lib/shapes/QuoraAccount';
import {Topic} from 'titan-ads/lib/shapes/Topic';
import {
  InputValues,
  Platform,
  QuoraConversionObjective,
  QuoraCreativeType,
  QuoraTargetingType,
} from 'titan-ads/lib/types';
import {
  getInputIndex,
  getInputPartial,
  getInputsByPlatform,
  getPartialKey,
  getPartialKeyByTitle,
} from 'titan-ads/lib/utils';
import {InputGroup} from '../components/InputGroup';
import {MasterURL} from '../components/MasterURL';
import {PasteConfig} from '../components/PasteConfig';
import {BrandAccountSelector} from '../components/RefreshQuoraData';
import {InputsContext} from '../contexts/AdInputs';
import {DashboardContext} from '../contexts/Dashboard';
import {saveAds} from '../hooks/google';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useSuspense} from '../utils';
import './Dashboard.scss';
import {default as style} from './Dashboard.scss.json';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {JSONWriter} from 'lincd-jsonld/lib/utils/JSONWriter';

// eslint-disable-next-line react-hooks/rules-of-hooks
const initialAccounts = useSuspense(BrandAccount.loadAll());
useSuspense(QuoraAccount.loadAll());
useSuspense(Answer.loadAll());
// useSuspense(Audience.loadAll());
// useSuspense(Location.loadAll());
// useSuspense(Question.loadAll());
// useSuspense(Topic.loadAll());

export default function Dashboard() {
  const {
    handleAddGroup,
    handleEditInput,
    handleMarkAsDuplicate,
    handleRemoveAllGroups,
    inputGroups,
  } = useContext(InputsContext);
  const {curAccount, masterUrl, landingPageName} = useContext(DashboardContext);
  const accountsResource = initialAccounts;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [savedAds, setSavedAds] = useState<ShapeSet<Ad>>(null);

  // const [pastedContent, setPastedContent] = useState<Ad>();
  // const [pasteMapping, setPasteMapping] = useState({});
  //
  // const pasteClipboard = () => {
  //   navigator.clipboard
  //     .readText()
  //     // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //     .then(handlePastedContent)
  //     .catch((e) => {
  //       if (e.message.includes('denied')) {
  //         alert('Enable the clipboard permission on this site');
  //       } else {
  //         console.error(e);
  //         alert(
  //           'Please check the console for an error, and forward it to the developer',
  //         );
  //       }
  //     });
  // };

  const handleAdSave = async () => {
    let stopRequest = false;
    setIsLoading(true);
    const payload = {};

    const duplicates = {};

    for (const groupId of Object.keys(inputGroups)) {
      // Object.keys(inputGroups).forEach((groupId) => {
      if (stopRequest) {
        return;
      }

      if (+groupId < 0) continue;

      const {
        account,
        adSet,
        campaign,
        inputs: _inputs,
        platform,
      }: {
        account: BrandAccount;
        adSet: AdSet;
        campaign: Campaign;
        inputs: InputValues;
        platform: Platform;
      } = inputGroups[groupId];
      const {name, shortName} = account;

      // create a copy of the `inputs` to avoid modifying the original
      // because on single ad creation, some ads fields is gone/empty
      // so that make the payload not complete when creating the ad
      const inputs = {..._inputs};
      let groupPayload = {};

      // Set the campaign and adset fields
      const campaignKey = getPartialKeyByTitle(platform, 'Campaign');
      inputs[campaignKey] = campaign.identifier;
      console.info(
        'Setting campaign name in payload (key, name):',
        campaignKey,
        campaign.identifier,
      );

      const adSetKey = getPartialKeyByTitle(platform, 'AdSet');
      inputs[adSetKey] = adSet.identifier;
      console.info(
        'Setting ad name in payload (key, name):',
        adSetKey,
        adSet.identifier,
      );

      const partials = getInputsByPlatform(platform);

      const conversionObjectiveIndex = getPartialKeyByTitle(
        platform,
        'Conversion Objective',
      );
      const conversionObjective = inputs[
        conversionObjectiveIndex
      ] as QuoraConversionObjective;

      const conversionEventIndex = getPartialKeyByTitle(
        platform,
        'Conversion Event',
      );

      if (conversionObjective !== 'Conversions') {
        inputs[conversionEventIndex] = null;
      }

      const creativeTypeIndex = getPartialKeyByTitle(platform, 'Creative Type');
      const creativeType = inputs[creativeTypeIndex] as QuoraCreativeType;

      const assetURLIndex = getInputIndex(platform, 'Creative Asset URL');
      const headlineDescIndex = getInputIndex(platform, 'Headline Description');
      const headlineIndex = getInputIndex(platform, 'Headline');
      const subHeadlineIndex = getInputIndex(platform, 'Sub Headline');
      const subheadlineDescIndex = getInputIndex(
        platform,
        'Sub Headline Description',
      );
      const ctaIndex = getInputIndex(platform, 'CTA');
      const brandLogoIndex = getInputIndex(platform, 'Brand Logo');

      partials[assetURLIndex].required = creativeType !== 'Text';

      partials[subHeadlineIndex].required = creativeType !== 'PA';
      partials[headlineIndex].required = creativeType !== 'PA';
      partials[ctaIndex].required = creativeType !== 'PA';
      partials[brandLogoIndex].required = !['PA', 'Text'].includes(
        creativeType,
      );

      const requiredInputs = partials
        .filter((i) => i.required)
        .map((p) => getPartialKey(p));

      const genderKeys = partials
        .filter(
          (i) =>
            i.title === 'Male' || i.title === 'Female' || i.title === 'Unknown',
        )
        .map((p) => getPartialKey(p));

      const targetingType = inputs[
        getPartialKeyByTitle(platform, 'Targeting Type')
      ] as QuoraTargetingType;

      if (targetingType === 'Contextual Topics') {
        // if adSet.topics.size === 0, then alert
        if (adSet.topics.size === 0) {
          stopRequest = true;
          setIsLoading(false);
          return alert('Please select at least one topic');
        }
      }
      if (targetingType === 'Behavioral Interests') {
        if (
          adSet.targetingDescription === '' ||
          adSet.targetingDescription === null
        ) {
          stopRequest = true;
          setIsLoading(false);
          return alert('Please select at least one behavior');
        }
      }

      const placementKeys = partials
        .filter((i) => i.title === 'Placement')
        .map((p) => getPartialKey(p));

      // Ensure every required input is not blank
      const hasEmptyRequiredInputs = Object.entries(inputs)
        .filter(([partialKey]) => requiredInputs.includes(partialKey))
        .some(([, value]) => !value);

      const allPlacementInputsAreFalse = placementKeys.every(
        (placement) => inputs[placement] === false,
      );
      // Check if all gender inputs are false
      const allGenderInputsAreFalse = genderKeys.every(
        (partialKey) => inputs[partialKey] === false,
      );

      if (allPlacementInputsAreFalse) {
        stopRequest = true;
        setIsLoading(false);
        return alert(
          'Specific placements cannot be empty. Please select one of the options',
        );
      }

      // if (allGenderInputsAreFalse) {
      //   stopRequest = true;
      //   setIsLoading(false);
      //   return alert(
      //     'Specific genders cannot be empty. Please select one of the options',
      //   );
      // }

      if (hasEmptyRequiredInputs) {
        stopRequest = true;
        setIsLoading(false);
        console.log(requiredInputs, inputs);
        return alert(
          'Found empty fields, please check all the fields thoroughly',
        );
      }

      //create a campaign from the inputs
      if (campaign.namedNode.isTemporaryNode) {
        const propShapes = Campaign.shape
          .getPropertyShapes()
          .addFrom(BaseShape.shape.getPropertyShapes());

        partials.forEach((partial) => {
          const {column, shapePath} = partial;
          if (shapePath && column === 'campaign') {
            const propShape = propShapes.find((ps) => ps.path === shapePath);

            campaign[propShape.label] =
              inputs[getPartialKey(partial)] === undefined
                ? ''
                : inputs[getPartialKey(partial)];
          } else {
            console.info('campaign: No shape path found', partial.title);
          }
        });
        campaign.setLaunchDate();
        campaign.setName(campaign.adSets.first());
        campaign.quoraAccount = account.quoraAccount;
        //have to clone because campaign may be overwritten soon
        campaign.save();
        groupPayload['campaign'] = campaign;
      }
      await LinkedStorage.promiseUpdated();

      //create adset from the inputs
      for(const a of campaign.adSets) {
      // campaign.adSets.map(async (a: AdSet) => {
        if (a.namedNode.isTemporaryNode) {
          const {deviceAndBrowser, name: adSetName, placement, topics} = a;
          //a.namedNode.remove();
          const prefixOptions = {
            deviceAndBrowser,
            final: true,
            name: adSetName,
            placement,
            topics,
          };
          const newAdSet = new AdSet(campaign, prefixOptions);
          newAdSet.quoraAccount = account.quoraAccount;

          const adSetPropShapes = AdSet.shape
            .getPropertyShapes()
            .addFrom(BaseShape.shape.getPropertyShapes());
          partials.forEach((partial) => {
            if (partial.shapePath && partial.column === 'adset') {
              const propShape = adSetPropShapes.find(
                (ps) => ps.path === partial.shapePath,
              );
              // For all object properties
              switch (partial.title) {
                case 'Included Locations':
                  newAdSet.geolocations.addFrom(adSet.geolocations);
                  console.log(
                    'Setting geolocations',
                    adSet.geolocations,
                    newAdSet.geolocations,
                  );
                  break;
                case 'Excluded Locations':
                  newAdSet.excludedGeolocations.addFrom(
                    adSet.excludedGeolocations,
                  );
                  console.log(
                    'Setting excluded geolocations',
                    adSet.excludedGeolocations,
                    newAdSet.excludedGeolocations,
                  );
                  break;
                default:
                  newAdSet[propShape.label] =
                    inputs[getPartialKey(partial)] === undefined
                      ? ''
                      : inputs[getPartialKey(partial)];
                  console.log(
                    `Setting ${propShape.label} to ${
                      inputs[getPartialKey(partial)]
                    } : ${newAdSet[propShape.label]}`,
                  );
              }
            } else {
              console.info('adSet: No shape path found', partial.title);
            }
          });
          newAdSet.setLaunchDate();
          newAdSet.setName();

          //save the topics, before saving the adset
          for(let topic of newAdSet.topics) {
            if(topic.namedNode.isTemporaryNode) {
              let newTopic = await topic.toPermanentTopicFrontend();
              newAdSet.topics.delete(topic);
              newAdSet.topics.add(newTopic);
            }
          }
          await LinkedStorage.promiseUpdated();

          newAdSet.save();
          if (groupPayload['adSet']) {
            console.error(
              'multiple adsets are possible? then update the payload code',
            );
          }
          groupPayload['adSet'] = newAdSet;
        }
      }
      if (!groupPayload['adSet'] && adSet) {
        groupPayload['adSet'] = adSet;
      }

      // Handle duplicates on the front-end
      const duplicateKey = JSON.stringify(inputs);
      duplicates[duplicateKey] = duplicates[duplicateKey] || [];
      duplicates[duplicateKey].push(groupId);

      const isNewEntry = !Object.keys(payload).includes(`${shortName}`);

      /**
       * Structure of the payload:
       * {
       *  "IE": {
       *    "Quora": [
       *       ["foo", "bar", "baz"],
       *       ["boo", "faz", "far"]
       *     ],
       *    "Rumble": [
       *       ...
       *     ],
       *   },
       *  "FXD": {
       *     ...
       *   }
       * }
       */
      groupPayload = await JSONWriter.stringify(groupPayload);
      if (isNewEntry) {
        payload[shortName] = {
          accountName: name,
          [platform]: {[groupId]: {inputs, groupPayload}},
        };
      } else {
        const isNewPlatformEntry = !Object.keys(payload[shortName]).includes(
          platform,
        );

        if (isNewPlatformEntry) {
          payload[shortName][platform] = {
            [groupId]: {inputs, groupPayload},
          };
        } else {
          const oldInputs = payload[shortName][platform];

          payload[shortName][platform] = {
            ...oldInputs,
            [groupId]: {inputs, groupPayload},
          };
        }
      }
    }

    // An error must have occurred
    if (stopRequest) {
      return;
    }

    let duplicatesDetected = false;
    Object.values(duplicates).forEach((indexArray: number[]) => {
      if (indexArray.length > 1) {
        duplicatesDetected = true;
        alert('Duplicates detected');
      }
    });

    if (duplicatesDetected) {
      return setIsLoading(false);
    }

    //sending master URL and landing page name as they will be used for landing page URL
    const contextData = {
      masterUrl,
      landingPageName,
    };

    //wait until save() methods are done
    await LinkedStorage.promiseUpdated();

    //send all the data to the backend, which will then send it to Google Sheets
    //and if that's all ok, then it goes on to create Ads & AdSets in Quora
    saveAds(payload, contextData)
      .then((r) => {
        if (r.error) {
          console.warn(r.error);
          alert(
            'Something went wrong. Please make a screenshot and send it to service@semantu.com and we will check it out asap',
          );
          setIsLoading(false);
          return;
        }
        const newAds = r.newAds;
        const accountIDs = Object.keys(r.payload);
        const duplicateGroups = r.duplicateGroupIds;

        for (const groupId of duplicateGroups) {
          handleMarkAsDuplicate(groupId);
        }
        if (duplicateGroups.length > 0) {
          return alert(
            `${duplicateGroups.length} ad${
              duplicateGroups.length != 1 ? 's were' : ' was'
            } already found in Google Sheets, and therefore not added`,
          );
        }

        for (const accountId of accountIDs) {
          const accountData = r.payload[accountId];

          const platforms: Platform[] = Object.keys(accountData).filter(
            (p) => p != 'accountName',
          ) as Platform[];

          for (const platform of platforms) {
            const platformData = accountData[platform];

            const groupIDs = Object.keys(platformData);

            const adIdPartial = getInputPartial(platform, 'Ad ID');
            const adIdKey = getPartialKey(adIdPartial);

            const adNamePartial = getInputPartial(platform, 'Ad Name');
            const adNameKey = getPartialKey(adNamePartial);

            groupIDs.forEach((groupId) => {
              handleEditInput(
                Number.parseInt(groupId),
                adIdPartial,
                platformData[groupId][adIdKey],
              );
              handleEditInput(
                Number.parseInt(groupId),
                adNamePartial,
                platformData[groupId][adNameKey],
              );
            });
          }
        }

        setSavedAds(newAds);
      })
      .catch((e) => {
        alert(e.message);
      })
      // Want the loading indicator to stop regardless of a
      // successful or unsuccessful response
      .finally(() => setIsLoading(false));
  };

  // const handlePastedContent = async (text: string) => {
  //   const adIdMatch = text.match(/-?\d{12}[a-zA-Z\d]{8}-?/);
  //   if (adIdMatch) {
  //     const adId = adIdMatch[0].replace(/-/g, '');
  //     const foundAd: Ad = await Ad.fetchById(adId);
  //
  //     if (
  //       foundAd &&
  //       curAccount.shortName === foundAd.adSet.campaign.account.shortName
  //     ) {
  //       setPastedContent(foundAd);
  //       addRow(foundAd);
  //     } else {
  //       alert(
  //         `Ad belongs to '${foundAd.adSet.campaign.account.name}'.  Please change brands if you wish to use this ad.`,
  //       );
  //     }
  //   } else {
  //     alert(
  //       'Valid ad ID not found in pasted content.\n\nPlease check your clipboard and try again.',
  //     );
  //   }
  // };

  const addRow = (ad?: Ad) => {
    const platform: Platform = ad ? ad.platform : detectPlatform('q', false);

    const inputOptions = getInputsByPlatform(platform);

    const values: InputValues = {};

    if (ad) {
      inputOptions.forEach((p) => {
        const {column, shapePath} = p;

        if (!shapePath || shapePath instanceof Array) {
          return;
        }

        const shapeToAccess: Shape = ad;
        // if (column === 'campaign') {
        //   shapeToAccess = ad.adSet.campaign;
        // }

        if (shapePath instanceof NamedNode && column === 'ad') {
          const propShape = shapeToAccess.nodeShape
            .getPropertyShapes()
            .find((ps) => ps.path === shapePath);

          values[getPartialKey(p)] = shapeToAccess[propShape.label];
        }
      });
    }

    // newGroupId is based on the biggest ID at the time of
    // creation, NOT based on how many other groups are
    // present.  This helps to prevent any bugs in the future
    // that might pop up after deleting a group of inputs
    // and then adding a new group, whilst other groups still
    // exist.  This is to do with how the inputs are being
    // stored in the AdInputs context provider
    let newGroupId = 0;
    if (Object.keys(inputGroups).length > 0) {
      const prevGroupId = Math.max(
        ...Object.keys(inputGroups).map((x) => Number.parseInt(x)),
      );

      // Don't want to count the QuickLaunch or Template groups
      newGroupId = prevGroupId < 0 ? 0 : newGroupId + 1;
    }

    handleAddGroup(
      newGroupId,
      platform,
      values,
      ad ? ad.adSet.campaign : undefined,
      ad ? ad.adSet : undefined,
    );
  };

  const delAllRows = () => {
    handleRemoveAllGroups();
    setSavedAds(null);
  };

  const hasChosenAccount = !!curAccount;

  return (
    <DefaultLayout>
      <h1>Create Ad Name & forwarding URL</h1>
      <h2>Step 1</h2>

      <Suspense fallback={<h1>Loading...</h1>}>
        <BrandAccountSelector qAccounts={accountsResource} />
      </Suspense>

      <MasterURL />

      {hasChosenAccount && !curAccount.quoraAccount && (
        <>
          <br />
          <p>Your chosen brand hasn't been assigned to a Quora account.</p>
          <p>
            Visit the{' '}
            <Link style={{textDecoration: 'underline'}} to="/admin/accounts">
              brands management
            </Link>{' '}
            page to assign one.
          </p>
        </>
      )}
      {hasChosenAccount &&
        !!curAccount.quoraAccount &&
        masterUrl &&
        landingPageName && (
          <>
            <h2 data-cy="step-2">Step 2</h2>

            {/*<PasteConfig*/}
            {/*  pasteClipboard={pasteClipboard}*/}
            {/*  partials={getInputsByPlatform('quora')}*/}
            {/*  pastedContent={pastedContent}*/}
            {/*  pasteMapping={pasteMapping}*/}
            {/*  setPasteMapping={setPasteMapping}*/}
            {/*/>*/}
            <h3>Create New Ad Manually:</h3>

            <div
              style={{
                backgroundColor: isLoading ? 'honeydew' : 'inherit',
              }}
              id="row-container"
            >
              {!savedAds && (
                <input
                  disabled={!hasChosenAccount}
                  className={style.buttons}
                  title={
                    hasChosenAccount ? '' : 'Please choose an account first'
                  }
                  type="button"
                  value="New ad"
                  onClick={() => addRow()}
                />
              )}
              {Object.keys(inputGroups).filter((x) => +x >= 0).length > 0 && (
                <input
                  type="button"
                  value={!savedAds ? 'Delete all' : 'Reset'}
                  className={style.buttonDelete}
                  onClick={() => delAllRows()}
                />
              )}

              {Object.keys(inputGroups)
                .filter((x) => +x >= 0)
                .map((key) => {
                  return (
                    <Suspense key={key} fallback={<h3>Loading...</h3>}>
                      <InputGroup
                        key={key}
                        id={Number.parseInt(key)}
                        savedAds={savedAds}
                      />
                    </Suspense>
                  );
                })}
            </div>

            {!savedAds && (
              <>
                {Object.keys(inputGroups).filter((x) => +x >= 0).length > 0 && (
                  <input
                    onClick={handleAdSave}
                    type="submit"
                    value={isLoading ? 'Creating...' : 'Create'}
                    disabled={isLoading || !hasChosenAccount || !masterUrl}
                    className={style.buttons}
                  />
                )}
                {/*<span>*/}
                {/*  <i>All fields are required</i>*/}
                {/*</span>*/}
              </>
            )}
          </>
        )}
    </DefaultLayout>
  );
}

/**
 * Attempt to automatically detect the platform of a given ad.
 *
 * In order, the checks go:
 *  1. Leading string (should either be Q, T, or R?)
 *  2. Number of separations
 *
 * If both checks pass, the corresponding/guessed platform is
 * returned.  Otherwise, an error is thrown.
 *
 * @param adInfo The name of the ad
 * @param strict Whether the second check should occur or not
 */
function detectPlatform(adInfo: string, strict: boolean = true): Platform {
  const SEPARATOR = ',';
  const FIRST_LETTER = adInfo[0].toLowerCase();

  // Check 1
  let platformGuess: Platform;
  switch (FIRST_LETTER) {
    case 'q':
      platformGuess = 'quora';
      break;
    case 'r':
      platformGuess = 'rumble';
      break;
    case 't':
      platformGuess = 'taboola';
      break;
    default:
      console.error(`Unknown platform beginning with '${FIRST_LETTER}'`);
      return;
  }

  const adSections = adInfo.split(SEPARATOR).map((s) => s.trim());
  const inputOptions = getInputsByPlatform(platformGuess);

  // Check 2
  const ACTUAL_NUM_OF_SECTIONS = adSections.length;
  const TARGET_NUM_OF_SECTIONS = inputOptions.length;

  if (ACTUAL_NUM_OF_SECTIONS === TARGET_NUM_OF_SECTIONS || !strict)
    return platformGuess;
  console.error(
    `Section discrepancies.  Should be ${TARGET_NUM_OF_SECTIONS} sections, but got ${ACTUAL_NUM_OF_SECTIONS}`,
  );
}
