import React,{useEffect,useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';
import {generatePath,Link,useLocation,useNavigate} from 'react-router-dom';
import {DynamicObject} from '../utils';
import {packageName} from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {titanAds} from 'titan-ads/lib/ontologies/titan-ads';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { TimeRangeProps, TimeRangeValue } from '../types';
import {AdChart} from '../components/AdChart';
import {AdChildren} from '../components/AdChildren';
import { Spinner } from '../components/Spinner';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import AdSetActionDropdown from '../components/AdSetActionDropdown';
import './AdSetManagementDetail.scss';
import style from './AdSetManagementDetail.scss.json';

// add UTC plugin to dayjs to handle UTC date
dayjs.extend(utc);

function AdSetManagementDetail()
{
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const uri = queryParams.get('uri') || queryParams.get('uri_undefined');
  if(!uri) {
    navigate(-1);
  }
  const id = uri.split('/').pop();

  const [adsetData, setAdsetData] = useState<DynamicObject>();

  const fetchSingleAdSetData = async () => {
    if (!uri) return;
    
    try {
      // const dateRange = await Server.call(packageName, 'adjustDateRangeTimeZone');
      const data = await Server.call(packageName, 'loadSingleAdSetData', uri);
      if (data) {
        setAdsetData(data);
      } else {
        setAdsetData(null);
      }
    } catch (error) {
      console.error('Error fetching AdSet data:', error);
      setAdsetData(null);
    }
  };

  useEffect(() => {
    fetchSingleAdSetData();
  }, [uri]);

  return (
    <DefaultLayout>
      <div className={style.headerWrapper}>
        <h1 className={style.header}>
          <Link to={ROUTES.views.path}>Views</Link> &raquo;
          <Link to={generatePath(ROUTES.view_detail.path,{detail: 'adset-management'})}> AdSet Management</Link> &raquo; {id}
        </h1>
        <AdSetActionDropdown selectedRowData={adsetData ? [adsetData] : []} onComplete={fetchSingleAdSetData}/>
      </div>
      <AdSetDetails id={uri} data={adsetData} />
    </DefaultLayout>
  );
}

export function AdSetDetails({id, data}: {id: string, data?: DynamicObject})
{
  const [engagementMetric, setEngagementMetric] = useState<string>('dailyClicks');
  const [metricData, setMetricData] = useState<DynamicObject>();

  // set default time range to last 7 days
  const [timeRange, setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7,'day').utc().format('YYYY-MM-DD'), // default is 7 days ago,
    endDate: dayjs().utc().format('YYYY-MM-DD'), // default is today
  });

  const [timeRangeOptions, setTimeRangeOptions] = useState<TimeRangeValue[]>([
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'last7days', label: 'Last 7 days' },
    { value: 'last14days', label: 'Last 14 days' },
    { value: 'last30days', label: 'Last 30 days' },
    { value: 'customSingleDate', label: 'Custom single date' },
  ])

  // fetch engagement metric data by uri
  // useEffect(() => {
  //   if (!data && !id) return;
  //
  //   Server.call(
  //     packageName,
  //     'getObservationsByShape',
  //     id,
  //     AdSet,
  //     [
  //       titanAds.dailyClicks,
  //       titanAds.dailyImpressions,
  //       titanAds.dailySpend,
  //       titanAds.bidAmount,
  //       // titanAds.dailySales,
  //     ],
  //     timeRange
  //   )
  //     .then(data => setMetricData(data))
  //     .catch(error => console.error('error fetching metric data:',error));
  // },[timeRange, data, id]);

  return (
    <>
      <div>
        {data ? <ShapeDetail data={data} /> : <Spinner />}
        {/*{data && (metricData ? <AdChart*/}
        {/*  data={metricData}*/}
        {/*  metric={engagementMetric}*/}
        {/*  onMetricChange={setEngagementMetric}*/}
        {/*  timeRange={timeRange}*/}
        {/*  timeRangeOptions={timeRangeOptions}*/}
        {/*  onTimeRangeChange={setTimeRange}*/}
        {/*/> : <Spinner />)}*/}
      </div>
      <AdChildren id={id} shapeType="adSet" label="Ads" />
    </>
  );
}

export default AdSetManagementDetail;