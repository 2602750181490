import React, {useEffect, useState, useCallback, useContext} from 'react';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {TitanQueryConfig, loadAdSetData, renderNoDataMessage} from '../utils';
import {Spinner} from '../components/Spinner';
import {InstanceOverview} from '../components/InstanceOverview';
import {debounce} from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import AdSetActionDropdown from '../components/AdSetActionDropdown';
import {NamedNode} from 'lincd/lib/models';
import {Shape} from 'lincd/lib/shapes/Shape';
import {useCustomColumns} from '../hooks/useCustomColumns';
import './AdSetManagement.scss';
import styles from './AdSetManagement.scss.json';
import {cpc} from 'titan-ads/src/ontologies/titan-ads';
import { useNavigate, Routes, useParams, useLocation } from 'react-router-dom';

interface DynamicObject {
  [key: string]: any;
}

interface CustomColumn {
  property?: {label?: string};
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}

interface AdSetManagementProps {
  batchActions?: any; // Adjust the type as needed
}

function AdSetManagement({batchActions}: AdSetManagementProps) {
  const {isLight} = useContext(ThemeContext); // Access the theme context
  const [instances, setInstances] = useState<DynamicObject[]>();
  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([
    {
      id: 'status',
      desc: true,
    },
  ]);
  const [pagination, _setPagination] = useState({pageIndex: 0, pageSize: 10}); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const shape: NodeShape = AdSet.shape;

  const setPagination = (val) => {
    return _setPagination(val);
  };

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({...prev, pageIndex: 0})); // Reset to first page
    }, 500), // 500ms debounce delay
    [],
  );

  async function fetchAdData() {
    try {
      const adSetData = await loadAdSetData({
        searchQuery: searchQuery,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting: sorting,
      });
      setInstances(adSetData.instances);
      setTotalItems(adSetData.numInstances);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchAdData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, searchQuery]);

  const customColumns = useCustomColumns(instances, {
    //to indicate the column, use the same key here as in the mapping object in views/AdManagement.tsx
    status: {
      className: (value, row: DynamicObject) => {
        //you can use the row object if you need to access other values in the row
        if (value === 'PAUSED') {
          return styles.lightRed;
        }
        return '';
      },
    },
    shortAdName: {
      className: (value, row: DynamicObject) => {
        //you can use the row object if you need to access other values in the row
        const status = row.status;
        if (status === 'PAUSED') {
          return styles.lightRed;
        }
        return '';
      },
    },
    cpc: {
      //Ad CPC is red if higher than adGrossPL value, green if lower than adGrossPL value by the calculation of adGrossPL/2.262, and yellow if lower than adGrossPL value by the calculation of adGrossPL/1.5
      className: (value, row: DynamicObject) => {
        const adSetGrossPL = row.adSetGrossPL;
        if (value > adSetGrossPL) {
          return styles.red;
        } else if (value < adSetGrossPL / 2.262) {
          return styles.green;
        } else if (value < adSetGrossPL / 1.5) {
          return styles.yellow;
        }
      },
    },
    //Ad Gross P&L is red if negative, green if positive, and yellow if zero
    adSetGrossPL: {
      className: (value, row: DynamicObject) => {
        if (value < 0) {
          return styles.red;
        } else if (value > 0) {
          return styles.green;
        } else if (value === 0) {
          return styles.yellow;
        }
      },
    },
  });

  const detailsViewHandler = (row) => {
    navigate(`${location.pathname}/details?uri=${row.id}`);
  }

  return instances && customColumns ? (
        <InstanceOverview 
          instances={instances} 
          shape={shape} 
          customColumns={customColumns}
          pagination={pagination}
          totalItems={totalItems}
          onPaginationChange={setPagination} 
          sorting={sorting}
          onSortingChange={setSorting}
          onSearchChange={debouncedSetSearchQuery}
          isLight={isLight} // Pass isLight to InstanceOverview
          batchActionDropdown={(selectedRowData) => {
            return <AdSetActionDropdown key={selectedRowData.map(r => r.id).join('-')} selectedRowData={selectedRowData} onComplete={fetchAdData}/>
          }}
          rightSideMenu={true}
          onDetailsClick={detailsViewHandler}
        />) : <Spinner />;
}

export default AdSetManagement;
