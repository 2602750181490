import React, { useEffect } from 'react';
import '../../pages/Automations.scss';
import style from '../../pages/Automations.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';
import { automation2 } from '../../data/automations';
import { Table } from '../Table';
import { STATUS_COLORS, useAutomation2 } from '../../hooks/useAutomation2';
import dayjs from 'dayjs';
import { Tooltip } from '../Tooltip';
import { Icons } from '../Icons';

// LimitAdSpend component
// This component displays a table of ads that have been paused by the Limit Ad Spend automation
export function LimitAdSpend() {
    const {
        loading,
        ads,
        selected,
        selectAll,
        fetchAds,
        toggleSelectAll,
        toggleSelect,
        onResumeAds
    } = useAutomation2()

    useEffect(() => {
        fetchAds();
    }, [])

    return (
        <div className={cl(style.automation2)}>
            <div className={style.headerContent}>
                <h3>{automation2.name} {automation2?.alternateName ? `(${automation2.alternateName})` : ''}</h3>
                <p>
                    {automation2.description}
                </p>
                <p>
                  The following ads have been paused by this automation in the last 24 hours or are close to reaching their spend threshold.<br />
                </p>
            </div>
            <div className={style.tableScroll}>
                <Table.Root isStickyHeader>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head className={style.cellCheckbox}>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={toggleSelectAll}
                                />
                            </Table.Head>
                            <Table.Head>Short Name</Table.Head>
                            <Table.Head>Spend Since Last Sale</Table.Head>
                            <Table.Head>Payout</Table.Head>
                            <Table.Head>Spend Percentage of Payout</Table.Head>
                            <Table.Head>Threshold</Table.Head>
                            <Table.Head>Action Time (PST)</Table.Head>
                            <Table.Head>Status</Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {ads && ads.length ? 
                            ads.map((ad) => (
                                <Table.Row key={ad.uri}>
                                    <Table.Cell className={style.cellCheckbox}>
                                        <input
                                            type="checkbox"
                                            checked={selected.has(ad.uri)}
                                            onChange={() => toggleSelect(ad.uri)}
                                            disabled={ad.status !== 'PAUSED'}
                                        />
                                    </Table.Cell>
                                    <Table.Cell className={style.cellAlignLeft}>{ad.shortName}</Table.Cell>
                                    <Table.Cell>{ad.spendSinceLastSale}</Table.Cell>
                                    <Table.Cell>${ad.payout}</Table.Cell>
                                    <Table.Cell>{ad.currentPercentage ? ad.currentPercentage : '0%'}</Table.Cell>
                                    <Table.Cell>{ad.maxPercentage}</Table.Cell>
                                    <Table.Cell>
                                        {ad.status !== 'RUNNING' && (
                                          <>
                                            {dayjs(ad.time).format('MMMM D, YYYY h:mm A') || '-'}
                                            <Tooltip.Provider>
                                                <Tooltip.Root>
                                                    <Tooltip.Trigger asChild>
                                                        <button className={style.infoCircle}>
                                                            <Icons.InfoCircled width={16} height={16} />
                                                        </button>
                                                    </Tooltip.Trigger>
                                                    <Tooltip.Content>
                                                        {ad.status === 'PAUSED' ? `Paused at ${dayjs(ad.time).format('MMMM D, YYYY h:mm A')}` : ad.status === 'RESUMED' ? `Resumed at ${dayjs(ad.time).format('MMMM D, YYYY h:mm A')}` : `-`}
                                                        <Tooltip.Arrow />
                                                    </Tooltip.Content>
                                                </Tooltip.Root>
                                            </Tooltip.Provider>
                                          </>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell style={{
                                        backgroundColor: ad.status === 'PAUSED' ? STATUS_COLORS.PAUSED 
                                            : ad.status === 'RESUMED' ? STATUS_COLORS.RESUMED 
                                            : STATUS_COLORS.RUNNING,
                                    }}>{ad.status}</Table.Cell>
                                </Table.Row>
                            )
                        ) : (
                            <Table.Row>
                                <Table.Cell colSpan={8} style={{textAlign: 'center'}}>
                                    <p>No ads are currently managed by this automation.</p>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table.Root>
            </div>
            <div style={{textAlign: 'center'}}>
              <p>
                Select ads that have been paused in order to resume them and increase their threshold by an additional 20% of the payout.<br />
                <button onClick={onResumeAds} disabled={loading || selected.size === 0}>Resume Ads and Increase Threshold with 20%</button>
              </p>
            </div>
        </div>
    )
}