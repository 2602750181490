import React, {useEffect, useState, useCallback, useContext} from 'react';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {TitanQueryConfig, loadAdSetData, isDateToday} from '../utils';
import {Spinner} from '../components/Spinner';
import {InstanceOverview} from '../components/InstanceOverview';
import {debounce} from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import AdSetActionDropdown from '../components/AdSetActionDropdown';
import {useCustomColumns} from '../hooks/useCustomColumns';
import './AdSetManagement.scss';
import styles from './AdSetManagement.scss.json';
import {cpc} from 'titan-ads/src/ontologies/titan-ads';
import { useNavigate, Routes, useParams, useLocation } from 'react-router-dom';
import DatePickerFilter from '../components/DatePickerFilter';
import { FilterProps, TimeRangeProps } from '../types';
import dayjs from 'dayjs';
import { DashboardContext } from '../contexts/Dashboard';
import { TableColumn } from '../utils/views/TableDataProvider';

interface DynamicObject {
  [key: string]: any;
}

interface AdSetManagementProps {
  batchActions?: any; // Adjust the type as needed
}

function AdSetManagement({batchActions}: AdSetManagementProps) {
  const {isLight} = useContext(ThemeContext); // Access the theme context
  const [instances, setInstances] = useState<DynamicObject[]>();
  const [columns, setColumns] = useState<TableColumn<any>[]>();
  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([
    {
      id: 'clicks',
      desc: true,
    },
  ]);

  const [pagination, _setPagination] = useState({pageIndex: 0, pageSize: 10}); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  
  const initialFilter = [
    { field: 'impressions', value: '0', operator: 'greater' }
  ];
  const [filtersData, setFiltersData] = useState<FilterProps[]>(initialFilter);
  // const [filtersData, setFiltersData] = useState([{ field: 'isActive', value: 'Yes', operator: 'equal' }]);
  
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate();

   // set default time range to last 30 days
  const [timeRange,setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), // default is 7 days ago
    endDate: dayjs().format('YYYY-MM-DD') // default is today,
  });


  const handleTimeRangeChange = (newTimeRange: TimeRangeProps) => {
    setTimeRange(newTimeRange);
  };

  const shape: NodeShape = AdSet.shape;

  const setPagination = (val) => {
    return _setPagination(val);
  };

  const handleCheckboxActiveOnly = (checked:boolean) => {
    setFiltersData((prev) => {
      if(!prev) {
        prev = [];
      }
      let updatedFilters: FilterProps[] = [];
      if(checked) {
        // get the filter status `On`, to make sure the filter is not duplicated
        const exists = prev.some(filter => filter.field === 'impressions' && filter.value === '0' && filter.operator === 'greater');
        if (!exists) {
          updatedFilters = [...prev, { field: 'impressions', value: '0', operator: 'greater' }];
        } else {
          updatedFilters = prev;
        }
      } else {
        updatedFilters = prev.filter(filter => filter.field !== 'impressions');
      }
      return updatedFilters;
    });

  };

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({...prev, pageIndex: 0})); // Reset to first page
    }, 500), // 500ms debounce delay
    [],
  );

  async function fetchAdSetData() {
    try {
      setDataLoading(true);
      const adSetData = await loadAdSetData({
        searchQuery: searchQuery,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting: sorting,
        filters: filtersData,
        timeRange,
        includeColumns:!columns
      });
      setDataLoading(false);
      setInstances(adSetData.instances);
      setTotalItems(adSetData.numInstances);
      if(adSetData.columns) {
        setColumns(adSetData.columns);
      }
      console.log("adsetData", adSetData)
    } catch (error) {
      console.error('Error fetching data:', error);
      setInstances([]);
    }
  }

  useEffect(() => {
      fetchAdSetData();
  }, [
    pagination.pageIndex, 
    pagination.pageSize, 
    sorting, 
    searchQuery, 
    filtersData,  
    timeRange
  ]);

  const customColumns = useCustomColumns(instances, columns, {
    //to indicate the column, use the same key here as in the mapping object in views/AdManagement.tsx
    status: {
      className: (value, row: DynamicObject) => {
        //you can use the row object if you need to access other values in the row
        if (value === 'PAUSED') {
          return styles.lightRed;
        }
        return '';
      },
    },
    shortAdName: {
      className: (value, row: DynamicObject) => {
        //you can use the row object if you need to access other values in the row
        const status = row.status;
        if (status === 'PAUSED') {
          return styles.lightRed;
        }
        return '';
      },
    },
    $CPC: {
      //Ad CPC is red if higher than adGrossPL value, green if lower than adGrossPL value by the calculation of adGrossPL/2.262, and yellow if lower than adGrossPL value by the calculation of adGrossPL/1.5
      className: (value, row: DynamicObject) => {
        const adSetGrossPL = row.$adSetGrossPL;
        if (value > adSetGrossPL) {
          return styles.red;
        } else if (value < adSetGrossPL / 2.262) {
          return styles.green;
        } else if (value < adSetGrossPL / 1.5) {
          return styles.yellow;
        }
      },
    },
    //Ad Gross P&L is red if negative, green if positive, and yellow if zero
    $adSetGrossPL: {
      className: (value, row: DynamicObject) => {
        if (value < 0) {
          return styles.red;
        } else if (value > 0) {
          return styles.green;
        } else if (value === 0) {
          return styles.yellow;
        }
      },
    },
  });

  const detailsViewHandler = (row) => {
    navigate(`${location.pathname}/details?uri=${row.id}`);
  }

  const handleRefreshData = (isRefreshing: boolean, newData: DynamicObject[]) => {
    if (isRefreshing) {
      const updatedInstances = instances.map((instance) => {
        const newInstance = newData.find((data) => data.id === instance.id);
        return newInstance ? newInstance : instance;
      });
      setInstances(updatedInstances);
    }
  };

  // trigger the fetchAdData function when the brandAccount changes from quickFilter
  const {curAccount} = useContext(DashboardContext);
  const handleBranchAccountChange = (account: string) => {
    setFiltersData((prev) => {
      if (!prev) {
        prev = [];
      }
      // set the brandAccount filter if the brandAccount option selected
      if (curAccount && curAccount.name !== undefined) {
        // remove the previous brandAccount filter if it exists
        prev = prev.filter(filter => filter.field !== 'brandAccount');
        // add the new brandAccount filter
        return [...prev, { field: 'brandAccount', value: account, operator: 'equal' }];
      } else {
        return prev.filter(filter => filter.field !== 'brandAccount');
      }
    })
  }

  // trigger the fetchAdData function when the filter changes
  // newFilters values, e.g. newFilters = [{ field: 'shortAdName', value: 'TF-247', operator: 'equal' }]
  // currentFilter, e.g = [{ field: 'status', value: 'On', operator: 'equal' }]
  // newFilter will replace currentFilter or current filter state
  const handleFilterChange = (newFilters: FilterProps[]) => {
    setFiltersData(newFilters);
  }

  return (
    <>
      {customColumns || instances?.length === 0 ? (
        <InstanceOverview
          instances={instances} 
          shape={shape} 
          customColumns={customColumns}
          initialFilter={filtersData}
          filterInstances={(filter: FilterProps[]) => handleFilterChange(filter)}
          pagination={pagination}
          totalItems={totalItems}
          resetFilter={(value:boolean) => value && setFiltersData(initialFilter)}
          onPaginationChange={setPagination} 
          sorting={sorting}
          onRefreshingData={handleRefreshData}
          columnInfo={columns}
          isLoading={dataLoading}
          onSortingChange={setSorting}
          triggerActionRefresh={fetchAdSetData}
          onSearchChange={debouncedSetSearchQuery}
          isLight={isLight} // Pass isLight to InstanceOverview
          batchActionDropdown={(selectedRowData) => {
            return <AdSetActionDropdown key={selectedRowData.map(r => r?.id).join('-')}
                                        selectedRowData={selectedRowData}
                                        onComplete={fetchAdSetData}
              />
          }}
          quickFilter={[
            {
              property: 'impressions',
              label: 'Show only ads with impressions',
              onChange: (checked:boolean) => handleCheckboxActiveOnly(checked),
              value: filtersData?.find(filter => filter.field === 'impressions')?.value === '0',
              defaultValue: '0'
            },
            {
                property: 'brandAccount',
                onChange: (account:string) => handleBranchAccountChange(account),
                value: curAccount?.name,
            }
          ]}
          onDetailsClick={detailsViewHandler}
          timeRange={timeRange}
          onTimeRangeChange={handleTimeRangeChange}
        />
      ) : (<Spinner />)}
    </>
  )
}

export default AdSetManagement;
