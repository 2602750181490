import React,{useEffect,useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import {Link, useLocation} from 'react-router-dom';
import './CampaignManagementDetail.scss';
import ShapeDetail from '../components/ShapeDetail';
import { QuickFilter, QuickFilters, TimeRangeProps } from '../types';
import './OptimizationReport.scss';
import style from './OptimizationReport.scss.json';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { ROUTES } from '../routes';
import { FilterTimeRange } from '../components/FilterTimeRange';
import { AdSetDetails } from './AdSetManagementDetail';
import { DynamicObject, loadApprovalDataObjects } from '../utils';
import { NamedNode } from 'lincd/lib/models';
import ObjectDetails from './ObjectDetails';

function OptimizationReportDetail()
{
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uri = queryParams.get('uri');
  const state = location.state.row
  const [reportDetails,setReportDetails] = useState<any>();
  const id = uri?.split('/').pop();
  const [objects, setObjects] = useState<NamedNode[]>();
  const [targetShape, setTargetShape] = useState<NamedNode>();

  useEffect(() => {
    const fetchReportDetails = async () => {
      try {
        const reportDetails = await Server.call(packageName, 'loadExecutableAction', state.id);
        if (reportDetails) setReportDetails(reportDetails);
      } catch (e) {
        console.error(e);
      }
    };
    fetchReportDetails();
  }, [state]);


    const fetchObjects = async () => {
      try {
         const execAction = await loadApprovalDataObjects(id);
         setObjects(execAction.objects);
         setTargetShape(execAction.targetShape);
      } catch(err) {
         console.error('Error fetching Objects data:', err);
         setObjects(null);
      }
     }
     
     useEffect(() => {
       if(id){
         fetchObjects();
       }
     }, [id]);

  return (
    <DefaultLayout>
      <h1><Link to={ROUTES.views.path}>Views</Link> &raquo; <Link to={ROUTES.view_detail.path.replace(':detail','optimization-report')}>Optimization Report</Link> &raquo; {id}</h1>
      {reportDetails && (
        <>
        <div className={style.approvalDetails}>
          <h2>Approved actions</h2>
        </div>
        <ShapeDetail data={reportDetails} skip={['objects']} />
         <>
           <h2>Applied to</h2>
           <ObjectDetails id={state.id}/> 
         </>
        </>
      )}
      </DefaultLayout>
  );
}
export default OptimizationReportDetail;