import React, {useEffect, useState, useCallback, useContext} from 'react'
import { AdSet, Campaign } from "titan-ads/lib/shapes/TitanShapes";
import {DefaultLayout} from '../layout/DefaultLayout';
import AdManagement from './AdManagement';
import AdSetManagement from './AdSetManagement';
import ApprovalPage from './ApprovalPage';
import CampaignManagement from './CampaignManagement';
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';

import {InstanceOverview} from '../components/InstanceOverview'
import { TitanQueryConfig, loadAdSetData, loadExecutableAction, generateCustomColumns  } from '../utils';
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import { debounce } from 'lodash';
import { Spinner } from '../components/Spinner';
import {useCustomColumns} from '../hooks/useCustomColumns';
import { useRowContext } from '../hooks/useRowContext';
import { packageName } from '../package';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { ExecutableAction } from 'titan-ads/lib/shapes/ExecutableAction';
import { useNavigate, Routes, useParams, useLocation, generatePath, Link} from 'react-router-dom';

interface DynamicObject {
  [key: string]: any;
}

interface CustomColumn {
  property?: { label?: string };
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}

function CampaignManagementDetail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uri = queryParams.get('uri');

  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([]);
  const [pagination, _setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const [campaignData,setCampaignData] = useState<DynamicObject>();
  const [adsetData, setAdsetData] =  useState<DynamicObject[]>();
  const navigate = useNavigate();

  const [filtersData, setFiltersData] = useState([]);

  const setPagination = (val) => {
    return _setPagination(val);
  }

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page
    }, 500), // 500ms debounce delay
    []
  );

  async function fetchAdSetData() {
    try {
      const adSetData = await loadAdSetData({
        searchQuery: searchQuery,
        filters:[{
          shapeField: 'campaign',
          value: Campaign.getFromURI(uri)
        },...filtersData],
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting: sorting,
      });
      setAdsetData(adSetData.instances);
      setTotalItems(adSetData.numInstances);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    Server.call(packageName,'loadSingleCampaignData', uri).then(data => {
      setCampaignData(data);
    })
  },[uri])

  useEffect(() => {
    fetchAdSetData();
  },[pagination.pageIndex, pagination.pageSize, sorting, searchQuery,campaignData,filtersData])

  const shape: NodeShape = AdSet.shape;
  const customColumns = useCustomColumns(adsetData);

  const detailViewHandler = (row) => {
    navigate(`/view/adset-management/details?uri=${row.id}`);
  }

  return (
    <DefaultLayout>
      <h1>
        <Link to={ROUTES.views.path}>Views</Link> &raquo; <Link to={generatePath(ROUTES.view_detail.path,{detail: "campaign-management"})}>Campaign Management</Link> &raquo; {campaignData?.name || campaignData?.quoraName || campaignData?.identifier}
      </h1>
      {campaignData && <ShapeDetail data={campaignData} />}
      <br />
      {adsetData && customColumns && (
        <>
          <h2>AdSets</h2>
          <InstanceOverview
            instances={adsetData}
            shape={shape}
            customColumns={customColumns}
            pagination={pagination}
            totalItems={totalItems}
            onPaginationChange={setPagination}
            sorting={sorting}
            onSortingChange={setSorting}
            onSearchChange={debouncedSetSearchQuery}
            isLight={true}
            filterInstances={(filters: any) => {
              setFiltersData(filters);
            }}
            onDetailsClick={detailViewHandler}
          />
        </>
      )}
    </DefaultLayout>

  )
}

export default CampaignManagementDetail