import * as React from "react"
import "./Table.scss";
import style from "./Table.scss.json"
import { cl } from "lincd/lib/utils/ClassNames";

interface RootProps extends React.HTMLAttributes<HTMLTableElement> {
  isStickyHeader?: boolean
}
const Root = React.forwardRef<
  HTMLTableElement,
  RootProps
>(({ className, isStickyHeader, ...props }, ref) => (
  <div className={cl(style.root, isStickyHeader ? style.isStickyHeader : '')}>
    <table
      ref={ref}
      className={cl(style.table, className)}
      {...props}
    />
  </div>
))
Root.displayName = "Root"

const Header = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cl(style.header, className)} {...props} />
))
Header.displayName = "Header"

const Body = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cl(style.body, className)}
    {...props}
  />
))
Body.displayName = "Body"

const Footer = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cl(style.footer, className)}
    {...props}
  />
))
Footer.displayName = "Footer"

const Row = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cl(style.row, className)}
    {...props}
  />
))
Row.displayName = "Row"

const Head = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cl(style.head, className)}
    {...props}
  />
))
Head.displayName = "Head"

const Cell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cl(style.cell, className)}
    {...props}
  />
))
Cell.displayName = "Cell"

const Caption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cl(style.caption, className)}
    {...props}
  />
))
Caption.displayName = "Caption"

export const Table = {
    Root,
    Header,
    Body,
    Footer,
    Row,
    Head,
    Cell,
    Caption
}