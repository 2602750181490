import {NamedNode} from 'lincd/lib/models';
import {Shape} from 'lincd/lib/shapes/Shape';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import React from 'react';

export function formatSpend(spend: number): string {
  return spend && (spend / 10_000)?.toFixed(2);
}

export function formatPercentage(value: number): string {
  return (Math.round(value * 100) / 100).toFixed(2) + '%';
}

export function calculatePercentage(part: number, total: number): string {
  return total > 0 ? ((part / total) * 100).toFixed(2) : '0.00';
}

export function formatCurrency(amount: number): string {
  return amount && '$'+(amount)?.toFixed(2).toLocaleString();
}

export const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export function formatValue(value: any): string|React.ReactNode {
  if(typeof value === 'string' && value.match(/(?:__|[*#])|\[(.*?)\]\(.*?\)/)) {
    //parse out link and title of link
    const [,title,url] = value.match(/\[(.*?)\]\((.*?)\)/);
    return <a href={url} target={'_blank'} rel="noreferrer">{
      title
    }</a>

  }
  if(typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if(value instanceof NamedNode) {
    console.warn('NamedNode:',value);
    value = value.uri;
  }
  if(value instanceof Shape) {
    value = value.uri;
  }
  if(typeof value === 'number') {
    value = formatNumberFrontend(value);
  }
  if(value instanceof ShapeSet) {
    //convert value to string, which will then follow automatic shortening of string
    value = value.map(v => v.uri).join(", ");
  }
  if(typeof value === 'string') {
    if(value.length > 100) {
      return value.slice(0,100) + '...';
    }
    return value;
  }
  if(Array.isArray(value)) {
    if(value.length > 0) {
      return value.join(', ');
    }
  }
  else if (typeof value === 'object' && value !== null) {
    console.log('converting object to json:',value);
    return JSON.stringify(value); // Or access specific properties you want to render.
  }
  return value;
}
export function formatDate(value?: string): string {
  return `${new Date(value || new Date()).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  })}`;
}

/**
 * Only use this on the frontend, because it will mess up sorting when used on the backend
 * @param number
 */
export function formatNumberFrontend(number: number): string {
  return number && number?.toLocaleString();
}

export function formatLink(text: any, link: string): string {
  return `[${text}](${link})`;
}


/**
 * Test Data from https://docs.google.com/spreadsheets/d/1o24SG4Inewi-YqHPeMKFNXwrmsh1KuFr6TfM1HFgP3g/edit?gid=0#gid=0
 * This data for generate quicklaunch data
 */
export const QUICKLAUNCH_DATA = [{
  link: "https://semantu.com",
  businessName: "Semantu",
  headlines: [
    {
      headline:'Unlock AI for Smarter Business Growth',
      description:'Unlock Power'
    },
    {
      headline:'Tailored Tools for Maximum Impact',
      description:'Tailored Tools'
    },
    {
      headline:'Effortless Data Insights with AI Simplicity',
      description:'Effortless Data'
    },
    {
      headline:'Take Control of Your Data with Powerful AI',
      description:'Take Control'
    }
  ],
  sublines: [
    {
      subline: 'Transform data into insights with Semantu’s AI tools, driving better decisions and business success.',
      description: 'Transform data',
    },
    {
      subline: 'Leverage Semantu’s technology to streamline operations, boost productivity, and elevate your strategy.',
      description: 'Streamline operations',
    },
    {
      subline: 'Cutting edge technology to create deeper partnerships with a robust collaborative framework.',
      description: 'Deeper Partnerships',
    },
    {
      subline: 'Semantu equips your business with powerful tools to stay ahead of the competition.',
      description: 'Semantu equips',
    }
  ],
  actions: ['Learn More', 'Start Now', '', ''],
  images: ['ai-woman', 'man-at-desk', 'handshake', ''],
},
  {
    link: "https://lincd.org",
    businessName: "LINCD",
    headlines: [
      {
        headline:'Open-Source Collaboration for a Better Future',
        description:'Building better'
      },
      {
        headline:'Connect, Innovate, and Impact with LINCD',
        description:'Impact with LINCD'
      },
      {
        headline:'Powering Impactful Solutions with Open-Source Tech',
        description:'Powering Impact'
      },
      {
        headline:'Supercharge Your Mission with Open-Source Technology',
        description:'Supercharge'
      }
    ],
    sublines: [
      {
        subline: 'LINCD empowers organizations to create scalable, impactful solutions for a more sustainable world.',
        description: 'Empowers',
      },
      {
        subline: 'Join a global network using LINCD’s open-source tech to drive change and solve pressing challenges.',
        description: 'Global Network',
      },
      {
        subline: 'LINCD’s protocol equips organizations with tools to deliver sustainable solutions effectively.',
        description: 'Equips Impact',
      },
      {
        subline: 'LINCD empowers organizations to grow impact using scalable, innovative tech for the greater good.',
        description: 'Greater Good',
      }
    ],
    actions: ['Learn More', 'Start Now', '', ''],
    images: ['protecting_earth', 'earth-in-hands', 'woman-at-computer', ''],
  }];