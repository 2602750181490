import * as React from 'react';
import './Tabs.scss';
import style from './Tabs.scss.json';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cl } from 'lincd/lib/utils/ClassNames';

// const Root = TabsPrimitive.Root;
function extend<C extends React.FunctionComponent<any>>(
  Component: C,
  baseClassName,
) {
  let extended = React.forwardRef<
    React.ElementRef<C>,
    React.ComponentPropsWithoutRef<C>
  >(({className, ...props}, ref) =>
    React.createElement(Component, {
      ref,
      className: cl(baseClassName, className),
      ...props,
    }),
  );
  extended.displayName = Component.displayName;
  return extended;
}
const Root = extend(TabsPrimitive.Root, style.Root);
const List = extend(TabsPrimitive.List, style.List);
const Trigger = extend(TabsPrimitive.Trigger, style.Trigger);
const Content = extend(TabsPrimitive.Content, style.Content);

export const Tabs = {
  Root,
  List,
  Trigger,
  Content,
};
export {Root, List, Trigger, Content};