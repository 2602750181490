import React, {useEffect, useState, useCallback, useContext} from 'react'
import {DefaultLayout} from '../layout/DefaultLayout';
import { Ad, AdSet, Campaign } from "titan-ads/lib/shapes/TitanShapes";
import {generatePath,Link,useParams} from 'react-router-dom';
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { TitanQueryConfig, loadAdData  } from '../utils';
import { packageName } from '../package';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import {InstanceOverview} from '../components/InstanceOverview'
import {useCustomColumns} from '../hooks/useCustomColumns';

interface DynamicObject {
  [key: string]: any;
}

interface CustomColumn {
  property?: { label?: string };
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}

function AdManagementDetail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uri = queryParams.get('uri');

  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([]);
  const [pagination, _setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const [adData,setAdData] = useState<DynamicObject>();

  useEffect(() => {
    Server.call(packageName,'loadSingleAdData', uri).then(data => {
      setAdData(data);
    })
  },[uri])


  return (
    <DefaultLayout>
      <h1><Link to={ROUTES.views.path}>Views</Link> &raquo; <Link to={generatePath(ROUTES.view_detail.path,{ detail: "ad-management" })}>Ad Management</Link> &raquo; {adData?.fullAdName} </h1>
      {adData && <ShapeDetail data={adData} />}
    </DefaultLayout>

  )
}

export default AdManagementDetail