
import React from 'react';
import './ShapeDetail.scss';
import style from './ShapeDetail.scss.json';
import { formatValue } from '../utils/data';

function ShapeDetail({ data, skip }: { data: any; skip?: string[] }) {
  if (!data || (typeof data !== 'object' && !Array.isArray(data))) {
    console.error('ShapeDetail expects data to be an object or an array, but received:', data);
    return null;
  }

  // Normalize `data` to always be an array for consistent processing
  const dataArray = Array.isArray(data) ? data : [data];

  return (
    <div>
      {dataArray.map((singleObject, index) => (
        <div key={index} className={style.shapeDetailContainer}>
          {Object.entries(singleObject)
            .filter(([key]) => key !== 'id' && (!skip || !skip.includes(key)))
            .map(([key, value], idx, arr) => (
              value !== undefined && value !== null && value !== '' && (
                <React.Fragment key={key}>
                  <div className={style.detailItem}>
                    <span className={style.label}>
                      {key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (firstChar) => firstChar.toUpperCase())}:
                    </span>
                    <span className={style.value}>
                      {Array.isArray(value)
                        ? value.map((item) => formatValue(item)).join(', ')
                        : formatValue(value) || '-'}
                    </span>
                  </div>
                </React.Fragment>
              )
            ))}
            <br/>
        </div>
      ))}
    </div>
  );
}

export default ShapeDetail;
