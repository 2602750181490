import {ExecutableAction} from 'titan-ads/lib/shapes/ExecutableAction';
import {Literal, NamedNode} from 'lincd/lib/models';
import {Ad, AdSet, Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {SelectedAction} from '../components/ActionDropdown';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {Boolean} from 'lincd-xsd/lib/shapes/Boolean';
import {xsd} from 'lincd/lib/ontologies/xsd';
import {DynamicObject} from '../utils';
import { automation1, automations } from '../data/automations';
import { titanAds } from 'titan-ads/lib/ontologies/titan-ads';
import { Thing } from 'lincd-schema/lib/shapes/Thing';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';

export interface ActionField {
  id?: string;
  name: string;
  label: string | ((formData: any) => string);
  type?: string | ((formData: any) => string);
  placeholder?: string | ((formData: any) => string);
  step?: string | ((formData: any) => string);
  checked?: boolean;
  options?: { label: string; value: string }[]; // For select fields
  value?: string | number;
  pattern?: string | ((formData: any) => string);
  suffix?: string | ((formData: any) => string);
  required?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, formData?: any) => void;
}

export interface ActionConfig
{
  targetShape: NodeShape;
  actions: SingleActionConfig[];
}

interface SingleActionConfig
{
  value: string;
  method?: string; // Optional method field
  requiresApproval?: boolean;
  label: string;
  fields: (ActionField[]) | ((rows:any[]) => ActionField[]);
  onSubmit?: (formData: {[key: string]: any},selectedAction: SelectedAction,selectedItems?: DynamicObject[]) => void|{message:string, type?:'success'|'error'}; // Accept additionalData
}

export const useBatchActions = () => {
  const adBatchActions: ActionConfig = {
    targetShape: Ad.shape,
    actions: [
      {
        value: 'turnoffNotProfitable',
        method: 'optimisationTurnOffNotProfitable',
        label: 'Turn Off and Label KEEP OFF (Not Profitable)',
        fields: [notesField,usernameField],
        requiresApproval: false,
      },
      {
        value: 'turnoffRevival',
        method: 'optimisationTurnOffRevival',
        label: 'Turn Off and Label KEEP OFF (Better Ad in Ad Set/Revival)',
        fields: [notesField,usernameField],
        requiresApproval: false,
      },
      {
        value: 'turnonRevival',
        method: 'optimisationTurnOnRevival',
        label: 'Turn Back On and Label KEEP ON (Revival)',
        fields: [notesField,usernameField],
        requiresApproval: false,
      },
      {
        value: 'launchTopPerformingCreative',
        method: 'launchTopPerformingCreative',
        label: 'Launch Top Performing Creative',
        fields: [notesField,usernameField],
        requiresApproval: true,
      },
      {
        value: 'launchNewAds',
        method: 'launchNewAds',
        label: 'Launch New Ads',
        fields: [notesField,usernameField],
        requiresApproval: true,
      },
      {
        value: 'turnon',
        method: 'turnOn', //preferably camelCase
        label: 'Resume',
        fields: [notesField,usernameField],
        requiresApproval: false,
      },
      {
        value: 'turnoff',
        method: 'turnOff',
        label: 'Pause',
        fields: [notesField,usernameField],
        requiresApproval: false,
      },
      // {
      //   value: 'change_status_guidance',
      //   method: 'statusGuidance',
      //   label: 'Change Status Guidance',
      //   fields: [
      //     {
      //       name: 'statusGuidance',
      //       label: 'Status Guidance',
      //       type: 'select',
      //       options: [
      //         {label: 'Keep On',value: 'keep_on'},
      //         {label: 'Keep Off (Not Profitable)',value: 'keep_off'},
      //         {label: 'Paused',value: 'paused'},
      //         {label: 'Untested',value: 'untested'},
      //       ],
      //     },
      //     notesField,
      //     usernameField,
      //   ],
      //   onSubmit: (formData: any,action: ExecutableAction) => {
      //     const {statusGuidance} = formData;
      //     // action.targetShape = Ad.shape;
      //     action.parameters.add(new Literal(statusGuidance));
      //   },
      // },
      {
        value: 'change_bid_amount',
        method: 'setBidAmountInQuora',
        label: 'Change Adset Bid Amount',
        fields: [
          {
            name: 'bidType',
            label: 'Update By',
            type: 'radio',
            options: [
              { label: 'New Amount', value: 'fixed' },
              { label: 'Percentage', value: 'percentage' },
            ],
            value: 'fixed', // Default selection,
            required: true,
          },
          {
            name: 'adSetBid', //this name should be matched with the mapping fields/properties on utils/views/AdManagement.tsx, otherwise the value from db won't be loaded into frontend
            label: (formData) => (formData.bidType === 'percentage' ? 'Fill in a positive number to increase the bid amount by this percentage. To decrease use a negative number' : ''),
            type: 'number',
            step: (formData) => (formData.bidType === 'percentage' ? '1' : '0.01'),
            placeholder: (formData) => (formData.bidType === 'percentage' ? 'Enter percentage change...' : 'Enter bid amount...'),
            pattern: (formData) => (formData.bidType === 'percentage' ? '^-?\\d+$' : ''), // Whole numbers for percentage
            suffix: (formData) => (formData.bidType === 'percentage' ? '%' : ''), // Show % for percentage
            onKeyDown: (e, formData) => {
              if (
                formData && formData.bidType === 'percentage' &&
                (e.key === '.' || e.key === ',')
              ) {
                e.preventDefault();
              }
            },
            required: true,
          },
          notesField,
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction, selectedRows) => {
          action.parameters.add(new Literal(formData.adSetBid, xsd.integer));
          action.parameters.add(new Literal(formData.bidType, xsd.string));
        },
      },
    ],
  };

  const adSetBatchActions: ActionConfig = {
    targetShape: AdSet.shape,
    actions: [
      {
        value: 'turnon',
        method: 'turnOn', //preferably camelCase
        label: 'Resume',
        fields: [notesField,usernameField],
        requiresApproval: false,
      },
      {
        value: 'turnoff',
        method: 'turnOff',
        label: 'Pause',
        fields: [notesField,usernameField],
        requiresApproval: false,
      },
      {
        value: 'change_bid_amount',
        method: 'setBidAmountInQuora',
        label: 'Change Bid Amount',
        fields: [
          {
            name: 'bidType',
            label: 'Update By',
            type: 'radio',
            options: [
              { label: 'New Amount', value: 'fixed' },
              { label: 'Percentage', value: 'percentage' },
            ],
            value: 'fixed', // Default selection,
            required: true,
          },
          {
            name: 'bidAmount',
            label: (formData) => (formData.bidType === 'percentage' ? 'Fill in a positive number to increase the bid amount by this percentage. To decrease use a negative number' : ''),
            type: 'number',
            step: (formData) => (formData.bidType === 'percentage' ? '1' : '0.01'),
            placeholder: (formData) => (formData.bidType === 'percentage' ? 'Enter percentage change...' : 'Enter bid amount...'),
            pattern: (formData) => (formData.bidType === 'percentage' ? '^-?\\d+$' : ''), // Whole numbers for percentage
            suffix: (formData) => (formData.bidType === 'percentage' ? '%' : ''), // Show % for percentage
            onKeyDown: (e, formData) => {
              if (
                formData && formData.bidType === 'percentage' &&
                (e.key === '.' || e.key === ',')
              ) {
                e.preventDefault();
              }
            },
            required: true,
          },
          notesField,
          usernameField,
        ],
        onSubmit: (formData: any, action: ExecutableAction, selectedItems) => {
          if(selectedItems.some((item) => {
            //if the item is not created from a template, or does not have an identifier
            //then we did not create it in this dashboard, and we cannot edit it
            //so we should not allow the action to be executed
            return !item.isQuickLaunched && !item.dateAdSetLaunched;
          })) {
            return {message: 'Cannot edit adSets that were not created in this dashboard.\n\n' +
                'Check that column "Date AdSet Launched" is not empty.', type: 'error'
            };
          }
          if(selectedItems.some((item) => item.placement === 'Digest Emails')) {
            return {message: 'Cannot edit adSets with placement "Digest Emails"', type: 'error'};
          }
          action.parameters.add(new Literal(formData.bidAmount, xsd.integer));
          action.parameters.add(new Literal(formData.bidType, xsd.string));
        },
      },
      {
        value: 'setOptimized',
        method: 'optimized',
        label: 'Mark as optimized',
        fields: [
          {
            name: 'optimized',
            label: 'Optimized',
            type: 'boolean'
          },
          notesField,
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction) => {
          action.parameters.add(Boolean.toLiteral(formData.optimized === true));
        },
      },
      {
        value: 'chooseAutomations',
        method: 'setAutomations',
        requiresApproval: false,
        label: 'Choose Automations',
        fields: (rows) => automationFields(rows, automations, 'adset'),
        onSubmit: (formData:any,action:ExecutableAction, selectedItems) => {      
          const hasChecked = Object.values(formData).some(data => (data as any).checked);
          
          // if (Object.keys(formData).length === 0 || !hasChecked) {
          //     return {message:"At least one checkbox must be checked", type: 'error'};
          // }
          Object.entries(formData).forEach(([key, data]) => {
              if ((data as any).checked) { // Only process if checked is true
                  const objectNode = NamedNode.getOrCreate((data as any).value);
                  action.set(titanAds.parameters, objectNode);
              }
          });

          return {message:"The selected automations have been saved!", type: 'success'}
        }
      }
    ],
  };

  const campaignBatchActions: ActionConfig = {
    targetShape: Campaign.shape,
    actions: [
      {
        value: 'turnon',
        method: 'turnOn', //preferably camelCase
        label: 'Resume',
        fields: [notesField,usernameField],
      },
      {
        value: 'turnoff',
        method: 'turnOff',
        label: 'Pause',
        fields: [notesField,usernameField],
      },
      {
        value: 'change_daily_maximum_budget',
        method: 'changeDailyMaximumBudget',
        label: 'Change Daily Maximum Budget',
        fields: [
          {
            name: 'dailyMaximumBudget',
            label: 'Daily Maximum Budget',
            type: 'number',
            placeholder: 'Enter budget...',
          },
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction) => {
          action.parameters.add(new Literal(formData.dailyMaximumBudget));
        },
      },
    ],
  };

  const approvalBatchActions = [
    {
      value: 'approve',
      method: 'approve',
      label: 'Approve',
      fields: [usernameField],
      onSubmit: (formData: any,selectedRows) => {
        return Server.call(packageName,'approveActions',formData.userName,selectedRows);
      },
    },
    {
      value: 'decline',
      method: 'decline',
      label: 'Decline',
      fields: [usernameField],
      onSubmit: (formData: any,selectedRows) => {
        return Server.call(packageName,'declineActions',formData.userName,selectedRows);
      },
    },
  ];

  return {adBatchActions, adSetBatchActions, campaignBatchActions, approvalBatchActions};
};


const notesField: ActionField = {
  name: 'notes',
  label: 'Notes',
  type: 'textarea',
  placeholder: 'Enter notes here...',
};
const usernameField: ActionField = {
  name: 'userName',
  label: 'Name of User',
  type: 'text',
  placeholder: 'Enter your name...',
};
const automationFields = (
  rows: any[], 
  automations: ShapeSet<Thing>, 
  filterDescription: 'ad'|'adset'|'campaign'
): ActionField[] => {
  let automationField = automations
    .filter((automation) => automation.disambiguatingDescription === filterDescription)
    .map((automation) => {
      const rowAutomations = rows.map((row) => [row.automations]);
      const checked = rowAutomations.every((row) =>
        row.some((entry) => entry.includes(automation.name)) // Check if the automation exists in the row
      );

      return {
        name: automation.identifier.toString(),
        label: `<b style="color:black;">${automation.name}</b><br><i style="color:black;">(${automation.description})</i>`,
        type: 'boolean',
        checked: checked,
        value: automation.uri,
      };
    });
  return [usernameField, ...automationField];
};

