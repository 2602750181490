import React, { useEffect, useState } from 'react';
import style from '../../pages/Automations.scss.json';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../../package';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { cl } from 'lincd/lib/utils/ClassNames';
import { automation3 } from '../../data/automations';
import { ROUTES } from '../../routes';
import { Icons } from '../Icons';
import { Tooltip } from '../Tooltip';
import { Table } from '../Table';

type AdState = {
  uri: string;
  shortName: string;
  quoraId: string;
};

type AdsState = {
  adsToPause: AdState[];
  adsKeepRunning: AdState[];
  adsKeepRunningReasons: Record<
    string,
    {
      rule: string;
      message: string;
    }[]
  >;
};

interface RunConvertingAdsProps {
  onRunConvertingAds?: () => Promise<AdsState | null>; // Optional prop to override default behavior
  onPauseAds?: (uris: string[]) => Promise<boolean>; // Optional prop to override default pause behavior
  onGetLastExecuted?: () => Promise<{ currentTime: string; lastExecuted: string } | null>; // Optional prop for last executed
}

export function RunConvertingAds({
  onRunConvertingAds,
  onPauseAds,
  onGetLastExecuted,
}: RunConvertingAdsProps) {
  const [ads, setAds] = useState<AdsState | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastExecuted, setLastExecuted] = useState<{ currentTime: string; lastExecuted: string } | null>(null);
  const [selectedAds, setSelectedAds] = useState<Set<string>>(new Set());
  const [selectAll, setSelectAll] = useState<boolean>(true);

  useEffect(() => {
    handleGetLastExecuted();
  }, []);

  const handleRunConvertingAds = async () => {
    setLoading(true);
    try {
      const response = onRunConvertingAds
        ? await onRunConvertingAds()
        : await Server.call(packageName, 'runConvertingAds');
      setAds(response);

      if (response?.adsToPause && selectAll) {
        const allUris = new Set<string>(response.adsToPause.map((ad: AdState) => ad.uri));
        setSelectedAds(allUris);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleGetLastExecuted = async () => {
    try {
      const response = onGetLastExecuted
        ? await onGetLastExecuted()
        : await Server.call(packageName, 'getLastExecuted');
      setLastExecuted(response);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCheckboxChange = (uri: string) => {
    setSelectedAds((prevSelectedAds) => {
      const newSelectedAds = new Set(prevSelectedAds);
      if (newSelectedAds.has(uri)) {
        newSelectedAds.delete(uri);
      } else {
        newSelectedAds.add(uri);
      }
      setSelectAll(newSelectedAds.size === ads?.adsToPause.length);
      return newSelectedAds;
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll && ads?.adsToPause) {
      const allUris = new Set<string>(ads.adsToPause.map((ad) => ad.uri));
      setSelectedAds(allUris);
    } else {
      setSelectedAds(new Set<string>());
    }
  };

  const handlePauseAds = async () => {
    setLoading(true);
    try {
      const uris = Array.from(selectedAds);
      const response = onPauseAds
        ? await onPauseAds(uris)
        : await Server.call(packageName, 'runPausedAds', uris);

      if (!response) {
        alert('Paused Ads Failed!');
        return;
      }

      setAds((prevAds) => {
        if (!prevAds) return prevAds;
        const newAdsToPause = prevAds.adsToPause.filter((ad) => !uris.includes(ad.uri));
        return { ...prevAds, adsToPause: newAdsToPause };
      });
      setSelectedAds(new Set<string>());
      handleGetLastExecuted();
      alert('Paused Ads Successfully!');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={cl(style.headerContent)}>
        <h3>
          {automation3.name} {automation3?.alternateName ? `(${automation3.alternateName})` : ''}
        </h3>
        <p>{automation3.description}</p>
        <button
          className={style.btn}
          disabled={loading}
          onClick={handleRunConvertingAds}
        >
          {loading ? 'Retrieving...' : 'Retrieve Ads'}
        </button>
        {lastExecuted && (
          <p className={style.lastExecuted}>
            Last Executed:{' '}
            <span>{dayjs(lastExecuted.lastExecuted).format('MMMM D, YYYY h:mm A')} PST</span>
            <br />
            Current Time:{' '}
            <span>{dayjs(lastExecuted.currentTime).format('MMMM D, YYYY h:mm A')} PST</span>
            <br />
          </p>
        )}
        <div className={style.info}>
          <h4>This will pause all currently running ads EXCEPT the following ads:</h4>
          <ol>
            <li>2+ sales with a positive gross P&L from the same weekday the prior week.</li>
            <li>
              1+ sale after the time of execution from 2 or more of the last 4 days (this accounts
              for afternoon/nighttime user behaviour variance).
            </li>
            <li>1+ sale yesterday after the time of execution.</li>
            <li>2+ sales from any of the same past 4 week days.</li>
            <li>1+ sale from any of the same past 4 week days after the time of execution.</li>
            <li>2+ sales with a positive gross P&L on the same day</li>
          </ol>
        </div>
      </div>

      {ads?.adsKeepRunning && ads.adsKeepRunning.length > 0 && (
        <div className={style.tableBlock}>
          <h5>Keep Running: {ads.adsKeepRunning.length} Ads</h5>
          <div className={cl(style.tableWrapper, style.withoutScroll)}>
            <Table.Root isStickyHeader>
              <Table.Header>
                <Table.Row>
                  <Table.Head className={style.cellFull}>Ad Name</Table.Head>
                  <Table.Head className={style.cellAction}>Rules</Table.Head>
                  <Table.Head className={style.cellAction}>DPT</Table.Head>
                  <Table.Head className={style.cellAction}>Quora</Table.Head>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {ads.adsKeepRunning.map((ad) => {
                  const today = dayjs().format('YYYY-MM-DD');
                  const dptLink = `${ROUTES.dayPartingTrackerDetail.path}?uri=${ad.uri}&date=${today}`;
                  const quoraLink = `https://www.quora.com/ads/ad_performance?id=${ad.quoraId}`;

                  return (
                    <Table.Row key={ad.uri}>
                      <Table.Cell className={style.cellFull}>{ad.shortName}</Table.Cell>
                      <Table.Cell className={style.cellAction}>
                        <Tooltip.Provider>
                          <Tooltip.Root>
                            <Tooltip.Trigger>
                              <Icons.InfoCircled width={16} height={16} />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                              <ul>
                                {ads.adsKeepRunningReasons[ad.uri]?.map((reason, index) => (
                                  <li key={index}>{reason.message}</li>
                                ))}
                              </ul>
                            </Tooltip.Content>
                          </Tooltip.Root>
                        </Tooltip.Provider>
                      </Table.Cell>
                      <Table.Cell className={style.cellAction}>
                        <Link to={dptLink} target="_blank">
                          <Icons.Link2 />
                        </Link>
                      </Table.Cell>
                      <Table.Cell className={style.cellAction}>
                        <Link to={quoraLink} target="_blank">
                          <Icons.Link2 />
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table.Root>
          </div>
        </div>
      )}

      {ads?.adsToPause && ads.adsToPause.length > 0 && (
        <div className={style.tableBlock} style={{ border: 0 }}>
          <h5>Will Be Paused: {ads.adsToPause.length} Ads</h5>
          <div className={cl(style.tableWrapper)}>
            <Table.Root isStickyHeader>
              <Table.Header>
                <Table.Row>
                  <Table.Head className={style.cellCheckbox}>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </Table.Head>
                  <Table.Head className={style.cellFull}>Ad Name</Table.Head>
                  <Table.Head className={style.cellAction}>DPT</Table.Head>
                  <Table.Head className={style.cellAction}>Quora</Table.Head>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {ads.adsToPause.map((ad) => {
                  const today = dayjs().format('YYYY-MM-DD');
                  const dptLink = `${ROUTES.dayPartingTrackerDetail.path}?uri=${ad.uri}&date=${today}`;
                  const quoraLink = `https://www.quora.com/ads/ad_performance?id=${ad.quoraId}`;

                  return (
                    <Table.Row key={ad.uri}>
                      <Table.Cell>
                        <input
                          type="checkbox"
                          checked={selectedAds.has(ad.uri)}
                          onChange={() => handleCheckboxChange(ad.uri)}
                        />
                      </Table.Cell>
                      <Table.Cell className={style.cellFull}>{ad.shortName}</Table.Cell>
                      <Table.Cell className={style.cellAction}>
                        <Link to={dptLink} target="_blank">
                          <Icons.Link2 />
                        </Link>
                      </Table.Cell>
                      <Table.Cell className={style.cellAction}>
                        <Link to={quoraLink} target="_blank">
                          <Icons.Link2 />
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table.Root>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <button
              className={style.btn}
              onClick={handlePauseAds}
              disabled={loading || selectedAds.size === 0}
            >
              {loading ? 'Pausing...' : `Pause ${selectedAds.size > 0 ? selectedAds.size : ''} Ads`}
            </button>
          </div>
        </div>
      )}
    </>
  );
}