import React from 'react';
import './Views.scss';
import * as styles from './Views.scss.json';
import {ROUTES} from '../routes';
import {generatePath} from 'react-router-dom';
import {DefaultLayout} from '../layout/DefaultLayout';
import {PageCard} from '../components/PageCard';
import {
  FaArrowCircleRight,
  FaCogs,
  FaLink,
  FaLock,
  FaSearch,
  FaUserFriends,
 FaAdversal, 
 FaChartBar} from 'react-icons/fa';
import { TbReportMoney } from "react-icons/tb";
import { TbReportAnalytics } from "react-icons/tb";
import { MdApproval } from "react-icons/md";
import { HiDocumentReport } from "react-icons/hi";
import { TiDocumentText } from "react-icons/ti";

function Views() {

  return (
    <DefaultLayout>
      <h1>Views</h1>
      <div className={styles.viewsManagement}>
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'ad-optimization-report'
        })} icon={FaAdversal} label={'Ad Optimization Report'} />
         <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'day-parting-tracker'
        })} icon={FaAdversal} label={'Day Parting Tracker'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'day-parting-summary'
        })} icon={TbReportAnalytics} label={'Day Parting Summary'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'daily-sales-report'
        })} icon={TbReportMoney} label={'Daily Sales Report'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'media-mix-report'
        })} icon={HiDocumentReport} label={'Media Mix Report'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'approval-page'
        })} icon={MdApproval} label={'Approval Page'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'optimization-report'
        })} icon={FaChartBar} label={'Optimization Log'} />
        <PageCard route={generatePath(ROUTES.templater.path, {
          detail: 'templater'
        })} icon={TiDocumentText} label={'Templates'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'adset-management'
        })} icon={FaAdversal} label={'AdSet Management'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'campaign-management'
        })} icon={FaAdversal} label={'Campaign Management'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'automations'
        })} icon={FaCogs} label={'Automations'} />
      </div>
    </DefaultLayout>
  )
}

export default Views