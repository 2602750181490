import React,{useEffect,useState} from 'react';
import {NamedNode} from 'lincd/lib/models';
import {Shape} from 'lincd/lib/shapes/Shape';
import {CustomColumn,DynamicObject} from '../utils';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {formatValue} from '../utils/data';
import { TableColumn } from '../utils/views/TableDataProvider';

export function useCustomColumns(
  instances: DynamicObject[],
  columnInfo?:TableColumn<any>[],
  customColumnConfig?: { [key: string]: { className?: (value: any, row: DynamicObject) => string } }
) {
  const [customColumns, setCustomColumns] = useState<CustomColumn[] | undefined>(undefined);

  useEffect(() => {
    if(columnInfo && columnInfo.length) {

      // Generate columns for each unique key
      const columns = columnInfo.map((singleCol) => {
        let label = singleCol.label;
        let info = singleCol.info;

        return {
          label,
          info,
          id:singleCol.key as string,
          // property: {label: label},
          meta: {
            className: (value: any, row: DynamicObject) => {
              if (customColumnConfig && customColumnConfig[singleCol.key as string]?.className) {
                return customColumnConfig[singleCol.key as string].className(row[singleCol.key as string], row);
              }
              return '';
            },
          },
          renderCell: (row: DynamicObject) => {
            const value = row[singleCol.key as string]; // Access the `value` field for the current key
            return formatValue(value); // Format the value
          },
        };
      });

      setCustomColumns(columns);
    } else if (instances && instances.length > 0) {

      // Collect all unique keys from all instances
      const allKeys = new Set<string>();

      instances.forEach((instance) => {
        Object.keys(instance).forEach((key) => {
          if(key !== 'id') {
            allKeys.add(key)
          }
        });
      });

      // Generate columns from all unique keys
      const columns = Array.from(allKeys)
        .map((key) => {
          return {
            label: key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase()),
            id: key,
            meta:{
              className: (value,row: DynamicObject) => {
                if(customColumnConfig && customColumnConfig[key] && customColumnConfig[key].className) {
                  return customColumnConfig[key].className(value,row);
                }
                return '';
              }
            },
            renderCell: (row: DynamicObject) => {
              const value = row[key];
              
              // split value by comma to display multiple values
              // we need to make sure the value is a string before split
              // currently we use on objects field on Approval and Optimization Report page
              // e.g. [001F](.../AdSet/316659354150273), [003F](.../AdSet/316659354247310)
              // const getValues = value && typeof value === 'string' && value?.split(',');
              // if (getValues && getValues.length > 1) {
              //   return (
              //     <div>
              //       {getValues.map((val: string, index: number) => {
              //         const format = formatValue(val, key.startsWith('$'));
              //         // remove comma from last value
              //         return (
              //           <span key={index}>
              //             {format}
              //             {index < getValues.length - 1 && ', '}
              //           </span>
              //         );
              //       })}
              //     </div>
              //   );
              // } else {
                return formatValue(value);
              // }
            },
          };
        });

      setCustomColumns(columns);
    }
  }, [instances, customColumnConfig]);

  return customColumns;
}