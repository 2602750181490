import React,{useEffect,useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';
import {generatePath,Link,useLocation,useNavigate} from 'react-router-dom';
import {DynamicObject, loadApprovalDataObjects} from '../utils';
import {packageName} from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {titanAds} from 'titan-ads/lib/ontologies/titan-ads';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { TimeRangeProps, TimeRangeValue } from '../types';
import { Spinner } from '../components/Spinner';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import { NamedNode } from 'lincd/lib/models';
import './ObjectDetails.scss';
import style from './ObjectDetails.scss.json';

// add UTC plugin to dayjs to handle UTC date
dayjs.extend(utc);

export default function ObjectDetails({id, data}: {id: string, data?: DynamicObject})
{
  const [engagementMetric, setEngagementMetric] = useState<string>('dailyClicks');
  const [metricData, setMetricData] = useState<DynamicObject>();
  const [objects, setObjects] = useState<NamedNode[]>();
  const [targetShape, setTargetShape] = useState<NamedNode>();

  // set default time range to last 7 days
  const [timeRange, setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7,'day').utc().format('YYYY-MM-DD'), // default is 7 days ago,
    endDate: dayjs().utc().format('YYYY-MM-DD'), // default is today
  });

  const [timeRangeOptions, setTimeRangeOptions] = useState<TimeRangeValue[]>([
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'last7days', label: 'Last 7 days' },
    { value: 'last14days', label: 'Last 14 days' },
    { value: 'last30days', label: 'Last 30 days' },
    { value: 'customSingleDate', label: 'Custom single date' },
  ])

  const fetchObjects = async () => {
    try {
       const execAction = await loadApprovalDataObjects(id);
       setObjects(execAction.objects);
       setTargetShape(execAction.targetShape);
    } catch(err) {
       console.error('Error fetching Objects data:', err);
       setObjects(null);
    }
   }
   
   useEffect(() => {
     if(id){
       fetchObjects();
     }
   }, [id]);

  // fetch engagement metric data by uri
  useEffect(() => {
    if (!data && !id) return;

    Server.call(
      packageName,
      'getObservationsByShape',
      id,
      targetShape,
      [
        titanAds.dailyClicks,
        // titanAds.dailyImpressions,
        titanAds.bidAmount,
        // titanAds.dailySpend
      ],
      timeRange
    )
      .then(data => setMetricData(data))
      .catch(error => console.error('error fetching metric data:',error));
  },[timeRange, id]);

  const auth = useAuth();
  const isSemantu = auth.userAccount.email.includes('@semantu.com');
  return (
    <>
      <div>
       {objects ? <ShapeDetail data={objects} /> : <Spinner />}
        {/* {isSemantu && data && (metricData ? <ObjectChart
          data={metricData}
          metric={engagementMetric}
          onMetricChange={setEngagementMetric}
          timeRange={timeRange}
          timeRangeOptions={timeRangeOptions}
          onTimeRangeChange={setTimeRange}
        /> : <Spinner />)} */}
      </div>
    </>
  );
}
