import React, {useEffect, useState, useCallback, useContext, useMemo} from 'react'
import {DefaultLayout} from '../layout/DefaultLayout';
import { Ad, AdSet, Campaign } from "titan-ads/lib/shapes/TitanShapes";
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { TitanQueryConfig, loadAdData  } from '../utils';
import { packageName } from '../package';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import {InstanceOverview} from '../components/InstanceOverview'
import {useCustomColumns} from '../hooks/useCustomColumns';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent, MarkAreaComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { titanAds } from 'titan-ads/lib/ontologies/titan-ads';
import dayjs from 'dayjs';
import { TimeRangeProps } from '../types';
import { FilterTimeRange } from '../components/FilterTimeRange';
import { Select } from '../components/Select';
import './AdSetManagementDetail.scss';
import style from './AdSetManagementDetail.scss.json';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer, MarkAreaComponent]);
import { useNavigate, generatePath, Link, useParams } from 'react-router-dom';

interface DynamicObject {
  [key: string]: any;
}

interface CustomColumn {
  property?: { label?: string };
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}


function AdSetManagementDetail()
{
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uri = queryParams.get('uri');

  const id = uri.split('/').pop();
  return <DefaultLayout>
    <h1><Link to={ROUTES.views.path}>Views</Link> &raquo; <Link to={generatePath(ROUTES.view_detail.path,{ detail: "adset-management" })}>AdSet Management</Link> &raquo; {id} </h1>
    <AdSetDetails id={uri} />
  </DefaultLayout>;
}
export function AdSetDetails({id,data}:{id:string,data?:DynamicObject}) {

  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([]);
  const [pagination, _setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const [adsetData,setAdsetData] = useState<DynamicObject>(data);
  const [adData, setAdData] =  useState<DynamicObject[]>();
  const navigate = useNavigate();
  const [filtersData, setFiltersData] = useState([]);

  const [engagementMetric, setEngagementMetric] = useState<string>('dailyImpressions');
  const [metricData, setMetricData] = useState<DynamicObject>();

  // set default time range to last 7 days
  const [timeRange, setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), // default is 7 days ago,
    endDate: dayjs().format('YYYY-MM-DD'), // default is today
  });


  const setPagination = (val) => {
    return _setPagination(val);
  }

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page
    }, 500), // 500ms debounce delay
    []
  );

  async function fetchAdData() {
    try {
      const adData = await loadAdData({
        searchQuery: searchQuery,
        filters:[{
          shapeField:'adSet',
          value: AdSet.getFromURI(id)
        }],
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting: sorting,
      });
      setAdData(adData.instances);
      setTotalItems(adData.numInstances);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if(!data)
    {
      Server.call(packageName,'loadSingleAdSetData',id).then(data => {
        setAdsetData(data);
      })
    }
  },[id,data])

  useEffect(() => {
    fetchAdData();
  },[pagination.pageIndex, pagination.pageSize, sorting, searchQuery,adsetData])

  const detailViewHandler = (row) => {
    navigate(`/view/ad-management/details?uri=${row.id}`);
  }

  const shape: NodeShape = Ad.shape;
  const customColumns = useCustomColumns(adData);

  // fetch engagement metric data by uri
  useEffect(() => {
    if (!adsetData && !id) return;

    Server.call(
      packageName,
      'getObservationsByShape',
      id,
      AdSet,
      [titanAds.dailyClicks, titanAds.dailyImpressions, titanAds.bidAmount],
      timeRange.startDate,
      timeRange.endDate
    )
      .then(data => setMetricData(data))
      .catch(error => console.error('error fetching metric data:', error));
  }, [timeRange, adsetData, id]);

  const engagementChartOptions = useMemo(() => {
    const markAreas = [];
    let start = null;

    const xAxisData = metricData?.[engagementMetric]?.map((obs) => dayjs(obs.date).format('MMM D')) || [];
    const xAxisValue = metricData?.[engagementMetric]?.map(obs => parseFloat(obs.value)) || [];

    // find the start and end of the `0` value.
    // when the value is `0`, it means the ad is paused
    // we use this to mark the area in the chart
    metricData?.[engagementMetric]?.forEach((obs, index) => {
      const value = parseFloat(obs.value); // convert value to number
      if (value === 0 && start === null) {
        start = index;
      } else if (value !== 0 && start !== null) {
        markAreas.push([{ xAxis: xAxisData[start] }, { xAxis: xAxisData[index - 1] }]);
        start = null;
      }
    });

    if (start !== null) {
      markAreas.push([{ xAxis: xAxisData[start] }, { xAxis: xAxisData[metricData[engagementMetric].length - 1] }]);
    }

    return {
      title: {
        text: `Engagement Metric`,
        textStyle: {
          fontSize: 15
        }
      },
      grid: {
        top: '50px',
        bottom: '30px',
        right: '25px',
        left: '50px',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: engagementMetric,
          type: 'line',
          smooth: true,
          data: xAxisValue,
          markArea: {
            itemStyle: {
              color: 'rgba(156, 180, 184, 0.4)'
            },
            data: markAreas
          }
        }
      ]
    };
  }, [engagementMetric, metricData]);

  const bidAmountChartOptions = useMemo(() => {
    const markAreas = [];
    let start = null;

    const xAxisData = metricData?.bidAmount?.map((obs) => dayjs(obs.date).format('MMM D')) || [];

    // find the start and end of the `0` value.
    // when the value is `0`, it means the ad is paused
    // we use this to mark the area in the chart
    metricData?.bidAmount?.forEach((obs, index) => {
      const value = parseFloat(obs.value); // convert value to number
      if (value === 0 && start === null) {
        start = index;
      } else if (value !== 0 && start !== null) {
        markAreas.push([{ xAxis: xAxisData[start] }, { xAxis: xAxisData[index - 1] }]);
        start = null;
      }
    });

    if (start !== null) {
      markAreas.push([{ xAxis: xAxisData[start] }, { xAxis: xAxisData[metricData.bidAmount.length - 1] }]);
    }

    const xAxisValue = metricData?.bidAmount?.map(obs => parseFloat(obs.value)) || [];

    return {
      title: {
        text: 'Bid Amount',
        textStyle: {
          fontSize: 15
        }
      },
      grid: {
        top: '50px',
        bottom: '30px',
        right: '25px',
        left: '50px',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Bid Amount',
          type: 'line',
          step: 'start',
          data: xAxisValue,
          markArea: {
            itemStyle: {
              color: 'rgba(156, 180, 184, 0.4)'
            },
            data: markAreas
          }
        }
      ],
    };
  }, [metricData]);

  return (
    <>
      <div>
        {adsetData && <ShapeDetail data={adsetData} />}
        {/*<div>*/}
        {/*  <div className={style.chartCard}>*/}
        {/*    <div className={style.chartFilter}>*/}
        {/*      <label>*/}
        {/*        Filter by*/}
        {/*        <Select value={engagementMetric} onChange={(e) => setEngagementMetric(e.target.value)} style={{marginLeft: '.5rem'}}>*/}
        {/*          <option value="dailyImpressions">Impressions</option>*/}
        {/*          <option value="dailyClicks">Clicks</option>*/}
        {/*        </Select>*/}
        {/*      </label>*/}
        {/*      <FilterTimeRange name="metric" label="Time Range" timeRange={timeRange} onHandleChange={(newTime: TimeRangeProps) => setTimeRange(newTime)} />*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <ReactEChartsCore*/}
        {/*        echarts={echarts}*/}
        {/*        option={engagementChartOptions}*/}
        {/*        notMerge={true}*/}
        {/*        lazyUpdate={true}*/}
        {/*        theme={'light'}*/}
        {/*        style={{ maxWidth: '100%'}}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={style.chartCard}>*/}
        {/*    <div>*/}
        {/*      <ReactEChartsCore*/}
        {/*        echarts={echarts}*/}
        {/*        option={bidAmountChartOptions}*/}
        {/*        notMerge={true}*/}
        {/*        lazyUpdate={true}*/}
        {/*        theme={'light'}*/}
        {/*        style={{ maxWidth: '100%'}}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        
      </div>
      {adData && customColumns && (
        <>
          <h2>Ads</h2>
          <InstanceOverview
            instances={adData}
            shape={shape}
            customColumns={customColumns}
            pagination={pagination}
            totalItems={totalItems}
            onPaginationChange={setPagination}
            sorting={sorting}
            onSortingChange={setSorting}
            onSearchChange={debouncedSetSearchQuery}
            isLight={true}
            onDetailsClick={detailViewHandler}
            filterInstances={(filters: any) => {
              setFiltersData(filters);
            }}
          /></>
      )}
    </>

  )
}

export default AdSetManagementDetail