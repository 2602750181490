import React, {useEffect, useState, useCallback, useContext, useMemo} from 'react';
import {Ad} from 'titan-ads/lib/shapes/TitanShapes';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {TitanQueryConfig, loadAdData, isDateToday} from '../utils';
import {Spinner} from '../components/Spinner';
import {InstanceOverview} from '../components/InstanceOverview';
import {debounce} from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import AdActionDropdown from '../components/AdActionDropdown';
import {useCustomColumns} from '../hooks/useCustomColumns';
import { useNavigate } from 'react-router-dom';
import './AdOptimizationReport.scss';
import styles from './AdOptimizationReport.scss.json';
import DatePickerFilter from '../components/DatePickerFilter';
import {TimeRangeProps,TimeRangeValue, FilterProps} from '../types';
import dayjs from 'dayjs';
import { DashboardContext } from '../contexts/Dashboard';
import { TableColumn } from '../utils/views/TableDataProvider';

interface DynamicObject {
  [key: string]: any;
}

interface AdManagementProps {
  batchActions?: any; // Adjust the type as needed
}

function AdOptimizationReport({batchActions}: AdManagementProps) {
  const navigate = useNavigate();
  const {isLight} = useContext(ThemeContext); // Access the theme context
  const [instances, setInstances] = useState<DynamicObject[]>();
  const [columns, setColumns] = useState<TableColumn<any>[]>();
  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([
    {
      id: 'clicks',
      desc: true,
    },
  ]);

  const maxDateBack = useMemo(() => {
    const date = new Date();
    date.setTime(date.getTime() - 32 * 24 * 60 * 60 * 1000);
    return date;
  }, []); 

  const [pagination, _setPagination] = useState({pageIndex: 0, pageSize: 10}); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const [timeRangeOptions, setTimeRangeOptions] = useState<TimeRangeValue[]>([
    // { value: 'yesterday', label: 'Yesterday' },//NOTE: dangerous to turn on since it triggers single date mode => DayPartingTracker mapping
    { value: 'last7days', label: 'Last 7 days' },
    { value: 'last14days', label: 'Last 14 days' },
    { value: 'last30days', label: 'Last 30 days' },
    { value: 'customDateRange', label: 'Custom date range', disabled: [
      { after: new Date(), before: maxDateBack },
      new Date(), // Disable today's date
    ]},
  ])

  const initialFilter = [
    { field: 'impressions', value: '0', operator: 'greater' }
  ];
  const [filtersData, setFiltersData] = useState<FilterProps[]>(initialFilter);
  // const [filtersData, setFiltersData] = useState([{ field: 'isActive', value: 'Yes', operator: 'equal' }]);

  const [dataLoading, setDataLoading] = useState(true);

  // set default time range to last 7 days
  const [timeRange,setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), // default is 7 days ago
    endDate: dayjs().format('YYYY-MM-DD') // default is today,
  });

  const handleTimeRangeChange = (newTimeRange: TimeRangeProps) => {
    //make sure we always do a timerange, otherwise it triggers DayPartingTracker mapping
    if(newTimeRange.date) {
      newTimeRange.startDate = newTimeRange.date;
      newTimeRange.endDate = newTimeRange.date;
      delete newTimeRange.date;
    }
    setTimeRange(newTimeRange);
    // let today = dayjs().format('YYYY-MM-DD');
    // if(newTimeRange.endDate === today && newTimeRange.startDate === today){
    //   //redirect to DayPartingView
    //   navigate(`/view/day-parting-tracker`)
    // } else {
    //   setTimeRange(newTimeRange);
    // }
  };
  
  const shape: NodeShape = Ad.shape;

  const setPagination = (val) => {
    return _setPagination(val);
  };

  // const debouncedSetSearchQuery = useCallback(
  //   debounce((value) => setSearchQuery(value), 500), // 500ms debounce delay
  //   []
  // );

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({...prev, pageIndex: 0})); // Reset to first page
    }, 500), // 500ms debounce delay
    [],
  );

  const handleCheckboxActiveOnly = (checked:boolean) => {
    setFiltersData((prev) => {
      if(!prev) {
        prev = [];
      }
      let updatedFilters: FilterProps[] = [];
      if(checked) {
        // get the filter status `On`, to make sure the filter is not duplicated
        const exists = prev.some(filter => filter.field === 'impressions' && filter.value === '0' && filter.operator === 'greater');
        if (!exists) {
          updatedFilters = [...prev, { field: 'impressions', value: '0', operator: 'greater' }];
        } else {
          updatedFilters = prev;
        }
      } else {
        updatedFilters = prev.filter(filter => filter.field !== 'impressions');
      }
      return updatedFilters;
    });

  };


  async function fetchAdData() {
    try {
      setDataLoading(true);
      const adData = await loadAdData({
        searchQuery: searchQuery,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting,
        filters: filtersData,
        timeRange,
        includeColumns:!columns
      });
      setInstances(adData.instances);
      setTotalItems(adData.numInstances);
      if(adData.columns) {
        setColumns(adData.columns);
      }
      setDataLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setInstances([]);
    }
  }

  const handleRefreshData = (isRefreshing: boolean, newData: DynamicObject[]) => {
    if (isRefreshing) {
      const updatedInstances = instances.map((instance) => {
        const newInstance = newData.find((data) => data.id === instance.id);
        return newInstance ? newInstance : instance;
      });
      setInstances(updatedInstances);
    }
  };

  useEffect(() => {
      fetchAdData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    searchQuery,
    filtersData,
    timeRange,
  ]);

  // const handleFilterInstances = (data: any) => {
  //   if (typeof data === 'boolean' && data) {
  //     fetchAdData();
  //   } else if (data?.numInstances > 0) {
  //     setFilterActive(true);
  //     setInstances(data.instances as DynamicObject[]);
  //     // const totalPages = Math.ceil(data.numInstances / pagination.pageSize);
  //     // if (totalPages < pagination.pageIndex) {
  //     //   _setPagination((prev) => ({...prev, pageIndex: totalPages - 1}));
  //     //   setTotalItems(data.numInstances);
  //     // }
  //   }
  // };

  const customColumns = useCustomColumns(instances,columns, 
  {
    //to indicate the column, use the same key here as in the mapping object in views/AdManagement.tsx
    deliveringNow: {
      className: (value, row: DynamicObject) => {
        //you can use the row object if you need to access other values in the row
        if (value === 'No') {
          return styles.lightRed;
        } else if (value === 'Yes') {
          return styles.green;
        }
        return '';
      },
    },
    shortAdName: {
      className: (value, row: DynamicObject) => {
        //you can use the row object if you need to access other values in the row
        const deliveringNow = row.deliveringNow;
        if (deliveringNow === 'No') {
          return styles.lightRed;
        } else if (deliveringNow === 'Yes') {
          return styles.green;
        }
        return '';
      },
    },
    adOptimized: {
      className: (value, row: DynamicObject) => {
        //Show the cell in green if the value is 'Yes'.
        if (value === 'Yes') {
          return styles.green;
        }
        // return styles.lightRed;
      },
    },
    adCPC: {
      className: (value, row: DynamicObject) => {
        //Show the cell as green if the value is lower than adGrossPL value by the calculation of adGrossPL/2.262, show as yellow if the value is lower than adGrossPL value by the calculation of adGrossPL/1.5, and show as red if the value eqiuals higher than adGrossPL
        const adGrossPL = row.adGrossPL;
        if(!value) return;
        if (value < adGrossPL / 2.262) {
          return styles.green;
        } else if (value < adGrossPL / 1.5) {
          return styles.yellow;
        } else if (value >= adGrossPL) {
          return styles.red;
        }
      },
    },
    estimatedAdSpendSinceLastSale: {
      className: (value, row: DynamicObject) => {
        if(!row.adGrossPL || !row.adCPC) return;
        //Show the cell as green if the value is lower than adGrossPL value by the calculation of adGrossPL/2.262, show as yellow if the value is lower than adGrossPL value by the calculation of adGrossPL/1.5, and show as red if the value eqiuals higher than adGrossPL
        if (typeof row?.adGrossPL === 'string' || typeof row?.adCPC === 'string') {
        const adGrossPL = parseFloat(row?.adGrossPL.replace(/[,$]/g, ''));
        const adCPC = parseFloat(row?.adCPC?.replace(/[,$]/g, ''));
        value = row.estimatedAdSpendSinceLastSale;
        if(!value) {
          return;
        }
        if(adCPC === 0 || adGrossPL === 0) return;
        if (adCPC < adGrossPL / 2.262) {
          return styles.green;
        } else if (adCPC < adGrossPL / 1.5) {
          return styles.yellow;
        } else if (adCPC >= adGrossPL) {
          return styles.red;
        }
        }
      },
    },
    //Ad Gross P&L is red if negative, green if positive, and yellow if zero
    adGrossPL: {
      className: (value, row: DynamicObject) => {
        if(!row.adGrossPL) return;
        //get number from the row
        if (typeof row?.adGrossPL === 'string') {
          value = parseFloat(row.adGrossPL.replace(/[,$]/g, ''));
        }
        if (value < 0) {
          return styles.red;
        } else if (value > 0) {
          return styles.green;
        } else if (value === 0) {
          return styles.yellow;
        }
      },
    },
  }
);
// console.log("customColumns", customColumns)

  const detailsViewHandler = (row) => {
    navigate(`/view/ad-management/details?uri=${row.id}`);
    // navigate(`${location.pathname}/details?uri=${row.id}`);
  }

  // trigger the fetchAdData function when the brandAccount changes from quickFilter
  const {curAccount} = useContext(DashboardContext);
  const handleBranchAccountChange = (account: string) => {
    setFiltersData((prev) => {
      if (!prev) {
        prev = [];
      }
      // set the brandAccount filter if the brandAccount option selected
      if (curAccount && curAccount.name !== undefined) {
        // remove the previous brandAccount filter if it exists
        prev = prev.filter(filter => filter.field !== 'brandAccount');
        // add the new brandAccount filter
        return [...prev, { field: 'brandAccount', value: account, operator: 'equal' }];
      } else {
        return prev.filter(filter => filter.field !== 'brandAccount');
      }
    })
  }

  // trigger the fetch the data function when the filter changes
  // newFilters values, e.g. newFilters = [{ field: 'shortAdName', value: 'TF-247', operator: 'equal' }]
  // currentFilter, e.g = [{ field: 'status', value: 'On', operator: 'equal' }]
  // newFilter will replace currentFilter or current filter state
  const handleFilterChange = (newFilters: FilterProps[]) => {
    setFiltersData(newFilters);
  }
  
  return (
    <>
      {customColumns || instances?.length === 0 ? (
        <InstanceOverview
          instances={instances}
          shape={shape}
          customColumns={customColumns}
          isLoading={dataLoading}
          // defaultColumn={{
          //   meta: {
          //     className: (value,row) => 'status',
          //   }
          // }}
          pagination={pagination}
          initialFilter={filtersData}
          resetFilter={(value:boolean) => value && setFiltersData(initialFilter)}
          totalItems={totalItems}
          onRefreshingData={handleRefreshData}
          columnInfo={columns}
          // filterInstances={(filters: any) => fetchAdData(filters)}
          filterInstances={(filter: any) => handleFilterChange(filter)}
          quickFilter={[
            {
              property: 'impressions',
              label: 'Show only ads with impressions',
              onChange: (checked:boolean) => handleCheckboxActiveOnly(checked),
              value: filtersData?.find(filter => filter.field === 'impressions')?.value === '0',
              defaultValue: '0'
            },
            {
              property: 'brandAccount',
              onChange: (account:string) => handleBranchAccountChange(account),
              value: curAccount?.name,
            }
          ]}
          onPaginationChange={setPagination}
          sorting={sorting}
          onSortingChange={(...args) => {
            setSorting(...args);
          }}
          onSearchChange={debouncedSetSearchQuery}
          isLight={isLight}
          triggerActionRefresh={fetchAdData} 
          onDetailsClick={detailsViewHandler}
          batchActionDropdown={(selectedRowData) => (
            <AdActionDropdown
              selectedRowData={selectedRowData}
              // onComplete={fetchAdData}
            />
          )}
          timeRange={timeRange}
          timeRangeOptions={timeRangeOptions}
          onTimeRangeChange={handleTimeRangeChange}
          // dateFilter={() => (
          //   <DatePickerFilter onDateSelect={setSelectedDate} mode={'multiple'} />
          // )}
        />
      ) : (<Spinner />)}
    </>
  );
}

export default AdOptimizationReport;
