import React,{useEffect,useState} from 'react';
import {NamedNode} from 'lincd/lib/models';
import {Shape} from 'lincd/lib/shapes/Shape';
import {CustomColumn,DynamicObject} from '../utils';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {formatNumberFrontend,formatValue} from '../utils/data';

export function useCustomColumns(instances,customColumnConfig?:{[key:string]:{className?:(value:any,row:DynamicObject)=>string}}) {
  const [customColumns, setCustomColumns] = useState<CustomColumn[] | undefined>(undefined);
  useEffect(() => {
    if (instances && instances.length > 0) {

      // Collect all unique keys from all instances
      const allKeys = new Set<string>();

      instances.forEach((instance) => {
        Object.keys(instance).forEach((key) => {
          if(key !== 'id') {
            allKeys.add(key)
          }
        });
      });

      // Generate columns from all unique keys
      const columns = Array.from(allKeys)
        .map((key) => {
          return {
            label: key,
            property: {label: key
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/^./, (str) => str.toUpperCase())},
            meta:{
              className: (value,row: DynamicObject) => {
                if(customColumnConfig && customColumnConfig[key] && customColumnConfig[key].className) {
                  return customColumnConfig[key].className(value,row);
                }
                return '';
              }
            },
            renderCell: (row: DynamicObject) => {
              const value = row[key];
              return formatValue(value);
            },
          };
        });

      setCustomColumns(columns);
    }
  }, [instances])

  return customColumns;
}