import {Server} from 'lincd-server-utils/lib/utils/Server';
import {Suspense, useContext, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {Answer} from 'titan-ads/lib/shapes/Answer';
import {BrandAccount} from 'titan-ads/lib/shapes/TitanShapes';
import {
  AdInputPartial,
  InputTitle,
  QuoraCreativeType,
} from 'titan-ads/lib/types';
import {
  getInputsByPlatform,
  getPartialKey,
  getPartialKeyByTitle,
} from 'titan-ads/lib/utils';
import {InputsContext} from '../contexts/AdInputs';
import {DashboardContext} from '../contexts/Dashboard';
import {packageName} from '../package';
import '../pages/Dashboard.scss';
import dashboardStyles from '../pages/Dashboard.scss.json';
import '../pages/Quicklaunch.scss';
import quicklaunchStyles from '../pages/Quicklaunch.scss.json';
import {GroupID, MediaGalleryAsset} from '../types';
import {useSuspense} from '../utils';
import {TrackerURLs} from './Ad/TrackerURLs';
import {AdInput} from './AdInput';
import {Answers} from './AdSet/Answers';
import {BrowseMedia} from './FileUploader';
import './AdInput.scss';
import inputStyles from './AdInput.scss.json';
import './InputGroup.scss';
import styles from './InputGroup.scss.json';
import MediaUploadGallery from './MediaGallery';
import {BrandAccountSelector} from './RefreshQuoraData';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

interface AdColumnProps {
  groupId: GroupID;
  handleNextPriority: (
    curPriority?: number,
    setToCurPriority?: boolean,
  ) => void;
  priority: number;
}

const initialBrands = useSuspense(BrandAccount.loadAll());

export default function AdColumn({
  groupId,
  handleNextPriority,
  priority,
}: AdColumnProps) {
  const [brands, setBrands] = useState<{read(): any}>(initialBrands);

  useEffect(() => {
    setBrands(useSuspense(BrandAccount.loadAll()));
  }, []);

  const {curAccount, masterUrl} = useContext(DashboardContext);

  const [browseMedia, setBrowseMedia] = useState<boolean>(false);
  const [browseLogos, setBrowseLogos] = useState<boolean>(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<MediaGalleryAsset>();
  const [selectedLogo, setSelectedLogo] = useState<MediaGalleryAsset>();

  const {inputGroups, handleEditInput} = useContext(InputsContext);
  const {adSet, campaign, inputs, platform} = inputGroups[groupId];

  // If the creative type is "Text", it shouldn't show the Creative Asset URL
  const byCreativeType = (p: AdInputPartial) => {
    const shouldDisplayCreativeAssetURL =
      inputs[getPartialKeyByTitle(platform, 'Creative Type')] !== 'Text';

    return !(
      p.title === 'Creative Asset URL' && !shouldDisplayCreativeAssetURL
    );
  };
  const byPriority = (p: AdInputPartial) => p.priority <= priority;
  const byAdColumnAndNotInternal = (p: AdInputPartial) =>
    byPriority(p) && byCreativeType(p) && !p.internal && p.column === 'ad';
  const adInputs = getInputsByPlatform(platform).filter(
    byAdColumnAndNotInternal,
  );

  const openMediaBrowser = () => {
    setBrowseMedia(true);
  };

  useEffect(() => {
    setLoading(true);
    setGalleryImages(null);
    if (curAccount) {
      const sanitisedBrandName = curAccount.name
        .replace(/\s+/g, '_')
        .toLowerCase();
      const getAllImages = async () => {
        try {
          const images = await Server.call(
            packageName,
            'readImageFromDB',
            sanitisedBrandName,
          );
          if (images) {
            // Transform ImageObject[] to MediaGalleryAsset[]
            const galleryAssets = images.map((image) => ({data: image}));
            setGalleryImages(galleryAssets);
          }
        } catch (error) {
          setGalleryImages([]);
          console.error('Error reading images:', error);
        }
      };
      getAllImages();
      setLoading(false);
    }
  }, [curAccount]);

  const source = null;

  return (
    <>
      {adInputs.map((p) => {
        let className = styles.ad;
        if (p.required) {
          className += ` ${styles.required}`;
        }

        let initialValue: string;
        if (p.title === 'Tracker URLs') {
          initialValue = null;
          className += ` ${inputStyles.Dropdown}`;
          return (
            <div className={className}>
              <TrackerURLs groupId={groupId} />
            </div>
          );
        }
        if (p.title === 'Status') {
          initialValue = 'UNTESTED';
        }

        // On a 'Video Views' campaign, only a video media type is allowed
        if (
          p.title === 'Creative Type' &&
          campaign.conversionObjective === 'Video views'
        ) {
          p.options = p.options.filter(
            (option: QuoraCreativeType) => option === 'Video',
          );
        }

        const creativeType: QuoraCreativeType = inputGroups[groupId].inputs[
          getPartialKeyByTitle(platform, 'Creative Type')
        ] as QuoraCreativeType;

        if (
          (creativeType === 'PA' &&
            (
              ['Body Text', 'Headline', 'CTA', 'Brand Logo'] as InputTitle[]
            ).includes(p.title)) ||
          (creativeType === 'Text' && p.title === 'Brand Logo')
        ) {
          return;
        }

        if (
          p.title === 'Creative Asset URL' &&
          (['Image', 'Video'] as QuoraCreativeType[]).includes(creativeType)
        ) {
          return createPortal(
            <div className={styles.ad}>
              <input
                className={dashboardStyles.buttons}
                onClick={openMediaBrowser}
                type="button"
                value={`${selectedAsset ? 'Change' : 'Select'} ${creativeType}`}
              />
              {selectedAsset && (
                <span>
                  <b>Selected asset: </b>
                  {selectedAsset.data.contentUrl.split('/').pop()}
                </span>
              )}
              {browseMedia && galleryImages && (
                <MediaUploadGallery
                  galleryImages={galleryImages?.filter(
                    (asset) =>
                      asset.data.usageInfo === creativeType.toLowerCase(),
                  )}
                  isMediaSelected={false}
                  isSelected={[]}
                  isLoading={isLoading}
                  multiSelect={false}
                  disableUploadMore={true}
                  brandAccount={curAccount.name}
                  fileInputRef={null}
                  onMediaReady={(assets) => {
                    setSelectedAsset(assets[0]);
                    handleEditInput(groupId, p, assets[0].data.url ?? '');
                  }}
                  browseMedia={setBrowseMedia}
                />
              )}
            </div>,
            document.getElementById(
              getPartialKeyByTitle(platform, 'Creative Type'),
            ),
          );
        } else if (p.title === 'Creative Asset URL' && creativeType === 'PA') {
          return (
            <Answers
              allowMultiple={false}
              groupId={groupId}
              handleSuccessfulValidation={(answer: Answer) => answer.save()}
              partial={p}
            />
          );
        }

        if (p.title === 'Brand Logo') {
          return (
            <div className={`${styles.ad} ${styles.required}`}>
              <label style={{marginBottom: '-.4rem', marginTop: '1rem'}}>
                Select Brand Logo
              </label>
              {selectedLogo && (
                <span>
                  <b>{selectedLogo.data.contentUrl.split('/').pop()}</b> - ID:{' '}
                  {selectedLogo.data.identifier}
                </span>
              )}
              {!curAccount && (
                <Suspense fallback={<BrandAccountSelector.Loading />}>
                  <BrandAccountSelector showLabel={false} qAccounts={brands} />
                </Suspense>
              )}
              {curAccount && source && (
                <p className={styles.logoApproved}>
                  <img src={asset('/images/checked.png')} />
                  {source} - Approved
                </p>
              )}
              {curAccount && source === '' && (
                <p className={styles.logoApproved}>
                  <img src={asset('/images/mark.png')} />
                  Not Approved
                </p>
              )}
              {curAccount && (
                <BrowseMedia
                  browseMedia={() => {
                    setBrowseLogos(true);
                  }}
                />
              )}
              {browseLogos &&
                // TODO: Move the createPortal to inside MediaUploadGallery instead?
                // (would make it a more generalised/generic solution - less boilerplate
                // code needed)
                createPortal(
                  <div className={quicklaunchStyles.initialReport}>
                    <MediaUploadGallery
                      galleryImages={galleryImages.filter(
                        (asset) => asset.data.usageInfo === 'logo',
                      )}
                      brandAccount={curAccount.name}
                      isSelected={[]}
                      multiSelect={false}
                      browseMedia={(boolean) => setBrowseLogos(boolean)}
                      onMediaReady={(assets) => {
                        setSelectedLogo(assets[0]);
                        handleEditInput(groupId, p, assets[0].data.identifier);
                      }}
                      isMediaSelected={false}
                      disableUploadMore={true}
                      fileInputRef={undefined}
                    />
                  </div>,
                  document.getElementById('app-main'),
                )}
            </div>
          );
        }

        let key;
        if (campaign && adSet) {
          key = `${campaign.name}-${adSet.name}-${getPartialKey(p)}`;
        }

        return (
          <AdInput
            className={className}
            groupId={groupId}
            handleNextPriority={handleNextPriority}
            id={getPartialKey(p)}
            inputData={p}
            initialValue={initialValue}
            key={key}
          />
        );
      })}
    </>
  );
}
