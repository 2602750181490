import { Thing } from 'lincd-schema/lib/shapes/Thing';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';
import {NamedNode} from 'lincd/lib/models';

function getAutomation(id: string) {
  //force temporary node (not saving updates)
  let node = NamedNode.getOrCreate(process.env.DATA_ROOT + '/automations/' + id,true);
  // node.isTemporaryNode = true;
  return new Thing(node);
}
export const automation1 = getAutomation('1');
automation1.identifier = 'automation1';
automation1.name = 'Reset Bid Amounts';
automation1.alternateName = 'Find Delivery Threshold';
automation1.description = 'Every 20 minutes between 1:10am - 4:10am for the selected ad sets. This automation essentially finds the delivery threshold for the ad set. Increases bid with 5% if clicks = 0. Keeps the bid the same if clicks > 0. Pauses the ad set if it reaches 25 clicks without sale.';
automation1.disambiguatingDescription = 'adset'; //to who this automation is intended for

export const automation2 = getAutomation('2')
automation2.identifier = 'automation2';
automation2.name = 'Limit Ad Spend';
automation2.description = 'Runs every 20 minutes at all times for all ads. It pauses ads if their spend since last sale goes over a certain percentage of payout. The percentage is 50% of payout from 12am to 8am and then 90% of payout from 8am onwards.';
automation2.disambiguatingDescription = 'ad';

export const automation3 = getAutomation('3')
automation3.identifier = 'automation3';
automation3.name = 'Run Converting Ads';
automation3.description = 'Pause all ads that are not converting.';
//don't use this property, because this automation is global and should not show up for any specific ad/adset
//automation3.disambiguatingDescription = 'ad';

export const automation4 = getAutomation('4')
automation4.identifier = 'automation4';
automation4.name = 'Reset Bid Amounts (2)';
automation4.alternateName = 'Start Of Day';
automation4.description = 'At 5:00am PST, this automation resumes all ad sets that were paused in the "Reset bid amount" automation. That is, if the ad set reached 25 clicks and therefor got paused, this automation resumes it';

export const automation5 = getAutomation('5')
automation5.identifier = 'automation5';
automation5.name = 'Run Profitable Ads';
automation5.description = 'Pause all ads that are not profitable.';

export const automations = new ShapeSet<Thing>([
    automation1,
    automation2,
    automation3,
    automation4,
    automation5
])
