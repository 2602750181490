import {useContext, useEffect, useState} from 'react';
import {Topic} from 'titan-ads/lib/shapes/Topic';
import {InputsContext} from '../../contexts/AdInputs';
import {DashboardContext} from '../../contexts/Dashboard';
import styles from '../InputGroup.scss.json';
import {SearchInput} from './SearchInput';
import {GroupID} from '../../types';
import {AdInputPartial} from 'titan-ads/lib/types';
import {getPartialKey} from 'titan-ads/lib/utils';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import { titanAds } from 'titan-ads/lib/ontologies/titan-ads';

interface TopicsProps {
  groupId: GroupID;
  partial: AdInputPartial;

  disabled?: boolean;
}

export function Topics({disabled = false, groupId, partial}: TopicsProps) {
  const {curAccount} = useContext(DashboardContext);
  const {handleEditInput, inputGroups} = useContext(InputsContext);

  const {adSet, inputs} = inputGroups[groupId];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Topic[]>([]);
  const [chosen, setChosen] = useState<Topic[]>([...adSet.topics]);

  useEffect(() => {
    const newValue = chosen.map((topic) => topic.name).join(',');
    const oldValue = inputs[getPartialKey(partial)];
    if (newValue !== oldValue) handleEditInput(groupId, partial, newValue);
  }, [chosen]);

  //this makes sure that state is updated when graph data updates outside of this component
  // also, works when user copy from template
  useEffect(() => {
    setChosen([...adSet.topics]);
  }, [adSet.topics.map((t) => t.name).join(',')]);

  const doSearch = (query: string) => {
    setIsLoading(true);
    curAccount.quoraAccount
      .getTopics(query)
      .then((res) => {
        if (res) {
          setResults(res.filter((r) => !chosen.find((c) => c.id === r.id)));
        } else {
          alert('No results found, please retry!');
        }
      })
      .finally(() => setIsLoading(false));
  };

  const onChooseResult = (result: Topic) => {
    setResults((prev) => prev.filter((r) => r.id !== result.id));
    // save list all topics to the temporary state - prevent re-fetching    
    const updatedChosen = [...chosen, result].filter((topic, index, self) =>
      index === self.findIndex((t) => t.id === topic.id)
    );
    // use `set` to add Topic to adSet.topics since `.add` is not working
    // adSet.topics.add(result);
    adSet.set(titanAds.hasTopics, result.namedNode)
    setChosen(updatedChosen);
  };

  const deleteTopic = (topic: Topic) => {
    const updatedChosen = chosen.filter((t) => t.id !== topic.id);
    // use `unset` to remove Topic from adSet.topics since `.delete` is not working
    adSet.unset(titanAds.hasTopics, topic.namedNode);
    setChosen(updatedChosen);

    // after delete topic, we need to make sure that the topic is showing again in the search result
    // and showing in the first place
    setResults((prev) => [topic, ...prev]);
  };

  const topicResult = results.filter((result) => !chosen.find((topic) => topic.id === result.id));

  return (
    <div className={`${styles.adset} ${styles.required}`} title="Topics">
      {chosen.map((topic) => (
      <div
        key={topic.id}
        style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: '15px',
        height: '15px',
        }}
      >
        <p>{topic.name}</p>
        <img
        onClick={() => deleteTopic(topic)}
        src={asset('/images/cross.png')}
        style={{width: '15px', height: '15px', marginLeft: '5px'}}
        />
      </div>
      ))}
      <SearchInput
        disabled={disabled}
        doSearch={doSearch}
        isLoading={isLoading}
        label={'Topics'}
        onChooseResult={onChooseResult}
        processResult={(result) => result.name}
        results={topicResult}
      />
    </div>
  );
}
