import React,{useCallback,useContext,useEffect,useState} from 'react';
import {ExecutableAction} from 'titan-ads/lib/shapes/ExecutableAction';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {CustomColumn,DynamicObject,generateCustomColumns,loadExecutableAction} from '../utils';
import {InstanceOverview} from '../components/InstanceOverview';
import {debounce} from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import {TimeRangeProps} from '../types';
import {Spinner} from '../components/Spinner';

function OptimizationReport()
{
  const {isLight} = useContext(ThemeContext);

  // State for Complete Action Status
  const [completeInstances,setCompleteInstances] = useState<DynamicObject[]>();
  const [completeTotalItems,setCompleteTotalItems] = useState(0);
  const [completeCustomColumns,setCompleteCustomColumns] = useState<CustomColumn[] | undefined>(undefined);
  const [completeSorting,setCompleteSorting] = useState([{id: 'requested',desc: false}]); // sort `requested` to DESC
  const [completePagination,setCompletePagination] = useState({pageIndex: 0,pageSize: 10});
  const [completeSearchQuery,setCompleteSearchQuery] = useState('');
  const [completeFiltersData,setCompleteFiltersData] = useState(null);

  // set default time range to last 7 days
  const [completeTimeRange,setCompleteTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: '',
    endDate: '',
  });

  const shape: NodeShape = ExecutableAction.shape;

  const debouncedSetCompleteSearchQuery = useCallback(
    debounce((value) => {
      setCompleteSearchQuery(value);
      setCompletePagination((prev) => ({...prev,pageIndex: 0}));
    },500),
    [],
  );

  // Fetch data for complete actions
  async function fetchCompleteData(filters?: any)
  {
    try
    {
      const completeData = await loadExecutableAction({
        searchQuery: completeSearchQuery,
        pageSize: completePagination.pageSize,
        pageIndex: completePagination.pageIndex,
        sorting: completeSorting,
        actionStatus: 2,
        filters: completeFiltersData ? completeFiltersData : undefined,
        timeRange: completeTimeRange,
      });
      setCompleteInstances(completeData.instances);
      setCompleteTotalItems(completeData.numInstances);
    } catch (error)
    {
      console.error('Error fetching complete data:',error);
    }
  }

  // Set up custom columns for complete actions
  useEffect(() => {
    if (completeInstances && completeInstances.length > 0)
    {
      setCompleteCustomColumns(generateCustomColumns(completeInstances));
    }
  },[completeInstances]);

  useEffect(() => {
    // Fetch data immediately when dependencies change
    fetchCompleteData(completeFiltersData ? completeFiltersData : null);

    let intervalId;
    // Set up interval to refetch every 15 seconds
    if (completeInstances && completeInstances.length > 0)
    {
      intervalId = setInterval(() => {
        fetchCompleteData(completeFiltersData ? completeFiltersData : null);
      },5000); // 15 seconds
    }

    // Cleanup interval on component unmount or when dependencies change
    return () => intervalId && clearInterval(intervalId);
  },[
    completePagination.pageIndex,
    completePagination.pageSize,
    completeSorting,
    completeSearchQuery,
    completeFiltersData,
    completeTimeRange,
  ]);

  // handle time range change
  const handleTimeRangeChange = (newTimeRange: TimeRangeProps) => {
    setCompleteTimeRange(newTimeRange);
  };

  return (
    <>
      <h2>Approved actions</h2>
      {completeInstances ? <>
          {(completeInstances.length > 0 && completeCustomColumns) ?
            <InstanceOverview
              instances={completeInstances}
              shape={shape}
              customColumns={completeCustomColumns}
              pagination={completePagination}
              totalItems={completeTotalItems}
              filterInstances={(filters: any) => {
                setCompleteFiltersData(filters);
              }}
              resetFilter={() => {
                setCompleteFiltersData(null);
              }}
              onPaginationChange={setCompletePagination}
              sorting={completeSorting}
              onSortingChange={setCompleteSorting}
              onSearchChange={debouncedSetCompleteSearchQuery}
              isLight={isLight}
              timeRange={completeTimeRange}
              onTimeRangeChange={handleTimeRangeChange}
              // batchActionDropdown={(selectedRows) => <ApprovalActionDropdown selectedRows={selectedRows} />}
            /> : <span>No approved actions found.</span>}
        </>
        : <Spinner />}
    </>
  );
}

export default OptimizationReport;