import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './ShapeTable.scss';
import style from './ShapeTable.scss.json';
import {Icons} from './Icons';
import {cl} from 'lincd/lib/utils/ClassNames';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {Spinner} from './Spinner';
import ModalPopup from './ModalPopup';
import { FilterProps, QuickFilter, QuickFilters, TimeRangeProps, TimeRangeValue } from '../types';
import { FilterTimeRange } from './FilterTimeRange';
import { BrandAccountSelector } from './RefreshQuoraData';
import {CustomColumnDef, DynamicObject,useSuspense} from '../utils';
import { BrandAccount } from 'titan-ads/lib/shapes/TitanShapes';
import { Popover } from './Popover';
import { DashboardContext } from '../contexts/Dashboard';
import { FilterBadge, FilterTable } from './FilterTable';
import { generatePath, Link, useSearchParams } from 'react-router-dom';
import { Tooltip } from './Tooltip';
import { TableColumn } from '../utils/views/TableDataProvider';
import { ROUTES } from '../routes';

interface ShapeTableProps {
  data: any | ShapeSet<any>;
  columns: CustomColumnDef<any>[];
  defaultColumn?:Partial<CustomColumnDef<any>>;
  pageReport?: string;
  initialFilter?: FilterProps[];
  filterInstances: (filters: FilterProps[]) => void;
  resetFilter: (value: boolean) => void;
  toggleSelectAll: () => void;
  isRowSelected: (id: string) => boolean;
  selectedRows: string[];
  toggleRowSelection: (id: string) => void;
  isDeleteBtnShowing: boolean;
  shape?: NodeShape;
  onRefreshingData?: (isRefreshing: boolean, instances?: any) => void;
  pagination: {pageIndex: number; pageSize: number};
  totalItems: number;
  onPaginationChange: (newPagination: {
    pageIndex: number;
    pageSize: number;
  }) => void;
  sorting: Array<{
    id: string;
    desc: boolean;
  }>;
  onSortingChange: (any) => void;
  onSearchChange: (any) => void;
  timeRange?: TimeRangeProps;
  timeRangeOptions?: TimeRangeValue[];
  onTimeRangeChange?: (timeRange: TimeRangeProps) => void;
  isLight?: boolean;
  columnInfo?:TableColumn<any>[];
  selectedRowData?:DynamicObject[];
  isLoading?:boolean;
  triggerActionRefresh: (any) => void;
  quickFilter?: QuickFilters[];
  batchActionDropdown?: React.ReactNode;
  dateFilter?: React.ReactNode;
  onDetailsClick: (any) => void;
}

const initialAccounts = useSuspense(BrandAccount.loadAll());

function ShapeTable({
  data,
  columns,
  columnInfo,
  defaultColumn,
  toggleSelectAll,
  filterInstances,
  initialFilter,
  resetFilter,
  isRowSelected,
  isLoading,
  selectedRows,
  toggleRowSelection,
  isDeleteBtnShowing,
  selectedRowData,
  shape,
  onRefreshingData,
  pagination, // Receive pagination state
  totalItems, // Receive total items count
  onPaginationChange, // Receive pagination change handler
  sorting,
  quickFilter,
  onSortingChange,
  onSearchChange,
  isLight,
  triggerActionRefresh,
  batchActionDropdown,
  dateFilter,
  onDetailsClick,
  timeRange,
  timeRangeOptions,
  onTimeRangeChange,
}: ShapeTableProps
) {
  const table = useReactTable({
    columns,
    defaultColumn,
    data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: () => {
      onPaginationChange(pagination);
    },
    pageCount: Math.ceil(totalItems / pagination.pageSize), // Calculate total pages
    onSortingChange: onSortingChange,
  });

  // Hacks full-width on table management view when on desktop
  useEffect(() => {
    const paths = ['adset-management', 'ad-management', 'campaign-management','optimization-report','approval-page','ad-optimization-report','day-parting-tracker'];
    if (paths.some(path => location.pathname.includes(`/view/${path}`))) {
      const elements = document.querySelectorAll('.titan_DefaultLayout_main, .titan_DefaultLayout_mainDark');
      elements.forEach(element => {
        (element as HTMLElement).style.maxWidth = 'none';
      });
    }
  }, [location.pathname]);

  const [loading, setLoading] = React.useState(false);
  const handleLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  // get brand accounts
  const accountsResource = initialAccounts;
  const {curAccount} = useContext(DashboardContext);

  // quick filter by status and brand account
  // console.log(`quickFilter: `, JSON.stringify(quickFilter));
  const quickFilterStatus = quickFilter && quickFilter?.find((filter) => filter.property === 'status') as QuickFilter<boolean>;
  const quickFilterBrand = quickFilter && quickFilter?.find((filter) => filter.property === 'brandAccount') as QuickFilter<string>;
  const quickFilterImpressions = quickFilter && quickFilter?.find((filter) => filter.property === 'impressions') as QuickFilter<boolean>;

  useEffect(() => {
    // disable load query params on first reload
    // when filterInstances is not set
    if (!filterInstances) return;

    const filters: FilterProps[] = [];

    // check if query params is exists
    // and then set query params URL only on first load
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.toString()) {
      searchParams.forEach((value, key) => {
        const [field, operator] = key.split('_');

        // skip if param is `uri`, this used for detail page
        if (field === 'uri') return;
        filters.push({ field, operator, value });
      });

    // if query param not set, set with initialFilter if exists
    } else if (initialFilter && initialFilter.length > 0) {
      filters.push(...initialFilter);
    } 
    
    // if query param and initialFilter not set, set with empty array
    setFilters(filters);
    addQueryParamsURL(filters);

    // send filters to parent component
    filterInstances && filterInstances(filters);
  }, []);
  

  // handle search, we need to state to keep search value when close the popover
  // and then pass the value to parent component

  // This trigger brandSelector dropdown in all table management
  useEffect(() => {
    quickFilterBrand?.onChange(curAccount?.name);
  }, [curAccount]);

  const [isModalOpen, setIsModalOpen] = useState({ value: false, index: 0 });
  const [search, setSearch] = useState('');
  const handleSearchChange = (value: string) => {
    setSearch(value);
    onSearchChange(value);
  }
  const [refreshedData, setRefreshedData] = useState<any>([]);
  const [isDataRefreshing, setIsDataRefreshing] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [newRefreshData, setNewRefreshData] = useState<any>([]);

  /**
   * this state is used to store filters and query params
   */
  const [filters, setFilters] = useState<FilterProps[]>([]); // filters state
  const [openFilter, setOpenFilter] = useState(false); // filter popover state
  const [searchParam, setSearchParam] = useSearchParams() // search params for URL

  useEffect(() => {
    const newData = refreshedData.filter((data: any) => !newRefreshData.some((newData: any) => newData.id === data.id));
    setNewRefreshData(newData);
    if (isFirstLoad) {
      setLoading(true);
      setIsDataRefreshing(false); 
      onRefreshingData(false);
      const timeout = setTimeout(() => {
        setLoading(false); 
        setIsDataRefreshing(true);
        setIsFirstLoad(false); 
      }, 1000); 

      return () => clearTimeout(timeout); 
    } else if (refreshedData && refreshedData.length > 0) {
      setLoading(true); 
      setIsDataRefreshing(false);
      onRefreshingData(true, newData);
      // const originalRow = table.getRowModel().rows;
      // const newDataIds = refreshedData.map((data: any) => data.id);
      // const updatedData = originalRow.map((row) => {
      //   // check if the newDataIds row is in the original table row data
      //   if (newDataIds.includes(row.original.id)) {
      //     const refreshedRow = refreshedData.find(
      //       (data: any) => data.id === row.original.id,
      //     );

      //     // cleanup value in the refreshedData and set the value whether its raw or formatted
      //     const cleanedRefreshedRow = Object.keys(refreshedRow).reduce(
      //       (value, key) => {
      //         if (refreshedRow[key] && typeof refreshedRow[key] === 'object') {
      //           value[key] =
      //             refreshedRow[key].formatted !== undefined
      //               ? refreshedRow[key].formatted
      //               : refreshedRow[key].raw;
      //         } else {
      //           value[key] = refreshedRow[key];
      //         }
      //         return value;
      //       },
      //       {},
      //     );
      //     // return and update the original table with current cleanup refreshed
      //     return {
      //       ...row,
      //       original: {
      //         ...row.original,
      //         ...cleanedRefreshedRow,
      //       },
      //     };
      //   }
      //   return row;
      // });
      const timeout = setTimeout(() => {
        setLoading(false);
        setIsDataRefreshing(true);
        onRefreshingData(false);
      }, 1000);

      return () => clearTimeout(timeout);
    } else {
      // No refreshedData, stop loading
      setLoading(false);
      setIsDataRefreshing(false);
      onRefreshingData(false);
    }

  }, [refreshedData, isFirstLoad]);

  useEffect(() => {
    if (isDataRefreshing) {
      setIsModalOpen({ value: false, index: 0 }); // Close modal after refresh
    }
  }, [isDataRefreshing]);

  // This effect ensure filtering works based on the current dataSet,
  // trigger customDate or filter timeRange, and set toggleAds == running to off.
  const prevOnTimeRangeChangeRef = useRef(onTimeRangeChange);

  // get column data for field options in filters
  // exclude selection and brandAccount column
  // this will be use in FilterTable component and trigger when columns changes
  const getPropertyFieldOption = useMemo(() => {
    return columns.map((column) => {
      return {
        label: column.header as string,
        value: column.id,
      }
    }).filter((item) => item.value !== 'selection' && item.value !== 'brandAccount');
  }, [columns])


  // apply filters, this function will be called when user click apply button in filter popover
  const handleApplyFilters = (filters: FilterProps[]) => {
    // set filters state and add query params to URL
    setFilters(filters);
    addQueryParamsURL(filters);

    // send filters to parent component
    filterInstances(filters);

    // close filter popover
    setOpenFilter(false);
  }

  // add query params to URL, this function also call every filters state changes
  const addQueryParamsURL = useCallback((filters: FilterProps[]) => {
    const params = new URLSearchParams();
    filters.forEach((filter) => {
      // e.g: status_equal=On&impression_greater=1000
      params.append(`${filter.field}_${filter.operator}`, filter.value);
    });
    setSearchParam(params);
  }, [filters]);

  // remove badge filter, this function will be called when user click remove badge in filter badge
  const handleRemoveBadge = (filter: FilterProps) => {
    // remove filter from filters state
    const newFilters = filters.filter((item) => item.field !== filter.field);
    setFilters(newFilters);

    // remove filter from query params
    const params = new URLSearchParams(searchParam);
    params.delete(`${filter.field}_${filter.operator}`);
    setSearchParam(params);

    // send filters to parent component
    filterInstances(newFilters);
  }

  // handle quick filter impressions, this call when the switch checkbox changes
  // TODO: now status replace with impressions, so later we need to refactor quickfilter can be dynamic and reusable
  const handleQuickFilterCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    // get the impressions filter from the filters state
    // we need check this to make sure impressions not duplicated
    // and relect to filters
    const getImpressionsFilter = filters.find((filter) => filter.field === 'impressions');
    let updatedFilters = filters;
    if (e.target.checked) {
      if (getImpressionsFilter) {
        // update the existing status filter
        updatedFilters = filters.map((filter) =>
          filter.field === 'impressions'
            ? { ...filter, value: quickFilterImpressions?.defaultValue }
            : filter
        );
      } else {
        // add a new impressions filter
        updatedFilters = [...filters, { field: 'impressions', value: quickFilterImpressions?.defaultValue, operator: 'greater' }];
      }
    } else {
      // remove impressions filter from filters
      updatedFilters = filters.filter((filter) => filter.field !== 'impressions');
    }
    // send updated filters to parent component
    handleApplyFilters(updatedFilters);

    // set quick filter status value
    quickFilterImpressions.onChange(e.target.checked);
  }

  // check filters has impressions filter and value is equal to quickFilterImpressions defaultValue or 0
  const isImpressionsCheckboxActive = filters.some((filter) => filter.field === 'impressions' && filter.value === quickFilterImpressions?.defaultValue);

  const firstColumnRef = useRef<HTMLTableCellElement | null>(null);
  const [baseLeft, setBaseLeft] = useState(45); // Default value

  useEffect(() => {
    if (firstColumnRef.current) {
      setBaseLeft(firstColumnRef.current.offsetWidth);
    }
  }, [columns]);

  const getStickyLeft = (columnId: string) => {
    const stickyColumnsFilter = columns.filter(col => col.sticky);
    const stickyIndex = stickyColumnsFilter.findIndex(col => col.id === columnId);
    return stickyIndex >= 0 ? baseLeft + stickyIndex * 110 : 'auto'; // 150px column width assumed
  };


  // hard code button go to adSet only on AOR page
  const isAOR = location.pathname.includes('ad-optimization-report');
  const goToAdSet = () => {
    const link = generatePath(ROUTES.view_detail.path, {
      detail: 'adset-management'
    });

    // open new tab
    window.open(link, '_blank');
  }

  return (
    <>
      <div className={style.tableWrapper}>
        <div className={style.action}>
          <div className={style.actionWrapper}>
              <>
                {/* disable filters feature if no filterInstances */}
                {filterInstances && (
                  <>
                    <Popover.Root open={openFilter} onOpenChange={() => setOpenFilter(!openFilter)}>
                      <Popover.Trigger asChild>
                        <button className={style.filterBtn} aria-label='filter'>
                          <Icons.Filter width={16} height={16} />
                          <span>Filter</span>
                        </button>
                      </Popover.Trigger>
                      <Popover.Content align='start' hideClose>
                        <div>
                          <FilterTable 
                            filters={filters}
                            fieldOptions={getPropertyFieldOption}  
                            onApplyFilters={handleApplyFilters}
                          />
                        </div>
                      </Popover.Content>
                    </Popover.Root>


                    {/* badge active filters */}
                    <div className={style.filterBadgeRow}>
                      {filters && filters.map((filter, index) => (
                        <FilterBadge key={index} filter={filter} onRemove={handleRemoveBadge} />
                      ))}
                    </div>
                  </>
                )}

              {/*{isModalOpen.value && !loading &&(*/}
              {/*  <ModalPopup*/}
              {/*    index={isModalOpen?.index}*/}
              {/*    title="Quick Actions"*/}
              {/*    closeModal={() => setIsModalOpen({ value: false, index: 0 })}*/}
              {/*    dataSource={selectedRowData}*/}
              {/*    isModalOpen={isModalOpen?.value}*/}
              {/*    counter={selectedRows.length}*/}
              {/*    onClose={(data: any) => setRefreshedData(data)}*/}
              {/*    onAction={triggerActionRefresh}*/}
              {/*  />*/}
              {/*)}*/}
              </>
            <div className={style.batchActionGroup}>
              {selectedRows.length > 0 && batchActionDropdown && (
                <>
                  <p>Batch Actions</p>
                  {React.cloneElement(
                    batchActionDropdown as React.ReactElement<any>,
                    { selectedRows },
                  )}
                </>
              )}
            </div>
          </div>

          <div className={style.additionalFilter}>
            <div className={style.actionWrapper}>
              {dateFilter && (
                <div className={style.batchActionGroup}>{dateFilter}</div>
              )}
              {timeRange && (
                <FilterTimeRange name="timerange" variant="outline" timeRange={timeRange} timeRangeOptions={timeRangeOptions} onHandleChange={onTimeRangeChange} />
              )}
              {quickFilter && quickFilter.length > 0 && (
                <div>
                  <Popover.Root>
                    <Popover.Trigger asChild>
                      <button 
                        className={cl(style.quickFilterBtn, quickFilterStatus?.value || quickFilterBrand?.value ? style.isActive : '')} 
                        aria-label='quick filter'
                      >
                        <Icons.SliderHorizontal width={16} height={16} />
                      </button>
                    </Popover.Trigger>
                    <Popover.Content align='end' hideClose>
                      <div className={style.quickFilterWrapper}>
                        {quickFilterBrand && (
                          <>
                          <h4>Brand Account:</h4>
                          <BrandAccountSelector 
                            className={style.brandAccountSelector}
                            showLabel={false} 
                            qAccounts={accountsResource} 
                          />
                          </>
                        )}
                        {quickFilterImpressions && (
                            <div className={style.activeCheckbox}>
                            <label htmlFor="StatusFilter">
                              {quickFilterImpressions.label}
                            </label>
                            <label className={style.switch}>
                              <input
                              type="checkbox"
                              id="StatusFilter"
                              onChange={(e) => handleQuickFilterCheckbox(e)}
                              checked={isImpressionsCheckboxActive}
                              />
                              <span className={style.slider}></span>
                            </label>
                            </div>
                        )}
                      </div>
                      <Popover.Arrow />
                    </Popover.Content>
                  </Popover.Root>
                </div>
                
              )}
                <div className={style.searchBox}>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => handleSearchChange(e.target.value)}
                      />
                    </div>
              <div>
               
                    <button
                      onClick={() => {
                        const searchBox = document.querySelector(`.${style.searchBox}`);
                        if (searchBox) {
                          const isDisplayed = (searchBox as HTMLElement).style.display === 'block';
                          (searchBox as HTMLElement).style.display = isDisplayed ? 'none' : 'block';
                        }
                      }}
                      className={cl(style.searchBtn, search ? style.isActive : '')}
                      aria-label='search'
                    >
                      <Icons.Search width={16} height={16} />
                    </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cl(
            style.tableContainer,
            pagination.pageSize >= 20 && style.expanded,
          )}
        >
          <div className={style.overlaySpinner}>
            <Spinner active={loading} />
            {loading && <p>Loading</p>}
          </div>
          <table className={cl(style.table)}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className={isLight ? style.tr : style.trDark}
                >
                  <th
                    className={cl(
                      isLight ? style.th : style.thDark,
                      style.stickyLeft,
                      style.sticky
                    )}
                    ref={firstColumnRef} // Capture width dynamically
                  >
                    <input type="checkbox" onChange={toggleSelectAll} />
                  </th>
                  {headerGroup.headers.map((header, index) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={cl(
                          isLight ? style.th : style.thDark,
                          (header.column.columnDef as CustomColumnDef).sticky ? style.stickyHeader : '',
                          style.sticky
                        )}
                        style={{
                          left: (header.column.columnDef as CustomColumnDef).sticky ? `${getStickyLeft(header.column.columnDef.id)}px` : 'auto'
                        }}
                      >
                        {index === 0 ? (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </div>
                        ) : (
                          <div
                            {...{
                            className: header.column.getCanSort()
                            ? style.canSort
                            : '',
                            onClick: () => {
                              // header.column.getToggleSortingHandler()
                              let columnIsSorting = sorting.some(s => s.id === header.column.id);
                              let columnIsSortingDesc = sorting.some(s => s.id === header.column.id && s.desc);
                              //first time, column is not sorting, start descending
                              if(!columnIsSorting)
                              {
                                header.column.toggleSorting(true);
                              } else {
                                //if column is sorting, but not descending, reset to not sorting
                                if(!columnIsSortingDesc)
                                {
                                  header.column.toggleSorting(null);
                                } else {
                                  //if column is sorting and descending, pass desc=false to sort ascending
                                  header.column.toggleSorting(false);
                                }
                              }
                            },
                            }}
                          >
                            <Tooltip.Provider>
                              <Tooltip.Root>
                                <Tooltip.Trigger asChild>
                                  <span style={{fontWeight: "bold"}}>
                                  {flexRender(
                                    typeof header.column.columnDef.header === 'string'
                                    ? header.column.columnDef.header
                                      .replace('$', '')
                                      .replace(/(cpc|cvr|epc|ctr)/gi, (match) => match.toUpperCase())
                                    : header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                  </span>
                                </Tooltip.Trigger>
                                <Tooltip.Content side="top">
                                    {(header.column.columnDef as CustomColumnDef).tooltip}
                                  <Tooltip.Arrow />
                                </Tooltip.Content>
                              </Tooltip.Root>
                            </Tooltip.Provider>
                            {{
                              asc: <Icons.UpArrow />,
                              desc: <Icons.DownArrow />,
                              }[header.column.getIsSorted() as string] ?? (
                              <Icons.LeftRightArrow />
                            )}
                          </div>
                        )}
                      </th>
                    );
                    
                  })}
                  {/* Conditionally render the right-side fixed header column */}
                  {onDetailsClick && (
                    <th
                      className={cl(
                        isLight ? style.th : style.thDark,
                        style.stickyRight,
                        style.sticky
                      )}
                    >
                      Actions
                    </th>
                  )}
                </tr>
              ))}
            </thead>
            <tbody>
              
            {data.length === 0 ? (
                <tr>
                  <td colSpan={columns.length}>
                    {isLoading ? (
                       <div className={style.skeletonCell} style={{margin: '0.5rem'}}></div>
                    ):(
                      <div className={style.noResult}>No Matches</div>
                    )}
                  </td>
                </tr>
              ) : table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  data-id={row.original.id}
                  className={isLight ? style.tr : style.trDark}
                >
                  
                  <td
                    className={cl(
                      isLight ? style.td : style.tdDark,
                      style.stickyLeft,
                      style.sticky
                    )}
                  >
                    {isLoading ? (
                      <div className={style.skeletonCell}></div>
                    ) : (
                      <>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          //when unchecking, keep open if currently more then 1 row selected ( so there will be at least 1 selected row left)
                          setIsModalOpen({ value: e.currentTarget.checked || selectedRowData.length > 1, index: row.index });
                          toggleRowSelection(row.original.id);
                        }}
                        checked={isRowSelected(row.original.id) && !(refreshedData && refreshedData.find((data: any) => data?.id === row?.original?.id))}
                        className={style.defaultCheckbox}
                      />
                        <span>
                        {isDataRefreshing && refreshedData.some((data: any) => data?.id === row?.original?.id) ? (
                          <IoIosCheckmarkCircleOutline className={style.refreshData} />
                        ) : (
                          !isDataRefreshing && refreshedData && refreshedData.some((data: any) => data?.id === row?.original?.id) && (
                          <MdCancel className={style.noRefreshData} />
                          )
                        )}
                        </span>
                      </>
                    )}
                   
                  </td>
                  {row.getVisibleCells().map((cell) => {
                    const insertRefreshData = refreshedData ? refreshedData.find((data: any) => data?.id === row?.original?.id) : null;
                    const rowData = insertRefreshData ? insertRefreshData : row.original;
                    const dynamicClassNameFn = cell.column.columnDef.meta ? cell.column.columnDef.meta['className'] : null;
                    let dynamicClassName = '';
                    if (dynamicClassNameFn) {
                      dynamicClassName = dynamicClassNameFn(cell.renderValue(), rowData);
                    }
                    // //see if there is any function provided for this column to dynamically determine an extra className
                    // const dynamicClassNameFn = cell.column.columnDef.meta ? cell.column.columnDef.meta['className'] : null;
                    // let dynamicClassName = '';
                    // if (dynamicClassNameFn)
                    // {
                    //   //if so, send the cell value to it
                    //   dynamicClassName = dynamicClassNameFn(cell.renderValue(),cell.row.original);
                    // }

                    return (
                      <td
                        key={cell.id}
                        className={cl(
                          isLight ? style.td : style.tdDark,
                          (cell.column.columnDef as CustomColumnDef).sticky ? style.stickyData : '',
                          style.sticky,
                          (cell.column.columnDef as CustomColumnDef).hoverflow ? style.hoverflow : '',
                          dynamicClassName
                        )}
                        style={{
                          left: (cell.column.columnDef as CustomColumnDef).sticky ? `${getStickyLeft(cell.column.columnDef.id)}px` : 'auto'
                        }}
                        onMouseEnter={(e) => {
                          if ((cell.column.columnDef as CustomColumnDef).hoverflow) {
                            // Get the inner div that wraps the cell content
                            const innerDiv = e.currentTarget.querySelector('div');
                            if (innerDiv) {
                              // Expand the width; you can set this to a fixed value or a value based on the content
                              innerDiv.style.width = '300px'; // or use a value from cell.column.columnDef.hoverflow if it's a number/string
                            }
                          }
                        }}
                        onMouseLeave={(e) => {
                          if ((cell.column.columnDef as CustomColumnDef).hoverflow) {
                            const innerDiv = e.currentTarget.querySelector('div');
                            if (innerDiv) {
                              // Reset the width to let the cell return to its narrow state
                              innerDiv.style.width = '';
                            }
                          }
                        }}
                      >
                          {isLoading || 
                            (!isDataRefreshing && newRefreshData && newRefreshData.length > 0 && 
                              newRefreshData.some((data) => data?.id === row?.original?.id)) ? (
                              <div className={style.skeletonCell}></div>
                            ) : (
                              <div>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </div>
                            )
                          }
                      </td>
                    )
                  })}
                  {/* Conditionally render the right-side fixed cell */}
                  {onDetailsClick && (
                    <td
                      className={cl(
                        isLight ? style.td : style.tdDark,
                        style.stickyRight,
                        style.sticky
                      )}
                    >
                      {isLoading ? (
                        <div className={style.skeletonCell}></div>
                      ) : (
                        <button
                          onClick={() => onDetailsClick(row.original)}
                          className={style.actionButton}
                        >
                          <Icons.Eye />
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={cl(style.tableBottom, !isAOR ? style.tableBottomEnd : '')}>
          {isAOR && (
            <div>
              <button onClick={goToAdSet}>Adset Bulk Actions & Automations</button>
            </div>
          )}
          <div className={style.pagination}>
            <span className={style.pageInfo}>
              Page {pagination.pageIndex + 1} of{' '}
              <strong>{Math.ceil(totalItems / pagination.pageSize)}</strong>
            </span>
            <span className={style.pageInfo}>
              <input
                type="number"
                className={style.goToPageForm}
                value={pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  onPaginationChange({ ...pagination, pageIndex: page });
                }}
              />
            </span>
            <select
              className={style.selectPageRows}
              value={pagination.pageSize}
              onChange={(e) => {
                onPaginationChange({
                  ...pagination,
                  pageSize: Number(e.target.value),
                });
              }}
            >
              {[10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
            <button
              className={style.paginationButton}
              onClick={() => onPaginationChange({ ...pagination, pageIndex: 0 })}
              disabled={pagination.pageIndex === 0}
            >
              <Icons.DoubleLeftArrow />
            </button>
            <button
              className={style.paginationButton}
              onClick={() =>
                onPaginationChange({
                  ...pagination,
                  pageIndex: pagination.pageIndex - 1,
                })
              }
              disabled={pagination.pageIndex === 0}
            >
              <Icons.SingleLeftArrow />
            </button>
            <button
              className={style.paginationButton}
              onClick={() =>
                onPaginationChange({
                  ...pagination,
                  pageIndex: pagination.pageIndex + 1,
                })
              }
              disabled={
                pagination.pageIndex >=
                Math.ceil(totalItems / pagination.pageSize) - 1
              }
            >
              <Icons.SingleRightArrow />
            </button>
            <button
              className={style.paginationButton}
              onClick={() =>
                onPaginationChange({
                  ...pagination,
                  pageIndex: Math.ceil(totalItems / pagination.pageSize) - 1,
                })
              }
              disabled={
                pagination.pageIndex >=
                Math.ceil(totalItems / pagination.pageSize) - 1
              }
            >
              <Icons.DoubleRightArrow />
            </button>
          </div>
        </div>
      </div>
    </>
  );

}

export default ShapeTable;
