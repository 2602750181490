import React, { useEffect, useState } from 'react';
import { Table } from '../Table';
import { cl } from 'lincd/lib/utils/ClassNames';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../../package';
import { automation1, automation4 } from '../../data/automations';
import style from '../../pages/Automations.scss.json';
import Modal from '../Modal';
import { useNavigate } from 'react-router-dom';

const ResetBidAmounts: React.FC = () => {
  const [currentAdsets, setCurrentAdsets] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const getAdsetsOfResetBidAmountAutomation = async () => {
    try {
      const response = await Server.call(packageName, 'getAdsetsOfResetBidAmountAutomation');
      setCurrentAdsets(response);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAdsetsOfResetBidAmountAutomation();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const addAdsetHandler = () => {
    setShowModal(true);
  }

  return (
    <div className={cl(style.automation1)}>
      <div className={style.headerContent}>
        <button className={style.btn} onClick={addAdsetHandler}>Add Adset to This Automation</button>
        <h3>The following 2 automations are involved in resetting bid amounts:</h3>
        <p>
          {automation1?.alternateName ? <b>{automation1.alternateName}<br /></b> : ''}{automation1.description}
        </p>
        <p>
          {automation4?.alternateName ? <b>{automation4.alternateName}<br /></b> : ''}{automation4.description}
        </p>
        <h3>These automations run on the following selected ad sets:</h3>
      </div>
      <div className={style.tableScroll}>
        <Table.Root isStickyHeader>
          <Table.Header>
            <Table.Row>
              <Table.Head>Short Name</Table.Head>
              <Table.Head>Bid Amount</Table.Head>
              <Table.Head>Daily Clicks</Table.Head>
              <Table.Head>Daily Sales</Table.Head>
              <Table.Head>Status</Table.Head>
              <Table.Head>Last 3 Actions</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {currentAdsets && currentAdsets.length ? (
              currentAdsets.map((adset: any) => (
                <Table.Row key={adset.uri}>
                  <Table.Cell className={style.cellAlignLeft}>{adset.shortName}</Table.Cell>
                  <Table.Cell>{adset.bidAmount}</Table.Cell>
                  <Table.Cell>{adset.dailyClicks}</Table.Cell>
                  <Table.Cell>{adset.dailySales}</Table.Cell>
                  <Table.Cell>{adset.status}</Table.Cell>
                  <Table.Cell>
                    {adset.actions && adset.actions.length ? (
                      <ul>
                        {adset.actions.map((action: any) => (
                          <li key={action.uri}>{action.description}</li>
                        ))}
                      </ul>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} style={{ textAlign: 'center' }}>
                  No selected ad sets
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table.Root>
      </div>
      <Modal show={showModal} onClose={handleCloseModal} title={'Reset Bid Amounts'}>
        <p>You will now be taken to the Adset Management Page. Here you can select adsets and then open "Batch Actions → Choose Automations".</p>
        <button className={style.btn} onClick={() => navigate(`/view/adset-management`)}>OK</button>
      </Modal>
    </div>
  );
};

export default ResetBidAmounts;
