import React from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import './Accounts.scss';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {packageName} from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';

export default function DataOperations() {

  const startDate = React.createRef<HTMLInputElement>();
  const endDate = React.createRef<HTMLInputElement>();
  const email = React.createRef<HTMLInputElement>();

  const [pendingItems,setPendingItems] = React.useState<{ads,adSets,campaigns}>();

  let auth = useAuth();
  if (!auth.userAccount.email.includes('@semantu.com'))
  {
    return <DefaultLayout><h1>Unauthorized</h1></DefaultLayout>;
  }
  const submit = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log('Recovering data');
    Server.call(packageName,'recoverData',{
      startDate: startDate.current?.value,
      endDate: endDate.current?.value,
      email: email.current?.value,
    });
    alert('Data recovery started, you will receive progress emails');
  };
  const updateAllItems = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log('Updating all items');
    Server.call(packageName,'updateStatusOfAllItems',{
      email: email.current?.value,
    });
    alert('Updating all items started, you will receive progress emails');
  };
  const getPendingItems = (e: React.MouseEvent) => {
    e.preventDefault();
    Server.call(packageName,'getFailedAndPendingItems').then(res => {
      setPendingItems(res);
    });
  };
  const updatePendingItems = (e: React.MouseEvent) => {
    e.preventDefault();
    Server.call(packageName,'continueFailedAndPendingItems',email.current?.value);
    alert('Failed items will be retried. You will receive progress emails');
  };

  let defaultStart = new Date().toDateString().split('T')[0].split('-').reverse().join('-');
  let defaultEndDate = new Date();
  defaultEndDate.setDate(0);
  let defaultEnd = defaultEndDate.toDateString().split('T')[0].split('-').reverse().join('-');

  return (
    <DefaultLayout>
      <section>
        <h1>Email</h1>
        <p>Fill in your email here to get notified of the results of any of the operations
          below:<input ref={email} type={'email'} name={'email'} placeholder={'email'} /></p>
      </section>
      <section>
        <h1>Recover Quora Data</h1>
        <p>
          Re-run the cronjob that retrieves data from the Quora API for certain dates.
          Due to API limits, one day will be recovered every 30 minutes, until the process is complete.
        </p>
        <form>
          <p>From <input ref={startDate} defaultValue={defaultStart} type={'date'} name={'startDate'} />&nbsp;
            until <input ref={endDate} type={'date'} name={'endDate'} defaultValue={defaultEnd} /></p>
          <button type="submit" onClick={submit}>Recover Data</button>
        </form>
      </section>
      <section>
        <h1>Update status of all items in Quora</h1>
        <p>
          Update the status of all items in Quora to match the status in the database.<br />
          <span style={{color: 'red'}}>This will use a lot of requests</span> and will run for at least 24 hours in production.<br />
          <button type="submit" onClick={updateAllItems}>Update all items</button>
        </p>
      </section>
      <section>
        <h1>Retry failed items</h1>
        <p>
          Sometimes things go wrong when we create items in Quora. Then the status becomes "FAILED".<br />
          Here you can find all ads/adSets/campaigns with status = "FAILED" and optionally retry to create them<br />
          <button onClick={getPendingItems}>Get list of failed items</button>
          <button onClick={updatePendingItems}>Retry failed items</button>
          {pendingItems && Object.keys(pendingItems).map(key => {
            return <>
              <h3>{key} ({pendingItems[key].length})</h3>
              <ul>
                {pendingItems[key].map(item => <li>{item.toString()}</li>)}
              </ul>
            </>;
          })}
        </p>
      </section>
    </DefaultLayout>
  );
}
