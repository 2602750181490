import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import TableView from '../components/TableView';
import './DailySalesReport.scss';
import styles from './DailySalesReport.scss.json';
import { DateRange, DayPicker } from 'react-day-picker';
import { BrandAccountSelector } from '../components/RefreshQuoraData';
import { useSuspense } from '../utils';
import { BrandAccount } from 'titan-ads/lib/shapes/TitanShapes';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { DashboardContext } from '../contexts/Dashboard';
import { QuoraAccount } from 'titan-ads/lib/shapes/QuoraAccount';
import { useScreenshot } from 'use-react-screenshot';
import {formatCurrency,formatSpend,formatNumberFrontend,getNormalizedSpend} from '../utils/data';
import Papa from 'papaparse';

function DailySalesReport() {
  const initialBrands = useSuspense(BrandAccount.loadAll());
  const [brands] = useState<{ read(): any }>(initialBrands);
  const [selected, setSelected] = useState<DateRange | undefined>();
  const [dateValue, setDateValue] = useState<DateRange | undefined>();
  const [showDayPicker, setShowDayPicker] = useState<boolean>(false);
  const [source, setSource] = useState<any[]>([]);
  const { curAccount } = useContext(DashboardContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  // const [campaignBid, setCampaignBid] = useState<any>('');
  // const [quoraAccounts, setQuoraAccounts] = useState<QuoraAccount[]>([]);
  const [selectedQuoraAccount, setSelectedQuoraAccount] = useState<QuoraAccount | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [image, takeScreenshot] = useScreenshot();
  // const [checked, setChecked] = useState<string>('');
  // const clientQuoraAccount = Array.from(
  //     new Set(quoraAccounts.filter(
  //         (account) => !account.name.includes('Semantu') && !account.name.includes('LINCD'))))
  //         .filter((acc, index, item) => index === item.findIndex((t) => t.quoraId === acc.quoraId));

  // Get daily Date Selector and send to backend
  const targetDate = { from: selected?.from.toISOString().split('T')[0], to: selected?.to.toISOString().split('T')[0] }
  // Get daily ads data
  const getDailyAdsData = async () => {
    try {
      setLoading(true);
      // send params to backend date and what constraint for getting the report (brand or quora account)
      const adData = await Server.call(packageName, 'getDailySalesReportData', targetDate, curAccount);
      if (adData) setSource(adData.data);
      setLoading(false);
    } catch (e) {
      console.log('Error getting daily ad data for report');
      return [];
    }
    setSelected(null);
  };



  // Format date range
  const formatDateRange = (range: DateRange) => {
    if (!range.from || !range.to) return '';
    const formatSingleDate = (d: Date) => {
      return (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getDate().toString().padStart(2, '0') + '/' + d.getFullYear().toString().slice(-2);
    }
    return `${formatSingleDate(range.from)} - ${formatSingleDate(range.to)}`;
  }

  // If date range is selected, get daily ads data (wait for to !== undefined)
  useEffect(() => {
    if (selected?.from !== selected?.to) {
      setShowDayPicker(false);
      getDailyAdsData();
    }
  }, [selected, curAccount]);

  const handleExportToCSV = () => {
    const csvData = [
      ['Daily Sales Report'],
      ['Brand Account', curAccount?.name],
      ['Day Report', formatDateRange(dateValue!)],
      ['Date', 'Impressions', 'Clicks', 'Spend', 'Sales', 'Payout'],
      ...source.map(row => [
        row.date,
        row.metrics.totalImpressions,
        row.metrics.totalClicks,
        getNormalizedSpend(row.metrics.totalSpend),
        row.metrics.totalSales,
        row.metrics.totalPayout
      ])
    ];

    const csv = Papa.unparse(csvData);
    const url = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
    const a = document.createElement('a');
    a.href = url;
    a.download = `Daily_Sales_Report_${formatDateRange(dateValue!)}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className={styles.dailySalesContainer}>
      <h3>Generate Report - Daily Sales Report</h3>
      <div className={styles.accountSelector}>
        <h4>Brand Account:</h4>
        <Suspense fallback={<BrandAccountSelector.Loading />}>
          <BrandAccountSelector qAccounts={brands} showLabel={false} />
        </Suspense>
        <div className={styles.inputDatePicker}>
          <h4>Date Selector:</h4>
          {!showDayPicker ? (
            <input
              type="text"
              readOnly
              value={dateValue?.to && dateValue?.from ? `${formatDateRange(dateValue)}` : 'Select Day'}
              onClick={() => setShowDayPicker(!showDayPicker)}
            />
          ) : (
            <div className={styles.dayPicker}>
              <DayPicker
                mode="range"
                required
                captionLayout="dropdown"
                hideNavigation
                timeZone="America/Los_Angeles"
                selected={selected}
                onSelect={(range: DateRange) => {
                  setSelected(range)
                  setDateValue(range)
                }}
              />
            </div>
          )}
        </div>
        <h4>Export To:</h4>
        <div className={styles.buttonSelector}>
          <button className={styles.screenShotbtn} disabled={source.length === 0} onClick={() => {
            takeScreenshot(ref.current).then((image) => {
              const link = document.createElement('a');
              link.href = image;
              link.download = `Daily_Sales_Report_${formatDateRange(dateValue!)}.png`;
              link.click();
            });
          }}>Screenshot
          </button>
          <button className={styles.csvButton} disabled={source.length === 0} onClick={handleExportToCSV}>
            CSV File
          </button>
        </div>
      </div>
      <TableView
        ref={ref}
        data={source}
        reportType='sales'
        isLoading={isLoading}
        account={curAccount?.name}
        selectedDate={dateValue}
      />
    </div>
  );
};

export default DailySalesReport;
