import React, {useState, useEffect} from 'react';
import ShapeTable from './ShapeTable';
import {Ad, AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {PropertyShape, NodeShape} from 'lincd/lib/shapes/SHACL';
import {xsd} from 'lincd-xsd/lib/ontologies/xsd';
import {shacl} from 'lincd/lib/ontologies/shacl';
import {Shape} from 'lincd/lib/shapes/Shape';
import {Prefix} from 'lincd/lib/utils/Prefix';
import { QuickFilters, TimeRangeProps, TimeRangeValue, FilterProps } from '../types';
import { CustomColumn, CustomColumnDef } from '../utils';
import { TableColumn } from '../utils/views/TableDataProvider';

interface DynamicObject {
  [key: string]: any;
}

interface InstanceOverviewProps {
  customColumns?: CustomColumn[];
  defaultColumn?: Partial<CustomColumnDef<any>>;
  instances: DynamicObject[];
  shape?: NodeShape;
  onRefreshingData?: (isRefreshing: boolean, instances: DynamicObject[]) => void;
  isLoading?:boolean;
  initialFilter?: FilterProps[];
  filterInstances?: (filters: any) => void;
  resetFilter?: (value: boolean) => void;
  pagination: {pageIndex: number; pageSize: number};
  columnInfo?:TableColumn<any>[];
  totalItems: number;
  onPaginationChange: (newPagination: {
    pageIndex: number;
    pageSize: number;
  }) => void;
  sorting: Array<any>;
  onSortingChange: (any) => void;
  onSearchChange: (any) => void;
  quickFilter?: QuickFilters[];
  isLight?: boolean;
  batchActionDropdown?: (selectedRows:DynamicObject[]) => React.ReactNode;
  dateFilter?: () => React.ReactNode;
  triggerActionRefresh: (any) => void;
  rightSideMenu?: boolean; // Add rightSideMenu prop
  timeRange?: TimeRangeProps;
  timeRangeOptions?: TimeRangeValue[];
  onTimeRangeChange?: (timeRange: TimeRangeProps) => void;
  onDetailsClick?: (any) => void;
}

function InstanceOverview({
  customColumns,
  defaultColumn,
  instances,
  shape,
  onRefreshingData,
  pagination,
  initialFilter,
  filterInstances,
  resetFilter,
  totalItems,
  onPaginationChange,
  sorting,
  onSortingChange,
  onSearchChange,
  isLight,
  batchActionDropdown,
  dateFilter,
  onDetailsClick,
  isLoading,
  triggerActionRefresh,
  timeRange,
  timeRangeOptions,
  quickFilter,
  columnInfo,
  onTimeRangeChange
}: InstanceOverviewProps) {
  const [selectAll, setSelectAll] = useState(false); // State to track whether all rows are selected
  const [selectedRows, setSelectedRows] = useState<string[]>([]); // State to track selected row indices
  const [isDeleteBtnShowing, setIsDeleteBtnShowing] =
    React.useState<boolean>(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allRowUris = instances.map((i) => i.id);
      setSelectedRows(allRowUris);
    } else {
      setSelectedRows([]);
    }
  };

  const toggleRowSelection = (uri: string) => {
    const selectedIndex = selectedRows.indexOf(uri);
    let newSelected: string[] = [];

    // If the row is already selected, remove it from the selectedRows array
    if (selectedIndex !== -1) {
      newSelected = selectedRows.filter((rowUri) => rowUri !== uri);
    } else {
      // If the row is not selected, add it to the selectedRows array
      newSelected = [...selectedRows, uri];
    }

    setSelectedRows(newSelected);
  };

  const isRowSelected = (id: string) => selectedRows.includes(id);

  let initialColumns: CustomColumnDef<Ad>[] = [
    {
      id: 'selection',
      cell: ({row}) => (
        <input
          type="hidden"
          onChange={() => toggleRowSelection(row.id)}
          checked={isRowSelected(row.id)}
        />
      ),
    },
  ];

  let columnConfig;

  const cell = (info) => {
    const value = info.getValue();
    return value;
  };

  if (customColumns) {
    // console.log("customColumns", customColumns)
    columnConfig = customColumns.map((config) => {
      return {
        meta: config.meta,
        id: config.id,
        header: config.label,
        accessorKey: config.label,
        tooltip: config.info,
        sticky: config.sticky,
        hoverflow: config.hoverflow,
        cell: cell,
        accessorFn: config.renderCell,
      };
    });
    initialColumns.push(...columnConfig);
  } else {
    //dynamically define the columns based on the properties of the shape
    let propertiesOfShape = shape.getPropertyShapes();
    propertiesOfShape.forEach((propShape) => {
      // default column configuration
      columnConfig = {
        id: propShape.label,
        accessorKey: propShape.label,
        cell: (info) => {
          let value = info.getValue();
          if (typeof value === 'undefined' || value === null) {
            return '';
          }
          let nodeKind = propShape.nodeKind;
          if (
            nodeKind === shacl.IRI ||
            nodeKind === shacl.BlankNode ||
            nodeKind === shacl.BlankNodeOrIRI
          ) {
            if (propShape.maxCount === 1) {
              //values are expected to be nodes (NamedNode or BlankNode), which will be returned as Shape or ShapeSet
              if (value instanceof Shape) {
                return Prefix.toPrefixed(value.uri);
              } else {
                console.warn(
                  'Was expecting a single Shape value, but instead got:',
                  value,
                  propShape,
                );
              }
            } else {
              if (value instanceof ShapeSet) {
                return [...value]
                  .map((shapeValue) => Prefix.toPrefixed(shapeValue.uri))
                  .join(', ');
              } else {
                console.warn(
                  'Was expecting a ShapeSet (multiple values), but instead got:',
                  value,
                  propShape,
                );
              }
            }
          } else if (nodeKind === shacl.Literal) {
            if (propShape.maxCount === 1) {
              if (!Array.isArray(value)) {
                return <CellValue value={value} propShape={propShape} />;
              } else {
                //if the value is an array
                console.warn(
                  'Was expecting a single value, but instead got an array of values',
                  propShape,
                  value,
                );
              }
            } else {
              //we are expecitng multiple values in an array
              if (Array.isArray(value)) {
                return (
                  <span>
                    {value.map((singleValue) => {
                      return (
                        <CellValue value={singleValue} propShape={propShape} />
                      );
                    })}
                  </span>
                );
              } else {
                //if the value is an array
                console.warn(
                  'Was expecting a multiple values as an array, but instead got something else',
                  propShape,
                  value,
                );
              }
            }
          } else {
            console.warn(
              'Unknown nodeKind or nodeKind not defined: ',
              propShape,
              nodeKind,
            );
          }
        },
        header: propShape.label
          .replace(/([A-Z])/g, ' $1')
          .replace(/_/g, ' ')
          .replace(/^./, (char) => char.toUpperCase()),
        footer: (props) => props.column.id,
      };

      initialColumns.push(columnConfig);
    });
  }
  const getRowsData = (selectedRows: string[]) => {
    return selectedRows.map((rowUri) => {
      return instances.find((instance) => instance.id === rowUri);
    });
  }

  const columns = React.useMemo<CustomColumnDef<Ad>[]>(() => initialColumns, [
    initialColumns, // we need to re-render the columns again when timeRange changes and has different mapping
  ]);

  return (
    <ShapeTable
      data={instances}
      columns={columns}
      isLoading={isLoading}
      defaultColumn={defaultColumn}
      toggleSelectAll={toggleSelectAll}
      isRowSelected={isRowSelected}
      selectedRows={selectedRows}
      selectedRowData={getRowsData(selectedRows)}
      resetFilter={resetFilter}
      initialFilter={initialFilter}
      filterInstances={filterInstances}
      toggleRowSelection={toggleRowSelection}
      isDeleteBtnShowing={isDeleteBtnShowing}
      triggerActionRefresh={triggerActionRefresh}
      shape={shape}
      columnInfo={columnInfo}
      onRefreshingData={(isRefreshing, instances) => {
        if (location.pathname !== '/approval' && location.pathname !== '/optimization') {
          onRefreshingData && onRefreshingData(isRefreshing, instances);
        }
      }}
      pagination={pagination} // Pass pagination state
      totalItems={totalItems} // Pass the total number of items
      onPaginationChange={onPaginationChange} // Pass handler to update pagination
      sorting={sorting}
      onSortingChange={onSortingChange}
      onSearchChange={onSearchChange}
      quickFilter={quickFilter}
      isLight={isLight} // Pass isLight to ShapeTable
      timeRange={timeRange} // Pass timeRange to ShapeTable
      timeRangeOptions={timeRangeOptions}
      onTimeRangeChange={onTimeRangeChange} // Pass onTimeRangeChange to ShapeTable
      batchActionDropdown={batchActionDropdown && batchActionDropdown(getRowsData(selectedRows))}
      dateFilter={dateFilter && dateFilter()}
      onDetailsClick={onDetailsClick}
    />
  );
}

export const CellValue = ({
  value,
  propShape,
}: {
  value: any;
  propShape: PropertyShape;
}) => {
  if(value === null) {
    return '';
  }
  //values are expected to be Literal
  let datatype = propShape.datatype;
  if (datatype === xsd.date) {
    if (value instanceof Date) {
      return value.toLocaleString();
    } else {
      console.warn(
        'This property should have Date values but returned something else:',
        propShape,
        value,
      );
    }
  } else if (datatype === xsd.boolean) {
    if (typeof value === 'boolean') {
      return value; // === "true" ?
    } else {
      console.warn(
        'This property should have Boolean values but returned something else:',
        propShape,
        value,
      );
    }
  } else if (datatype === xsd.integer || datatype === xsd.decimal) {
    if (typeof value === 'number') {
      if (isNaN(value) || value === null) {
        return '';
      }
      return value;
    } else {
      console.warn(
        'This property should have Number/Integer values but returned something else:',
        propShape,
        value,
      );
    }
  } else if (datatype && datatype !== xsd.string) {
    console.warn('Datatype not supported yet:', datatype, propShape, value);
  } else {
    //by default the datatype is either xsd.string or undefined, then we expect a string
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'undefined') {
      return '';
    } else if (value === null) {
      return '';
    } else if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value); // for debugging purposes or handle the object appropriately.
    } else {
      console.warn(
        'This property should have string values but returned something else:',
        propShape,
        value,
      );
    }
  }
};

export {InstanceOverview};
