import {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {Location} from 'titan-ads/lib/shapes/Location';
import {AdInputPartial} from 'titan-ads/lib/types';
import {getInputPartial, getPartialKey} from 'titan-ads/lib/utils';
import {InputsContext} from '../../contexts/AdInputs';
import {DashboardContext} from '../../contexts/Dashboard';
import styles from '../InputGroup.scss.json';
import {SearchInput} from './SearchInput';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import { titanAds } from 'titan-ads/lib/ontologies/titan-ads';

interface LocationsProps extends PropsWithChildren {
  disabled?: boolean;
  groupId: number;
  label: 'Excluded Locations' | 'Included Locations';
  partial: AdInputPartial;
}

export function Locations({
  children,
  disabled = false,
  groupId,
  label,
  partial,
}: LocationsProps) {
  const {handleEditInput, inputGroups} = useContext(InputsContext);
  const {curAccount} = useContext(DashboardContext);

  const {adSet, inputs} = inputGroups[groupId];

  const isIncludeLocation = label === 'Included Locations';
  const initialChosen = isIncludeLocation ? 
    [...adSet.geolocations] : 
    [...adSet.excludedGeolocations];

  const [chosen, setChosen] = useState<Location[]>(initialChosen);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Location[]>([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

  useEffect(() => {
    const newValue = chosen.map((location) => location.name).join(',');
    const oldValue = inputs[getPartialKey(partial)];
    if (newValue !== oldValue) handleEditInput(groupId, partial, newValue);
  }, [chosen]);

  useEffect(() => {
    if (isIncludeLocation) {
      setChosen([...adSet.geolocations]);
    } else {
      setChosen([...adSet.excludedGeolocations]);
    }
  }, [adSet.geolocations.map((l) => l.name).join(','), adSet.excludedGeolocations.map((l) => l.name).join(',')]);

  const doSearch = (query: string) => {
    setIsLoading(true);

    const ignoreIds = chosen.map((location) => location.id);

    // You can't include and exclude the same location if it's already
    // been chosen - therefore we want to exclude it from the quora query
    const otherLocationsLabel = isIncludeLocation ? 
      'Included Locations' : 'Excluded Locations';
    const otherLocationsIndex = getInputPartial('quora', otherLocationsLabel);
    const otherLocations =
      inputGroups[groupId].inputs[getPartialKey(otherLocationsIndex)];

    if (otherLocations) {
      ignoreIds.push(...otherLocations?.toString().split(','));
    }

    curAccount.quoraAccount
      .getLocations(query, ignoreIds)
      .then((res) => {
        setResults(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChooseResult = (result: Location) => {
    setResults((prev) => prev.filter((r) => r.name !== result.name));

    const updatedChosen = [...chosen, result].filter((location, index, self) => {
      return index === self.findIndex((l) => l.id === location.id);
    });
    setChosen(updatedChosen);

    if (isIncludeLocation) {
      // use `set` to add Location to adSet.geolocations since `.add` is not working
      // adSet.geolocations.add(result);
      adSet.set(titanAds.geolocations, result.namedNode);
    } else {
      // use `set` to add Location to adSet.excludedGeolocations since `.add` is not working
      // adSet.excludedGeolocations.add(result);
      adSet.set(titanAds.excludedGeolocations, result.namedNode);
    }
  };

  const removeLocation = (location: Location) => {
    const updateChosen = chosen.filter((l) => l.id !== location.id);
    setChosen(updateChosen);

    // after delete location, we need to make sure that the location is showing again in the search result
    // and showing in the first place
    setResults((prev) => [location, ...prev]);

    if (isIncludeLocation) {
      // use `unset` to remove Location from adSet.geolocations since `.delete` is not working
      // adSet.geolocations.delete(location);
      adSet.unset(titanAds.geolocations, location.namedNode);
    } else {
      // use `unset` to remove Location from adSet.excludedGeolocations since `.delete` is not working
      // adSet.excludedGeolocations.delete(location);
      adSet.unset(titanAds.excludedGeolocations, location.namedNode);
    }
  };

  const processResult = (result: Location) =>
    `(${result.geotype}) ${result.name}`;

  const searchLabel = `${chosen.length || ''} ${label.slice(0, -1)}${
    chosen.length === 1 ? '' : 's'
  }`;

  return (
    <div className={styles.adset}>
      <div>
        {chosen.map((location) => (
          <div
            key={location.id}
            style={{
              display: 'flex',
              marginTop: '20px',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '20px',
              height: '15px',
            }}
          >
            <p>{location.name}</p>
            <img
              onClick={() => removeLocation(location)}
              src={asset("/images/cross.png")}
              style={{width: '15px', height: '15px', marginLeft: '5px'}}
              alt="remove"
            />
          </div>
        ))}
      </div>
      <SearchInput
        disabled={disabled}
        doSearch={doSearch}
        isLoading={isLoading}
        label={searchLabel}
        onChooseResult={onChooseResult}
        processResult={processResult}
        results={results}
      />
      {children}
    </div>
  );
}
