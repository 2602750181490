import React,{useCallback,useContext,useEffect,useState} from 'react';
import {ExecutableAction} from 'titan-ads/lib/shapes/ExecutableAction';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {CustomColumn,DynamicObject,generateCustomColumns,loadExecutableAction,renderNoDataMessage} from '../utils';
import {Spinner} from '../components/Spinner';
import {InstanceOverview} from '../components/InstanceOverview';
import {debounce} from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import ApprovalActionDropdown from '../components/ApprovalActionDropdown';
import { useCustomColumns } from '../hooks/useCustomColumns';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';
import {Thing} from 'lincd-schema/lib/shapes/Thing';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';

function ApprovalPage()
{
  const {isLight} = useContext(ThemeContext);

  // State for Potential Action Status
  const [potentialInstances,setPotentialInstances] = useState<DynamicObject[]>();
  const [potentialTotalItems,setPotentialTotalItems] = useState(0);
  // const [potentialCustomColumns,setPotentialCustomColumns] = useState<CustomColumn[] | undefined>(undefined);
  const [potentialSorting,setPotentialSorting] = useState([]);
  const [potentialPagination,setPotentialPagination] = useState({pageIndex: 0,pageSize: 10});
  const [potentialSearchQuery,setPotentialSearchQuery] = useState('');

  // State for Active Action Status
  const [activeInstances,setActiveInstances] = useState<DynamicObject[]>();
  const [activeTotalItems,setActiveTotalItems] = useState(0);
  // const [activeCustomColumns,setActiveCustomColumns] = useState<CustomColumn[] | undefined>(undefined);
  const [activeSorting,setActiveSorting] = useState([]);
  const [activePagination,setActivePagination] = useState({pageIndex: 0,pageSize: 10});
  const [activeSearchQuery,setActiveSearchQuery] = useState('');

  // State for Failed Action Status
  const [failedInstances,setFailedInstances] = useState<DynamicObject[]>();
  const [failedTotalItems,setFailedTotalItems] = useState(0);
  // const [failedCustomColumns,setFailedCustomColumns] = useState<CustomColumn[] | undefined>(undefined);
  const [failedSorting,setFailedSorting] = useState([]);
  const [failedPagination,setFailedPagination] = useState({pageIndex: 0,pageSize: 10});
  const [failedSearchQuery,setFailedSearchQuery] = useState('');

  const shape: NodeShape = ExecutableAction.shape;

  const debouncedSetPotentialSearchQuery = useCallback(
    debounce((value) => {
      setPotentialSearchQuery(value);
      setPotentialPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },500),
    [],
  );
  const debouncedSetActiveSearchQuery = useCallback(
    debounce((value) => {
      setActiveSearchQuery(value);
      setActivePagination((prev) => ({ ...prev, pageIndex: 0 }));
    },500),
    [],
  );

  const debouncedSetFailedSearchQuery = useCallback(
    debounce((value) => {
      setFailedSearchQuery(value)
      setFailedPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },500),
    [],
  );

  // Fetch data for potential actions
  async function fetchPotentialData()
  {
    try
    {
      const potentialData = await loadExecutableAction({
        searchQuery: potentialSearchQuery,
        pageSize: potentialPagination.pageSize,
        pageIndex: potentialPagination.pageIndex,
        sorting: potentialSorting,
      });
      setPotentialInstances(potentialData.instances);
      setPotentialTotalItems(potentialData.numInstances);
    } catch (error)
    {
      console.error('Error fetching potential data:',error);
    }
  }

  // Fetch data for active actions
  async function fetchActiveData()
  {
    try
    {
      const activeData = await loadExecutableAction({
        searchQuery: activeSearchQuery,
        pageSize: activePagination.pageSize,
        pageIndex: activePagination.pageIndex,
        sorting: activeSorting,
        actionStatus: 1,
      });
      setActiveInstances(activeData.instances);
      setActiveTotalItems(activeData.numInstances);
    } catch (error)
    {
      console.error('Error fetching active data:',error);
    }
  }

  // Fetch data for active actions
  async function fetchFailedData()
  {
    try
    {
      const failedData = await loadExecutableAction({
        searchQuery: failedSearchQuery,
        pageSize: failedPagination.pageSize,
        pageIndex: failedPagination.pageIndex,
        sorting: failedSorting,
        actionStatus: 3,
      });
      setFailedInstances(failedData.instances);
      setFailedTotalItems(failedData.numInstances);
    } catch (error)
    {
      console.error('Error fetching failed data:',error);
    }
  }

  // // Set up custom columns for potential actions
  // useEffect(() => {
  //   if (potentialInstances && potentialInstances.length > 0)
  //   {
  //     setPotentialCustomColumns(generateCustomColumns(potentialInstances));
  //   }
  // },[potentialInstances]);

  // // Set up custom columns for active actions
  // useEffect(() => {
  //   if (activeInstances && activeInstances.length > 0)
  //   {
  //     setActiveCustomColumns(generateCustomColumns(activeInstances));
  //   }
  // },[activeInstances]);

  // // Set up custom columns for failed actions
  // useEffect(() => {
  //   if (failedInstances && failedInstances.length > 0)
  //   {
  //     setFailedCustomColumns(generateCustomColumns(failedInstances));
  //   }
  // },[failedInstances]);

  // Fetch data when pagination, sorting, or searchQuery changes for each action status
  useEffect(() => {
    fetchPotentialData();
  },[potentialPagination.pageIndex,potentialPagination.pageSize,potentialSorting,potentialSearchQuery]);

  useEffect(() => {
    // Fetch data immediately when dependencies change
    fetchActiveData();

    let intervalId;
    // Set up interval to refetch every 15 seconds
    if(activeInstances && activeInstances.length > 0) {
       intervalId = setInterval(() => {
        fetchActiveData();
      },5000); // 15 seconds
    }

    // Cleanup interval on component unmount or when dependencies change
    return () => intervalId && clearInterval(intervalId);
  },[activePagination.pageIndex,activePagination.pageSize,activeSorting,activeSearchQuery]);

  const navigate = useNavigate();
  useEffect(() => {
    // Fetch data immediately when dependencies change
    fetchFailedData();

    // Set up interval to refetch every 15 seconds
    const intervalId = setInterval(() => {
      fetchFailedData();
    },15000); // 15 seconds

    // Cleanup interval on component unmount or when dependencies change
    return () => clearInterval(intervalId);
  },[failedPagination.pageIndex,failedPagination.pageSize,failedSorting,failedSearchQuery]);

  const potentialCustomColumns = useCustomColumns(potentialInstances);
  const activeCustomColumns = useCustomColumns(activeInstances);
  const failedCustomColumns = useCustomColumns(failedInstances);

  const onClickActionDetails = (instance: DynamicObject) => {
    navigate(`${location.pathname}/details?id=${instance.id}`);
  }

  return (
    <>
      <h2>Pending approval</h2>
      {!potentialInstances || (potentialInstances.length === 0 && !potentialCustomColumns) ? (
        renderNoDataMessage(potentialInstances,'pending approvals')
      ) : potentialInstances && potentialCustomColumns ? (
        <InstanceOverview
          instances={potentialInstances}
          shape={shape}
          customColumns={potentialCustomColumns}
          pagination={potentialPagination}
          totalItems={potentialTotalItems}
          onPaginationChange={setPotentialPagination}
          sorting={potentialSorting}
          onDetailsClick={onClickActionDetails}
          onSortingChange={setPotentialSorting}
          onSearchChange={debouncedSetPotentialSearchQuery}
          isLight={isLight}
          batchActionDropdown={(selectedRows) =>
            <ApprovalActionDropdown selectedRows={selectedRows} onComplete={fetchPotentialData} />}
        />
      ) : (
        <Spinner />
      )}
      <br />
      {activeInstances && (activeInstances.length > 0 && activeCustomColumns) && <>
        <h2>Currently being applied</h2>
        <InstanceOverview
          instances={activeInstances}
          shape={shape}
          customColumns={activeCustomColumns}
          pagination={activePagination}
          totalItems={activeTotalItems}
          onPaginationChange={setActivePagination}
          sorting={activeSorting}
          onSortingChange={setActiveSorting}
          onSearchChange={debouncedSetActiveSearchQuery}
          isLight={isLight}
          // batchActionDropdown={(selectedRows) => <ApprovalActionDropdown selectedRows={selectedRows} />}
        />
      </>}
      <br />
      {failedInstances && failedInstances.length === 0 && failedCustomColumns && <>
        <h2>Failed Actions</h2>
        <InstanceOverview
          instances={failedInstances}
          shape={shape}
          customColumns={failedCustomColumns}
          pagination={failedPagination}
          totalItems={failedTotalItems}
          onPaginationChange={setFailedPagination}
          sorting={failedSorting}
          onSortingChange={setFailedSorting}
          onSearchChange={debouncedSetFailedSearchQuery}
          isLight={isLight}
          // batchActionDropdown={(selectedRows) => <ApprovalActionDropdown selectedRows={selectedRows} />}
        />
     </>}
   </>)
}

export default ApprovalPage;