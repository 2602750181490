import React, {useEffect, useState, useCallback, useContext} from 'react'
import { AdSet } from "titan-ads/lib/shapes/TitanShapes";
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { TitanQueryConfig, loadCampaignData , renderNoDataMessage } from '../utils';
import { Spinner } from '../components/Spinner';
import {InstanceOverview} from '../components/InstanceOverview'
import { debounce } from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import CampaignActionDropdown from '../components/CampaignActionDropdown';
import {useCustomColumns} from '../hooks/useCustomColumns';
import { useNavigate, Routes, useParams, useLocation } from 'react-router-dom';

interface DynamicObject {
  [key: string]: any;
}

interface CustomColumn {
  property?: { label?: string };
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}

interface CampaignManagementProps {
  batchActions?: any; // Adjust the type as needed
}

function CampaignManagement({ batchActions }: CampaignManagementProps) {
  const { isLight } = useContext(ThemeContext); // Access the theme context
  const [instances, setInstances] = useState<DynamicObject[]>();
  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([]);
  const [pagination, _setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const shape: NodeShape = AdSet.shape;

  const setPagination = (val) => {
    return _setPagination(val);
  }

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page
    }, 500), // 500ms debounce delay
    []
  );

  async function fetchCampaignData() {
    try {
      const campaignData = await loadCampaignData({
        searchQuery: searchQuery,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting: sorting,
      });
      setInstances(campaignData.instances);
      setTotalItems(campaignData.numInstances);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchCampaignData();
  },[pagination.pageIndex, pagination.pageSize, sorting, searchQuery])

  const customColumns = useCustomColumns(instances);

  const detailsViewHandler = (row) => {
    navigate(`${location.pathname}/details?uri=${row.id}`);
  }

  return (
    <>
       {instances && customColumns ? (
        <InstanceOverview 
          instances={instances} 
          shape={shape} 
          customColumns={customColumns}
          pagination={pagination}
          totalItems={totalItems}
          onPaginationChange={setPagination} 
          sorting={sorting}
          onSortingChange={setSorting}
          onSearchChange={debouncedSetSearchQuery}
          isLight={isLight} // Pass isLight to InstanceOverview
          batchActionDropdown={(selectedRowData) => <CampaignActionDropdown selectedRowData={selectedRowData} onComplete={fetchCampaignData}/>}
          rightSideMenu={true}
          onDetailsClick={detailsViewHandler}
        />) : (<Spinner />)}
    </>
      
  )
}

export default CampaignManagement