import * as React from 'react';

import './Tooltip.scss';
import style from './Tooltip.scss.json';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cl } from 'lincd/lib/utils/ClassNames';

function extend<C extends React.FunctionComponent<any>>(
  Component: C,
  baseClassName: string,
) {
  let extended = React.forwardRef<
    React.ElementRef<C>,
    React.ComponentPropsWithoutRef<C>
  >(({className, ...props}, ref) =>
    React.createElement(Component, {
      ref,
      className: cl(baseClassName, className),
      ...props,
    }),
  );
  extended.displayName = Component.displayName;
  return extended;
}

const Provider = TooltipPrimitive.Provider;
const Root = TooltipPrimitive.Root;
const Trigger = TooltipPrimitive.Trigger;
const Arrow = extend(TooltipPrimitive.Arrow, style.Arrow);

const Content = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({className, sideOffset = 4, ...props}, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cl(style.Content, className)}
    {...props}
  />
));
Content.displayName = TooltipPrimitive.Content.displayName;

export const Tooltip = {
  Root,
  Provider,
  Trigger,
  Arrow,
  Content,
};

export {Root, Provider, Trigger, Arrow, Content};