import { useEffect, useState } from "react";
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from "../package";

export type AdSpendLimitState = {
    uri: string;
    shortName: string;
    spendSinceLastSale: string;
    maxPercentage: number;
    payout: number;
    currentPercentage: number;
    time: string;
    status: string;
}

export const STATUS_COLORS = {
    PAUSED: '#ffecb7',
    RESUMED: '#cee7fe',
    RUNNING: '#ccebd7'
};

// this hook is used in src/components/Automation/LimitAdSpend.tsx
export function useAutomation2() {
    const [loading, setLoading] = useState<boolean>(false);
    const [ads, setAds] = useState<AdSpendLimitState[]>([]);
    const [selected, setSelected] = useState<Set<string>>(new Set());
    const [selectAll, setSelectAll] = useState<boolean>(true);

    // get automation limit ad spend data from server
    const fetchAds = async () => {
        setLoading(true);
        try {
            const response = await Server.call(packageName, 'getAutomationLimitAdSpend');
            setAds(response);
            
            if (response && selectAll) {
                const uris = new Set<string>(
                    response
                        .filter((ad: AdSpendLimitState) => ad.status === 'PAUSED')
                        .map((ad: AdSpendLimitState) => ad.uri)
                );
                setSelected(new Set());
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // toggle select all checkbox
    // only PAUSED ads can be selected
    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll && ads) {
            const allUris = new Set<string>(
                ads
                    .filter((ad) => ad.status === 'PAUSED')
                    .map(ad => ad.uri)
            );
            setSelected(allUris);
        } else {
            setSelected(new Set<string>());
        }
    };

    // toggle select ad, only PAUSED ads can be selected
    const toggleSelect = (uri: string) => {
        setSelected(prev => {
            const updated = new Set(prev);
            if (updated.has(uri)) {
                updated.delete(uri);
            } else {
                updated.add(uri);
            }

            // handle select all checkbox based on selection state
            setSelectAll(
                updated.size === ads.filter((ad) => ad.status === 'PAUSED').length
            );

            return updated;
        });
    };

    // submit PAUSED ads to resume
    const onResumeAds =async () => {
        setLoading(true);
        try {
            const uris = Array.from(selected);
            const response = await Server.call(packageName, 'runResumeAds', uris);
            
            if (!response) {
                alert('Resume Ads Failed!');
                return;
            }

            // update adSpendLimit table with new response from runResumeAds
            setAds(response);

            // reset selected ads
            setSelected(new Set<string>());
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        ads,
        selected,
        selectAll,
        fetchAds,
        toggleSelectAll,
        toggleSelect,
        onResumeAds
    }
}