import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './ShapeTable.scss';
import style from './ShapeTable.scss.json';
import {Icons} from './Icons';
import {cl} from 'lincd/lib/utils/ClassNames';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import { useNavigate, Routes, useParams, useLocation } from 'react-router-dom';
import {Spinner} from './Spinner';
import { ROUTES } from '../routes';
import { useRowContext } from '../hooks/useRowContext';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { URI } from 'lincd/lib/utils/URI';
import { TimeRangeProps } from '../types';
import { FilterTimeRange } from './FilterTimeRange';

interface MenuItem {
  name: string;
  checked?: boolean;
  value?: string;
  condition?: string;
  subItems?: MenuItem[];
}

const menuItems: MenuItem[] = [
  // {name: 'Accounts', value: '', condition: '', subItems: []},
  // {name: 'Campaigns', value: '', condition: '', subItems: []},
  // {name: 'AdSets', value: '', condition: '', subItems: []},
  {name: 'Ads', value: '', condition: '', subItems: []},
];

// eslint-disable-next-line react/display-name
// @ts-ignore
export const FilterComponent = forwardRef<HTMLDivElement, FilterComponentProps>(
  ({data, onClose, filterInstances, filterOff, isLoading, onOpen}, ref) => {
    // State for menu items
    const [menuItemsState, setMenuItemsState] = useState<MenuItem[]>([]);
    const [filterItems, setFilterItems] = useState([]);
    const [filterMenuVisible, setFilterMenuVisible] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState(false);
    const [searchResultItem, setSearchResultItem] = useState<MenuItem[]>([]);
    const [filterValue, setFilterValue] = useState([]);
    const [filterCondition, setFilterCondition] = useState([]);
    const [filterName, setFilterName] = useState([]);

    // Pagination state and query
    const [filterDataCount, setFilterDataCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    // Debounce logic for search
    const debounce = (func: (...args: any[]) => void, delay: number) => {
      let timeoutId: NodeJS.Timeout;
      return function (...args: any[]) {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
      };
    };

    // Handle search filter based on query input
    const handleSearchChange = useCallback(
      debounce((query: string | null) => {
        const loweredQuery = query?.toLowerCase();

        // If no menu items are loaded yet, initialize them
        if (menuItemsState.length === 0) {
          setMenuItemsState(menuItems);
          return;
        }

        // If the query is not empty
        if (loweredQuery) {
          // Filter the menu items based on the query
          const filteredMenuItems = menuItemsState
            .map((item) => {
              // Filter the sub-items
              const matchingSubItems =
                item.subItems?.filter((subItem) =>
                  subItem.name.toLowerCase().includes(loweredQuery),
                ) || [];

              // Check if the item itself matches the query or has matching sub-items
              if (
                matchingSubItems.length > 0 ||
                item.name.toLowerCase().includes(loweredQuery)
              ) {
                return {
                  ...item,
                  subItems: matchingSubItems,
                };
              }
              return null; // Exclude items that do not match
            })
            .filter(Boolean); // Remove null entries

          setSearchResultItem(filteredMenuItems);
        } else {
          // Handle the case where the query is empty
          resetMenuItems(); // Reset to original state or perform another action as needed
        }
      }, 300), // Debounce timeout
      [menuItemsState, menuItems], // Dependencies
    );

    // Handle checkbox changes for sub-items
    const handleCheckboxChange = (
      itemName: string,
      subItemName: string,
      isChecked: boolean,
    ) => {
      setMenuItemsState((prevItems) =>
        prevItems.map((item) => ({
          ...item,
          subItems: item.subItems?.map((subItem) =>
            subItem.name === subItemName
              ? {...subItem, checked: isChecked}
              : subItem,
          ),
        })),
      );

      setSearchResultItem((prevItems) => {
        return prevItems.map((item) => ({
          ...item,
          subItems: item.subItems?.map((subItem) =>
            subItem.name === subItemName
              ? {...subItem, checked: isChecked}
              : subItem,
          ),
          checked: item.subItems?.some((subItem) => subItem.checked) || false,
        }));
      });

      setFilterItems((prevItems) => {
        const updatedItems = prevItems.filter(
          (item) => item.name !== subItemName,
        );
        if (isChecked) {
          updatedItems.push({name: subItemName, value: '', condition: ''});
        } else {
          const existingItem = prevItems.find(
            (item) => item.name === subItemName,
          );
          if (existingItem) {
            updatedItems.push(existingItem);
          }
        }
        return updatedItems;
      });
    };

    // Clears all filters
    const clearAllFilters = () => {
      setMenuItemsState((prevItems) =>
        prevItems.map((item) => ({
          ...item,
          subItems: item.subItems?.map((subItem) => ({
            ...subItem,
            checked: false,
          })),
        })),
      );
      setFilterItems([]);
      setFilterMenuVisible(false);
      if (filterOff) {
        filterOff(true);
      }
    };

    // Removes a selected filter
    const removeSelectedFilter = async (
      itemName: string,
      index: number,
      fromWhere?: string,
    ) => {
      setFilterItems((prevItems) => prevItems.filter((_, i) => i !== index));

      setMenuItemsState((prevItems) =>
        prevItems.map((item) => ({
          ...item,
          subItems: item.subItems?.map((subItem) =>
            subItem.name === itemName ? {...subItem, checked: false} : subItem,
          ),
        })),
      );

      // Call handleFilterData with the rest of the active filters
      if (fromWhere === 'list-filter') {
        await handleBlur(filterItems.filter((_, i) => i !== index));
      }
    };

    // Handle apply filter logic with validation
    const handleBlur = async (data?: any) => {
      const formattedFilters = data
        ? data.map((item: any) => ({
            field: item.name,
            value: item.value,
            operator: item.condition,
          }))
        : filterItems?.map((item: any) => ({
            field: item.name,
            value: item.value,
            operator: item.condition,
          }));

      if (formattedFilters) {
        isLoading(true);
        setFilterVisible(false);
        // Sends filter data to the API
        if(filterInstances) {
          await filterInstances(formattedFilters);
        }
        isLoading(false);
      } else {
        // UX improvement: use notification instead of alert
        alert(
          'Please select the condition and enter the value for all filters.',
        );
      }
    };

    // Effect for setting menu items and additional dynamic attributes (adItems)
    useEffect(() => {
      const adItems = data
        .getHeaderGroups()
        .flatMap((headerGroup) => headerGroup.headers);

      setMenuItemsState((prevItems) => {
        if (prevItems.length > 0) {
          return prevItems.map((item, index) => ({
            ...item,
            subItems:
              index === 0
                ? adItems.slice(1).map((adItem, adIndex) => {
                    const filterItemIndex = filterItems.findIndex(
                      (filter) => filter.name === adItem.column.id,
                    );
                    return filterItemIndex !== -1
                      ? {
                          name: adItem.column.id,
                          checked: true,
                          condition: filterItems[filterItemIndex].condition,
                          value: filterItems[filterItemIndex].value,
                          index: adIndex,
                        }
                      : {
                          name: adItem.column.id,
                          checked: false,
                          index: adIndex,
                        };
                  })
                : item.subItems,
          }));
        }
        return menuItems;
      });

      setSearchResultItem((prevItems) => {
        if (prevItems.length > 0) {
          return prevItems.map((item, index) => ({
            ...item,
            subItems:
              index === 0
                ? adItems.slice(1).map((adItem) => ({
                    name: adItem.column.id,
                    checked: false,
                  }))
                : item.subItems,
          }));
        }
        return menuItems;
      });
    }, [data, filterItems, filterName, filterValue, filterCondition]);

    // Effect for handling filter visibility when menu items or onClose changes
    useEffect(() => {
      if (!onClose) return;

      setFilterVisible(false);
      onOpen?.(false);
    }, [onClose]);

    const handleSelectChange = (e, subItem, key, setState) => {
      const value = e.target.value;
      const itemIndex = menuItemsState.findIndex(
        (item) => item.name === subItem.name,
      );
      if (itemIndex !== -1) {
        setState((prevValues) => {
          const newValues = [...prevValues];
          newValues[itemIndex] = value;
          return newValues;
        });
      }
      setFilterItems((prevState) => {
        const updatedItems = [...prevState];
        const filterItemIndex = updatedItems.findIndex(
          (item) => item.name === subItem.name,
        );
        if (filterItemIndex !== -1) {
          updatedItems[filterItemIndex] = {
            ...updatedItems[filterItemIndex],
            [key]: value,
          };
        } else {
          updatedItems.push({name: subItem.name, [key]: value});
        }
        return updatedItems;
      });
    };

    // Reset menu items to original state
    const resetMenuItems = () => {
      // Resetting both the state and sub-items
      setSearchResultItem(menuItemsState);
    };

    return (
      <div className={style.ShapeTable}>
        <div className={style.filterContainer}>
          <div
            className={cl(style.filter, {[style.active]: filterVisible})}
            onClick={() => setFilterVisible(!filterVisible)}
          >
            <img src={asset('/images/filter.png')} alt="filter-logo" />
            <p>Filter</p>
          </div>

          {filterItems.length > 0 && (
            <div className={style.filterActive}>
              <ul>
                {filterItems
                  .filter((item) => item.value)
                  .map((item, index) => (
                    <li key={item.name}>
                      <p>
                        {item.name
                          .replace(/([a-z])([A-Z])/g, '$1 $2')
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                        :{' '}
                      </p>
                      <h5>{item.value}</h5>
                      <img
                        src={asset('/images/close.png')}
                        alt="close-selected"
                        onClick={() =>
                          removeSelectedFilter(item.name, index, 'list-filter')
                        }
                      />
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>

        {filterVisible && (
          <div ref={ref} className={style.filterOverlay}>
            <div className={style.filterInner}>
              {filterItems.length === 0 && (
                <div className={style.filterCondition}>
                  <p>No filters applied yet</p>
                </div>
              )}
              {menuItemsState
                .flatMap((item) =>
                  item.subItems
                    ?.filter((subItem) => subItem.checked)
                    .map((subItem) => ({parent: item, subItem})),
                )
                .map(({parent, subItem}, itemIndex) => {
                  return (
                    <React.Fragment key={`${subItem.name}-${itemIndex}`}>
                      <div className={style.filterSelection}>
                        <img
                          src={asset('/images/menu.png')}
                          alt="menu-filter"
                          className={style.menuFilterIcons}
                        />
                        <p>Where</p>
                        <select
                          defaultValue=""
                          value={
                            menuItemsState.length > 0 && subItem.name
                              ? subItem.name
                              : filterName
                          }
                          onChange={(e) =>
                            handleSelectChange(
                              e,
                              subItem,
                              'name',
                              setFilterValue,
                            )
                          }
                        >
                          <option value="" disabled>
                            {subItem.name || 'Select Attributes'}
                          </option>
                          {parent.subItems?.map((optionSubItem) => (
                            <option
                              key={optionSubItem.name}
                              value={optionSubItem.name}
                            >
                              {optionSubItem.name
                                .replace(/([a-z])([A-Z])/g, '$1 $2')
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                            </option>
                          ))}
                        </select>
                        <select
                          defaultValue=""
                          value={
                            menuItemsState.length > 0 && subItem.condition
                              ? subItem.condition
                              : filterCondition
                          }
                          onChange={(e) =>
                            handleSelectChange(
                              e,
                              subItem,
                              'condition',
                              setFilterCondition,
                            )
                          }
                        >
                          <option value="" disabled>
                            ⊕ Add Condition
                          </option>
                          <option value="not_equal">Not Equal</option>
                          <option value="smaller">Smaller</option>
                          <option value="smaller_or_equal">
                            Smaller or Equal
                          </option>
                          <option value="equal">Equals</option>
                          <option value="greater">Greater</option>
                          <option value="greater_or_equal">
                            Greater or Equal
                          </option>
                          <option value="contains">Contains</option>
                        </select>
                        <input
                          type="text"
                          placeholder="Enter value..."
                          value={
                            menuItemsState.length > 0 && subItem.value
                              ? subItem.value
                              : filterValue
                          }
                          aria-label="Filter Value"
                          onChange={(e) =>
                            handleSelectChange(
                              e,
                              subItem,
                              'value',
                              setFilterValue,
                            )
                          }
                        />
                        <img
                          src={asset('/images/close.png')}
                          alt="close-filter"
                          className={style.menuFilterIcons}
                          onClick={() =>
                            removeSelectedFilter(subItem.name, itemIndex)
                          }
                        />
                      </div>
                      <hr />
                    </React.Fragment>
                  );
                })}

              {filterItems.length > 0 && (
                <button
                  className={style.appliedFilter}
                  onClick={() => handleBlur()}
                >
                  Apply Filter
                </button>
              )}

              <div className={style.filterAdd}>
                <div
                  className={cl(style.filterAddons, {
                    [style.active]: filterMenuVisible,
                  })}
                  onClick={() => setFilterMenuVisible(!filterMenuVisible)}
                >
                  <img src={asset('/images/plus.svg')} alt="add-filter" />
                  <p>{filterMenuVisible ? 'Close filter' : 'Add filter'}</p>
                </div>

                <div className={style.filterAddons} onClick={clearAllFilters}>
                  <p>Clear all filters</p>
                </div>
              </div>

              {filterMenuVisible && (
                <nav className={style.menu}>
                  <div className={style.searchContainer}>
                    <input
                      type="text"
                      placeholder="Search attributes..."
                      className={style.searchInput}
                      onChange={(e) => handleSearchChange(e.target.value)}
                      style={{
                        backgroundImage: `url(${asset('/images/glass.png')})`,
                        backgroundColor: '#fafafa',
                        backgroundRepeat: 'no-repeat',
                        paddingLeft: '35px',
                        backgroundSize: '28px',
                        backgroundPosition: '5px',
                        position: 'relative',
                        top: '5px',
                        left: '2px',
                        borderWidth: '1px !important',
                        borderColor: '#c1c1c1 !important',
                        borderStyle: 'solid !important',
                      }}
                    />
                  </div>
                  <ul>
                    {searchResultItem
                      .filter((item) => item.subItems.length > 0)
                      .map((item) => (
                        <li key={item.name} className={style.menuList}>
                          {item.subItems &&
                            item.subItems.map((subItem) => (
                              <div
                                key={subItem.name}
                                className={style.subMenuItem}
                                onClick={() => {
                                  const isChecked = !subItem.checked;
                                  setCheckboxValue(isChecked);
                                  handleCheckboxChange(
                                    item.name,
                                    subItem.name,
                                    isChecked,
                                  );
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    filterItems.length > 0
                                      ? filterItems.some(
                                          (filter) =>
                                            filter.name === subItem.name,
                                        )
                                      : subItem.checked
                                  }
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    setCheckboxValue(e.target.checked);
                                    handleCheckboxChange(
                                      item.name,
                                      subItem.name,
                                      e.target.checked,
                                    );
                                  }}
                                />
                                <label>{subItem.name}</label>
                              </div>
                            ))}
                        </li>
                      ))}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);
FilterComponent.displayName = 'FilterComponent';

interface ShapeTable {
  data: any | ShapeSet<any>;
  columns: ColumnDef<any>[];
  defaultColumn?:Partial<ColumnDef<any>>;
  pageReport?: string;
  filterInstances: (filters: any) => void;
  resetFilter: (value: boolean) => void;
  toggleSelectAll: () => void;
  isRowSelected: (id: string) => boolean;
  selectedRows: string[];
  toggleRowSelection: (id: string) => void;
  isDeleteBtnShowing: boolean;
  shape?: NodeShape;
  pagination: {pageIndex: number; pageSize: number};
  totalItems: number;
  onPaginationChange: (newPagination: {
    pageIndex: number;
    pageSize: number;
  }) => void;
  sorting: Array<any>;
  onSortingChange: (any) => void;
  onSearchChange: (any) => void;
  timeRange?: TimeRangeProps;
  onTimeRangeChange?: (timeRange: TimeRangeProps) => void;
  isLight?: boolean;
  batchActionDropdown?: React.ReactNode;
  onDetailsClick: (any) => void;
}

function ShapeTable({
  data,
  columns,
  defaultColumn,
  toggleSelectAll,
  filterInstances,
  resetFilter,
  isRowSelected,
  selectedRows,
  toggleRowSelection,
  isDeleteBtnShowing,
  shape,
  pagination, // Receive pagination state
  totalItems, // Receive total items count
  onPaginationChange, // Receive pagination change handler
  sorting,
  onSortingChange,
  onSearchChange,
  isLight,
  batchActionDropdown,
  onDetailsClick,
  timeRange,
  onTimeRangeChange,
}: ShapeTable
) {
  const location = useLocation();

  const memoizedData = React.useMemo(() => {
    return [...data];
  }, [data]);

  const table = useReactTable({
    columns,
    defaultColumn,
    data: memoizedData,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: () => {
      onPaginationChange(pagination);
    },
    pageCount: Math.ceil(totalItems / pagination.pageSize), // Calculate total pages
    onSortingChange: onSortingChange,
  });

  const filterMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterMenuRef.current &&
        event.target instanceof Node &&
        !filterMenuRef.current.contains(event.target)
      ) {
        setClickOutSide(!clickOutSide);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [clickOutSide, setClickOutSide] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  // console.log('customTimeRange', customTimeRange);

  return (
    <>
      <div className={style.tableWrapper}>
        <div className={style.action}>
          {/* added check to wait for data to load before rendering filter */}
          {table && (
            <FilterComponent
              ref={filterMenuRef}
              data={table}
              onClose={clickOutSide}
              filterInstances={filterInstances}
              filterOff={resetFilter}
              isLoading={handleLoading}
              onOpen={(value: boolean) => setClickOutSide(value)}
            />
          )}
          <div className={style.batchActionGroup}>
            {selectedRows.length > 0 && batchActionDropdown && (
              <>
                <p>Batch Actions</p>
                {React.cloneElement(
                  batchActionDropdown as React.ReactElement<any>,
                  { selectedRows },
                )}
              </>
            )}
          </div>
          <div className={style.actionWrapper}>
            <div className={style.searchAd}>
              <input
                type="text"
                className={style.searchBox}
                placeholder="Search..."
                onChange={(e) => onSearchChange(e.target.value)}
              />
            </div>
            {timeRange && (
              <FilterTimeRange name="timerange" variant="outline" timeRange={timeRange} onHandleChange={onTimeRangeChange} />
            )}
          </div>
        </div>

        <div
          className={cl(
            style.tableContainer,
            pagination.pageSize >= 20 && style.expanded,
          )}
        >
          <div className={style.overlaySpinner}>
            <Spinner active={loading} />
          </div>
          <table className={cl(style.table)}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className={isLight ? style.tr : style.trDark}
                >
                  <th
                    className={cl(
                      isLight ? style.th : style.thDark,
                      style.sticky,
                      style.stickyLeft,
                    )}
                  >
                    <input type="checkbox" onChange={toggleSelectAll} />
                  </th>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={isLight ? style.th : style.thDark}
                    >
                      {index === 0 ? (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      ) : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? style.canSort
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: <Icons.UpArrow />,
                            desc: <Icons.DownArrow />,
                          }[header.column.getIsSorted() as string] ?? (
                            <Icons.LeftRightArrow />
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                  {/* Conditionally render the right-side fixed header column */}
                  {onDetailsClick && (
                    <th
                      className={cl(
                        isLight ? style.th : style.thDark,
                        style.sticky,
                        style.stickyRight,
                      )}
                    >
                      Actions
                    </th>
                  )}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  data-id={row.original.id}
                  className={isLight ? style.tr : style.trDark}
                >
                  <td
                    className={cl(
                      isLight ? style.td : style.tdDark,
                      style.sticky,
                      style.stickyLeft,
                    )}
                  >
                    <input
                      type="checkbox"
                      onChange={() => toggleRowSelection(row.original.id)}
                      checked={isRowSelected(row.original.id)}
                    />
                  </td>
                  {row.getVisibleCells().map((cell) => {
                    //see if there is any function provided for this column to dynamically determine an extra className
                    const dynamicClassNameFn = cell.column.columnDef.meta ? cell.column.columnDef.meta['className'] : null;
                    let dynamicClassName = '';
                    if (dynamicClassNameFn)
                    {
                      //if so, send the cell value to it
                      dynamicClassName = dynamicClassNameFn(cell.renderValue(),cell.row.original);
                    }

                    return (<td
                        key={cell.id}
                        className={cl(isLight ? style.td : style.tdDark)}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    )
                  })}
                  {/* Conditionally render the right-side fixed cell */}
                  {onDetailsClick && (
                    <td
                      className={cl(
                        isLight ? style.td : style.tdDark,
                        style.sticky,
                        style.stickyRight,
                      )}
                    >
                      <button
                        onClick={() => onDetailsClick(row.original)}
                        className={style.actionButton}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#000000"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#000000"
                            d="M9.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z"
                          ></path>
                          <path
                            fill="#000000"
                            fillRule="evenodd"
                            d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20c4.182 0 7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4 7.818 4 4.972 6.5 3.275 8.704 2.425 9.81 2 10.361 2 12zm10-3.75a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={style.pagination}>
          <span className={style.pageInfo}>
            Page {pagination.pageIndex + 1} of{' '}
            <strong>{Math.ceil(totalItems / pagination.pageSize)}</strong>
          </span>
          <span className={style.pageInfo}>
            <input
              type="number"
              className={style.goToPageForm}
              value={pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                onPaginationChange({ ...pagination, pageIndex: page });
              }}
            />
          </span>
          <select
            className={style.selectPageRows}
            value={pagination.pageSize}
            onChange={(e) => {
              onPaginationChange({
                ...pagination,
                pageSize: Number(e.target.value),
              });
            }}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
          <button
            className={style.paginationButton}
            onClick={() => onPaginationChange({ ...pagination, pageIndex: 0 })}
            disabled={pagination.pageIndex === 0}
          >
            <Icons.DoubleLeftArrow />
          </button>
          <button
            className={style.paginationButton}
            onClick={() =>
              onPaginationChange({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              })
            }
            disabled={pagination.pageIndex === 0}
          >
            <Icons.SingleLeftArrow />
          </button>
          <button
            className={style.paginationButton}
            onClick={() =>
              onPaginationChange({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              })
            }
            disabled={
              pagination.pageIndex >=
              Math.ceil(totalItems / pagination.pageSize) - 1
            }
          >
            <Icons.SingleRightArrow />
          </button>
          <button
            className={style.paginationButton}
            onClick={() =>
              onPaginationChange({
                ...pagination,
                pageIndex: Math.ceil(totalItems / pagination.pageSize) - 1,
              })
            }
            disabled={
              pagination.pageIndex >=
              Math.ceil(totalItems / pagination.pageSize) - 1
            }
          >
            <Icons.DoubleRightArrow />
          </button>
        </div>
      </div>
    </>
  );

}

export default ShapeTable;
