import React,{useCallback,useContext,useEffect,useState} from 'react';
import {ExecutableAction} from 'titan-ads/lib/shapes/ExecutableAction';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {DynamicObject,loadExecutableAction} from '../utils';
import {InstanceOverview} from '../components/InstanceOverview';
import {debounce} from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import {FilterProps, TimeRangeProps} from '../types';
import {Spinner} from '../components/Spinner';
import { useNavigate } from 'react-router-dom';
import { useCustomColumns } from '../hooks/useCustomColumns';
import dayjs from 'dayjs';
import './OptimizationReport.scss';
import style from './OptimizationReport.scss.json';
import { FilterTimeRange } from '../components/FilterTimeRange';

function OptimizationReport()
{
  const {isLight} = useContext(ThemeContext);
  const navigate = useNavigate();
  // State for Complete Action Status
  const [completeInstances,setCompleteInstances] = useState<DynamicObject[]>();
  const [completeTotalItems,setCompleteTotalItems] = useState(0);
  const [completeSorting,setCompleteSorting] = useState([{id: 'time',desc: true}]); // sort by time, last executed first
  const [completePagination,setCompletePagination] = useState({pageIndex: 0,pageSize: 10});
  const [completeSearchQuery,setCompleteSearchQuery] = useState('');
  const initialFilter = [];
  const [completeFiltersData,setCompleteFiltersData] = useState(initialFilter);
  const [dataLoading, setDataLoading] = useState(true);

  // set default time range to last 7 days
  // const [completeTimeRange,setCompleteTimeRange] = useState<TimeRangeProps>();
  const [completeTimeRange,setCompleteTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), // default is 7 days ago
    endDate: dayjs().format('YYYY-MM-DD') // default is today,
  });

  const shape: NodeShape = ExecutableAction.shape;

  const debouncedSetCompleteSearchQuery = useCallback(
    debounce((value) => {
      setCompleteSearchQuery(value);
      setCompletePagination((prev) => ({...prev,pageIndex: 0}));
    },500),
    [],
  );

  // Fetch data for complete actions
  async function fetchCompleteData()
  {
    try {
      setDataLoading(true);
      const completeData = await loadExecutableAction({
        searchQuery: completeSearchQuery,
        pageSize: 0, 
        pageIndex: 0,
        sorting: completeSorting,
        actionStatus: 2,
        filters: completeFiltersData,
        timeRange: completeTimeRange,
        timeRangeProperty:'startTime'
      });

      // remove `startTime` from the table because we already have requested
      // we keep `startTime` from the mapping because we need it for the time range filter
      completeData.instances.forEach((instance) => {
        delete instance.startTime;
      });
      
      setCompleteInstances(completeData.instances);
      setCompleteTotalItems(completeData.numInstances);
      setDataLoading(false);
    } catch (error) {
      console.error('Error fetching complete data:',error);
      setCompleteInstances([]);
    }
  }

  const completeCustomColumns = useCustomColumns(completeInstances, []);

  useEffect(() => {
    fetchCompleteData();
  }, [
    completeFiltersData, 
    completePagination.pageIndex, 
    completePagination.pageSize,
    completeSearchQuery, 
    completeSorting, 
    completeTimeRange
  ]);

  // handle time range change
  const handleTimeRangeChange = (newTimeRange: TimeRangeProps) => {
    setCompleteTimeRange(newTimeRange);
  };

  const detailsViewHandler = (row: DynamicObject) => {
    navigate(`${location.pathname}/details?uri=${row.id}&timeRange=${completeTimeRange.value}&startDate=${completeTimeRange.startDate}&endDate=${completeTimeRange.endDate}`, { state: { row } });
  }

  // trigger the fetchAdData function when the filter changes
  // newFilters values, e.g. newFilters = [{ field: 'shortAdName', value: 'TF-247', operator: 'equal' }]
  // currentFilter, e.g = [{ field: 'status', value: 'On', operator: 'equal' }]
  // newFilter will replace currentFilter or current filter state
  const handleFilterChange = (newFilters: FilterProps[]) => {
    setCompleteFiltersData(newFilters);
  }

  return (
    <>
    <div className={style.optimizationReport}>
      <h2>Approved actions</h2>
      </div>
      {completeInstances?.length === 0 || completeCustomColumns ? (
        <InstanceOverview
          instances={completeInstances}
          shape={shape}
          customColumns={completeCustomColumns}
          triggerActionRefresh={fetchCompleteData}
          pagination={completePagination}
          totalItems={completeTotalItems}
          filterInstances={(filter: FilterProps[]) => handleFilterChange(filter)}
          resetFilter={() => {
            setCompleteFiltersData(null);
          }}
          onPaginationChange={setCompletePagination}
          sorting={completeSorting}
          onSortingChange={setCompleteSorting}
          onSearchChange={debouncedSetCompleteSearchQuery}
          isLight={isLight}
          timeRange={completeTimeRange}
          onDetailsClick={detailsViewHandler}
          onTimeRangeChange={handleTimeRangeChange}
          isLoading={dataLoading}
          // batchActionDropdown={(selectedRows) => <ApprovalActionDropdown selectedRows={selectedRows} />}
        />
      ) : <Spinner />}
    </>
  );
}

export default OptimizationReport;