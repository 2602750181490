import React, { useEffect, useState } from 'react';
import './Automations.scss';
import style from './Automations.scss.json';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { Tabs } from '../components/Tabs';
import { automation1, automation2, automation3, automation5 } from '../data/automations';
import { LimitAdSpend } from '../components/Automation/LimitAdSpend';
import ResetBidAmounts from '../components/Automation/ResetBidAmount';
import { RunConvertingAds } from '../components/Automation/RunConvertingAds';
import { RunProfitableAds } from '../components/Automation/RunProfitableAds';

type AdState = {
    uri: string;
    shortName: string;
    quoraId: string;
}

type AdsState = {
    adsToPause: AdState[];
    adsKeepRunning: AdState[];
    adsKeepRunningReasons: Record<string, {
        rule: string;
        message: string;
    }[]>;
};

export default function Automations() {
    const [ads, setAds] = useState<AdsState>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [lastExecuted, setLastExecuted] = useState<{currentTime,lastExecuted}>({currentTime: null, lastExecuted: null});
    const [selectedAds, setSelectedAds] = useState<Set<string>>(new Set());
    const [selectAll, setSelectAll] = useState<boolean>(true);

  const [selectAllAdsets, setSelectAllAdsets] = useState<boolean>(false);
  const [currentAdsets, setCurrentAdsets] = useState([]);
  const [selectedAdsets, setSelectedAdsets] = useState<Set<string>>(new Set());

  // get last executed time first load
    useEffect(() => {
        getLastExecuted();
        getAdsetsOfResetBidAmountAutomation();
    }, []);

    // run converting ads for get running and paused ads
    const onRunConvertingAds = async () => {
        setLoading(true);
        try {
            const response = await Server.call(packageName, 'runConvertingAds');
            setAds(response);

            // set ads to pause will be selected by default if selectAll is true
            if (response?.adsToPause && selectAll) {
                const allUris = new Set<string>(response.adsToPause.map((ad: AdState) => ad.uri));
                setSelectedAds(allUris);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    // get last executed time
    const getLastExecuted = async () => {
        try {
            const response = await Server.call(packageName, 'getLastExecuted');
            setLastExecuted(response);
            // console.log(`Last Executed: ${response}`);
        } catch (err) {
            console.error(err);
        }
    };

    // handle checkbox change when select each checkbox
    const handleCheckboxChange = (uri: string) => {
        setSelectedAds(prevSelectedAds => {
            const newSelectedAds = new Set(prevSelectedAds);
            if (newSelectedAds.has(uri)) {
                newSelectedAds.delete(uri);
            } else {
                newSelectedAds.add(uri);
            }

            // handle select all checkbox based on each checkbox
            setSelectAll(newSelectedAds.size === ads?.adsToPause.length);

            return newSelectedAds;
        });
    };

    // handle select all checkbox change
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        if (!selectAll && ads?.adsToPause) {
            const allUris = new Set<string>(ads.adsToPause.map(ad => ad.uri));
            setSelectedAds(allUris);
        } else {
            setSelectedAds(new Set<string>());
        }
    };

    // turnOff selected ads
    const onPauseAds = async () => {
        setLoading(true);
        try {
            const uris = Array.from(selectedAds);
            const response = await Server.call(packageName, 'runPausedAds', uris);

            // paused ads failed
            if (!response) {
                alert('Paused Ads Failed!');
                return;
            }

            // remove selected ads from adsToPause table
            setAds(prevAds => {
                if (!prevAds) return prevAds;
                const newAdsToPause = prevAds.adsToPause.filter(ad => !uris.includes(ad.uri));
                return { ...prevAds, adsToPause: newAdsToPause };
            });
            // reset selected ads
            setSelectedAds(new Set<string>());
            
            
            // run getLastExecuted to update last executed time
            // and show paused ads successfully
            getLastExecuted();
            alert('Paused Ads Successfully!');
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

  const getAdsetsOfResetBidAmountAutomation = async () => {
    try {
      const response = await Server.call(packageName, 'getAdsetsOfResetBidAmountAutomation');
      setCurrentAdsets(response);
      // set ads to pause will be selected by default if selectAll is true
      if (response && selectAllAdsets) {
        const uris = new Set<string>(response.map((adset: AdState) => adset.uri));
        setSelectedAdsets(uris);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // handle select all checkbox change
  const handleSelectAllAdsets = () => {
    // setSelectAllAdsets(!selectAllAdsets);
    setSelectAllAdsets((prev) => !prev);
    if (!selectAllAdsets && currentAdsets) {
      const allUris = new Set<string>(currentAdsets.map(adset => adset.uri));
      setSelectedAdsets(allUris);
    } else {
      setSelectedAdsets(new Set<string>());
    }
  };


  const handleCheckboxAdsets = (uri: string) => {
    setSelectedAdsets(prevSelectedAdsets => {
      const newSelectedAdset = new Set(prevSelectedAdsets);
      if (newSelectedAdset.has(uri)) {
        newSelectedAdset.delete(uri);
      } else {
        newSelectedAdset.add(uri);
      }

      // handle select all checkbox based on each checkbox
      setSelectAllAdsets(newSelectedAdset.size === currentAdsets.length);

      return newSelectedAdset;
    });
  }

    return (
        <div className={style.container}>
            <Tabs.Root defaultValue={automation3.identifier as string}>
                <Tabs.List>
                    <Tabs.Trigger value={automation3.identifier as string}>{automation3.name}</Tabs.Trigger>
                    <Tabs.Trigger value={automation2.identifier as string}>{automation2.name}</Tabs.Trigger>
                    <Tabs.Trigger value={automation1.identifier as string}>{automation1.name}</Tabs.Trigger>
                    <Tabs.Trigger value={automation5.identifier as string}>{automation5.name}</Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value={automation3.identifier as string}>
                    <RunConvertingAds/>
                </Tabs.Content>
                <Tabs.Content value={automation2.identifier as string}>
                    <LimitAdSpend />
                </Tabs.Content>
                <Tabs.Content value={automation1.identifier as string}>
                    <ResetBidAmounts/>
                </Tabs.Content>
                <Tabs.Content value={automation5.identifier as string}>
                    <RunProfitableAds/>
                </Tabs.Content>
            </Tabs.Root>
        </div>
    );
}