import {Link} from 'react-router-dom';
import style from './Header.scss.json';
import './Header.scss';
import {ROUTES} from '../routes';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {IoSunnyOutline} from 'react-icons/io5';
import {ThemeContext} from '../contexts/Theme';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import { Tooltip } from '../components/Tooltip';
import { cl } from 'lincd/lib/utils/ClassNames';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { AlertDialog } from '../components/AlertDialog';

export function Header() {
  const {userAccount} = useAuth();
  const {isLight, toggleTheme} = useContext(ThemeContext);
  const [status, setStatus] = useState<'online' | 'offline'>('online');
  const [openDialog, setOpenDialog] = useState(false); 
  const previousStatus = useRef<'online' | 'offline'>('offline');

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout;

    const checkHealth = async () => {
      try {
        const response = await Server.call(packageName, 'getServerHealth');
        if (mounted && response.status === 'ok') {
          // refresh page if status changes from offline to online
          if (previousStatus.current === 'offline' && status === 'offline') {
            console.log('Server is back online, refreshing page...');
            window.location.reload();

            // clear local storage to make sure dialog can show again when server offline
            removeStatusIndicationStorage();
          }

          setStatus('online');
          previousStatus.current = 'online';
          setOpenDialog(false);

          // clear local storage to make sure dialog can show again when server offline
          removeStatusIndicationStorage();
        }
      } catch (error) {
        if (mounted) {
          setStatus('offline');
          previousStatus.current = 'offline';

          // check status indication in local storage
          // to make sure dialog show only 1 time when server offline and user click OK button
          const statusIndication = localStorage.getItem('status_indication');
          if (!statusIndication) {
            setOpenDialog(true);
          }
        }
      }

      // check server health every 10 seconds
      timeoutId = setTimeout(checkHealth, 10000);
    };
    checkHealth();

    // cleanup indicator status
    return () => {
      mounted = false;
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [status]);

  // enable this function and `simulateServerCrash` on the backend to simulate a server crash
  // const simulateCrash = async () => {
  //   try {
  //     await Server.call(packageName, 'simulateServerCrash');
  //     console.log('Crash simulation initiated');
  //   } catch (error) {
  //     console.log('Server responded with error:', error);
  //     setStatus('offline');
  //     setOpenDialog(true);
  //   }
  // };

  // handle error dialog to show only 1 time with store the status in local storage
  const handleDialog = () => {
    setOpenDialog(false);
    localStorage.setItem('status_indication', 'true');
  }

  // remove status indication in local storage
  const removeStatusIndicationStorage = () => {
    localStorage.removeItem('status_indication');
  }

  return (
    <>
      <header className={isLight ? style.header : style.headerDark}>
        <img
          src={asset("/images/Titaninteractive.webp")}
          className={style.logo}
          alt="Titan Interactive"
        />
        <nav className={isLight ? style.menu : style.menuDark}>
          {/*<IoSunnyOutline onClick={toggleTheme} />*/}

          <Tooltip.Provider>
              <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                      <span className={cl(style.statusIndicator, status === 'online' ? style.up : style.down)} />
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                      Server: <strong>{status === 'online' ? `Online` : `Offline`}</strong>
                      <Tooltip.Arrow />
                  </Tooltip.Content>
              </Tooltip.Root>
          </Tooltip.Provider>

          {Object.keys(ROUTES).map((key) => {
            const route = ROUTES[key];
            if (route.excludeFromMenu) return null;

            let userIsWhitelisted = false;

            const userEmailExists = userAccount?.email;
            const isWhitelistedRoute = route.whitelist;
            if (userEmailExists && isWhitelistedRoute) {
              userIsWhitelisted = route.whitelist.includes(userAccount.email);
            }

            const isValidForUser =
              !route.whitelist || (route.whitelist && userIsWhitelisted);

            return (
              <React.Fragment key={key}>
                {userEmailExists && isValidForUser && (
                  <Link key={key} to={route.path}>
                    {route.label || key}
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </nav>
      </header>

      {/* <div>
        <button onClick={() => simulateCrash()}>Simulate Crash</button>
      </div> */}

      {status === 'offline' && (
        <AlertDialog.Root open={openDialog} onOpenChange={setOpenDialog}>
          <AlertDialog.Content className="AlertDialogContent">
            <AlertDialog.Title className="AlertDialogTitle">
              Server Connection Lost
            </AlertDialog.Title>
            <AlertDialog.Description className="AlertDialogDescription">
              Server is updating. Pages may not load. The page will automatically refresh once the server is back up.
            </AlertDialog.Description>
            <div style={{ display: "flex", gap: 25, justifyContent: "flex-end" }}>
              <AlertDialog.Action asChild>
                <button className={style.btnAlert} onClick={handleDialog}>OK</button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Root>
      )}
    </>
  );
}
