import {ImageObject} from 'lincd-schema/lib/shapes/ImageObject';
import {MediaObject} from 'lincd-schema/lib/shapes/MediaObject';
import {VideoObject} from 'lincd-schema/lib/shapes/VideoObject';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {BrandAccount} from 'titan-ads/lib/shapes/TitanShapes';
import FileUploader from '../components/FileUploader';
import '../components/RefreshQuoraData.scss';
import MediaUploadGallery from '../components/MediaGallery';
import {DefaultLayout} from '../layout/DefaultLayout';
import {packageName} from '../package';
import './Media.scss';
import {QuoraAccount} from 'titan-ads/lib/shapes/QuoraAccount';
import {BrandAccountSelector} from '../components/RefreshQuoraData';
import {useSuspense} from '../utils';
import {DashboardContext} from '../contexts/Dashboard';
import {QuoraAdType} from 'titan-ads/lib/types';
import {MIMEType} from '../types';

export interface MediaProps {
  // onMediaReady: (imageList: MediaObject[]) => void;
  onMediaReady: any;
  mediaType: QuoraAdType;
  source?: string;
  cta?: string[];
  isQuickLaunched?: boolean;
  fileInputRef?: any;
  isSelected?: [];
}

QuoraAccount.loadAll();
const initialBrands = useSuspense(BrandAccount.loadAll());

export default function Media({
  mediaType,
  isQuickLaunched,
  isSelected,
  onMediaReady,
}: MediaProps) {
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [browseMedia, setBrowseMedia] = useState<boolean>(false);
  const [fileImage, setFileImage] = useState(null);
  const fileInputRef = useRef(null);
  const [CDNUrl, setCDNUrl] = useState('');
  const [selectedType, setSelectedType] = useState<MIMEType>('image');

  const [inputKey, setInputKey] = useState(Date.now());
  const [fileError, setFileError] = useState('');
  const [nameError, setNameError] = useState('');
  const [galleryImage, setGalleryImage] = useState([]);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadStatus, setUploadStatus] = useState({
    cdnUpload: false,
    localUpload: false,
    cypressIsUpload: false,
    isUploadSuccess: false,
  });

  const [statusVisibility, setStatusVisibility] = useState({
    cdnUpload: true,
    localUpload: false,
    cypressUpload: false,
  });

  const [brands, setBrands] = useState<{read(): any}>(initialBrands);
  const {curAccount} = useContext(DashboardContext);

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const uploadToCypress = async (fileSelected: MediaObject, fileInfo: any) => {
    try {
      // Save the image to local folder
      setStatusVisibility((prevState) => ({...prevState, localUpload: true}));
      console.log('Image URL CDN:', fileSelected.contentUrl);
      setCDNUrl(fileSelected.contentUrl);

      // Save the image to local folder when in production (it will be stored on S3 bucket)
      if (process.env.NODE_ENV === 'production') {
        const localFilesystemPath = await Server.call(
          packageName,
          'saveCDNImageToLocal',
          fileSelected,
        );
        if (localFilesystemPath) {
          fileInfo.filename = localFilesystemPath.split('/').pop();
        }
        console.log('Image saved to local storage. path:', localFilesystemPath);
      }

      setUploadStatus((prevState) => ({
        ...prevState,
        localUpload: true,
      }));
      // fileSelected.contentUrl =
      //   'http://localhost:4000/data/uploads/' + fileSelected.name;
      // console.log(`Image saved to local storage. path: ${saveToLocal}`);
      await delay(2000);
      setStatusVisibility((prevState) => ({
        ...prevState,
        localUpload: false,
      }));
      setStatusVisibility((prevState) => ({
        ...prevState,
        cypressUpload: true,
      }));

      const cypressUpload = await Server.call(
        packageName,
        'cypressUpload',
        fileInfo,
      );
      if (cypressUpload.success) {
        console.log(
          'Cypress tests completed successfully.',
          cypressUpload.data,
        );
        setUploadedImageUrl(cypressUpload.data);
        setUploadStatus((prevState) => ({
          ...prevState,
          cypressIsUpload: true,
          isUploadSuccess: true,
        }));
        await delay(2500);
        setStatusVisibility((prevState) => ({
          ...prevState,
          cypressIsUpload: false,
        }));
        setLoading(false);
        await delay(1500);
        // show media browser
      } else {
        // reset all the value Uploaad status
        setUploadStatus({
          cdnUpload: false,
          localUpload: false,
          cypressIsUpload: false,
          isUploadSuccess: false,
        });
        // reset all the value visibility
        setStatusVisibility({
          cdnUpload: true,
          localUpload: false,
          cypressUpload: false,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };

  const resetInput = () => {
    setInputKey(Date.now()); // Reset the input field
    setFileError(''); // Clear any existing error messages
  };

  useEffect(() => {
    setBrands(useSuspense(BrandAccount.loadAll()));
  }, []);

  useEffect(() => {
    setGalleryImage([]); // Clear previous images
    if (curAccount) {
      const sanitisedBrandName = curAccount.name.replace(/\s+/g, '_');
      const getAllImages = async () => {
        try {
          const images = await Server.call(
            packageName,
            'readImageFromDB',
            sanitisedBrandName,
          );
          if (images) {
            // Transform ImageObject[] to MediaGalleryAsset[]
            const galleryAssets = images.map((image) => ({data: image}));

            setGalleryImage(galleryAssets);
          }
        } catch (error) {
          setGalleryImage([]);
          console.error('Error reading images:', error);
        }
      };
      getAllImages();
    }
  }, [curAccount]);

  useEffect(() => {
    if (mediaType) {
      setSelectedType(mediaType as MIMEType);
    }
  }, [mediaType]);

  const handleFileUpload = async (file: File) => {
    if (!name) {
      setNameError('Please enter the name of the image');
      return;
    } else if (file) {
      setNameError('');
      setStatusVisibility((prevState) => ({
        ...prevState,
        cypressIsUpload: false,
      }));
      if (!nameError && !fileError) {
        setLoading(true);
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (selectedType === 'image' || selectedType === 'logo') {
          if (!curAccount) {
            alert('Please select a brand account');
            return;
          }
          setUploadedImageUrl('');
          // Handle image file
          const reader = new FileReader();
          reader.onload = async (e) => {
            const imageDataUrl = e.target.result as string;
            const accountName = curAccount.name.replace(/\s+/g, '_');
            try {
              const imageObject = await ImageObject.fromDataURL(
                imageDataUrl,
                `${accountName}/${name}.${fileExtension}`,
                {
                  copyrightNotice: curAccount.quoraAccount.name.replace(
                    /\s+/g,
                    '_',
                  ),
                  creditText: accountName,
                  name: name,
                  usageInfo: selectedType,
                },
              );

              if (imageObject) {
                // imageObject.contentUrl = 'https://titan-ad-media.nyc3.cdn.digitaloceanspaces.com/Semantu_Test/t8.png';
                // // if(process.env.NODE_ENV === 'production') {
                //   //also save to filesystem
                //   const saveToLocal = await Server.call(
                //     packageName,
                //     'saveCDNImageToLocal',
                //     imageObject,
                //   );
                //   console.log('Image saved to local storage. path:', saveToLocal)
                // // }
                setUploadStatus((prevState) => ({
                  ...prevState,
                  cdnUpload: true,
                }));
                await delay(2000);
                setStatusVisibility((prevState) => ({
                  ...prevState,
                  cdnUpload: false,
                }));
                const imageInfo = {
                  uri: imageObject.uri,
                  contentUrl: imageObject.contentUrl,
                  filename: imageObject.contentUrl.split('/').pop(),
                  usageInfo: selectedType.toLowerCase(),
                  creditText: curAccount.name
                    .replace(/\s+/g, '_')
                    .toLowerCase(),
                  copyrightNotice: curAccount.quoraAccount.name.replace(
                    /\s+/g,
                    '_',
                  ),
                };

                await uploadToCypress(imageObject, imageInfo);
              }
            } catch (error) {
              console.error('Error during image processing:', error);
              setLoading(false);
            }
          };

          reader.readAsDataURL(file);
        } else {
          // Handle video file
          const accountName = curAccount.name.replace(/\s+/g, '_');
          try {
            const videoURI = await VideoObject.fromFormFile(
              file,
              accountName + '/' + name + '.' + fileExtension,
              {
                copyrightNotice: curAccount.quoraAccount.name.replace(
                  /\s+/g,
                  '_',
                ),
                creditText: accountName,
                usageInfo: selectedType,
              },
            );
            if (videoURI) {
              setUploadStatus((prevState) => ({
                ...prevState,
                cdnUpload: true,
              }));
              await delay(2000);
              setStatusVisibility((prevState) => ({
                ...prevState,
                cdnUpload: false,
              }));

              const videoInfo = {
                uri: videoURI.uri,
                contentUrl: videoURI.contentUrl,
                filename: videoURI.contentUrl.split('/').pop(),
                usageInfo: selectedType.toLowerCase(),
                creditText: curAccount.name.replace(/\s+/g, '_').toLowerCase(),
                copyrightNotice: curAccount.quoraAccount.name.replace(
                  /\s+/g,
                  '_',
                ),
              };
              await uploadToCypress(videoURI, videoInfo);
            }
          } catch (error) {
            console.error('Error during image processing:', error);
            setLoading(false);
          }
        }
      }
    } else {
      setNameError('Please upload the image first');
    }
  };

  // Validation restriction for image upload
  //Recommended aspect ratio: 16:9 to 4:5
  //Recommended minimum width: 580px;
  const handleImageVideoSelect = (e) => {
    setStatusVisibility((prevState) => ({
      ...prevState,
      cypressUpload: false,
    }));
    const file = e.target.files[0];
    if (file) {
      const validExtensionsVideo = ['mov', 'mp4'];
      const validExtensionsImage = ['jpeg', 'jpg', 'png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (
        (selectedType === 'video' &&
          !validExtensionsVideo.includes(fileExtension)) ||
        (selectedType === 'image' &&
          !validExtensionsImage.includes(fileExtension))
      ) {
        setFileError(
          `${fileExtension} is an invalid file format. Please select a ${
            selectedType === 'video' ? 'MOV or MP4' : 'JPEG or PNG'
          } file.`,
        );
        e.target.value = '';
        return;
      }

      if (selectedType === 'image' || selectedType === 'logo') {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          const aspectRatio = width / height;

          // Define the acceptable aspect ratio range
          // const minAspectRatio = 4 / 5; // 0.8
          // const maxAspectRatio = 16 / 9; // 1.77

          if (selectedType === 'image') {
            if (width >= 580) {
              setFileImage(file);
              setFileError(''); // Clear error message
            } else {
              setFileError('Image must have a minimum width of 580px.');
              e.target.value = ''; // Clear input field
            }
          } else if (selectedType === 'logo') {
            if (width >= 500) {
              setFileImage(file);
              setFileError(''); // Clear error message
            } else {
              setFileError('Image must have a minimum width of 500px.');
              e.target.value = ''; // Clear input field
            }
          }
        };

        img.onerror = () => {
          setFileError('Invalid image. Please select a valid image file.');
          e.target.value = ''; // Note: Clearing input this way may not work in React
        };
      } else {
        // Handle video file & validation
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);

        video.onloadedmetadata = () => {
          const width = video.videoWidth;
          if (width < 360) {
            setFileError('Video must have a minimum width of 360px.');
            e.target.value = '';
          } else {
            setFileImage(file);
          }
        };

        video.onerror = () => {
          setFileError('Invalid video. Please select a valid video file.');
          e.target.value = ''; // Note: Clearing input this way may not work in React
        };
      }
    }
  };

  // Function to handle the change in the dropdown selection
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value as MIMEType);
  };
  const handleInput = (e) => {
    const file = e.target.value;
    const formattedValue = file.toLowerCase().replace(/ /g, '_');
    setName(formattedValue);
  };

  const Wrapper =
    window.location.pathname === '/media' ? DefaultLayout : Fragment;
  return (
    <Wrapper>
      {mediaType ? null : <h1>Media</h1>}
      <>
        {!isQuickLaunched && (
          <Suspense fallback={<BrandAccountSelector.Loading />}>
            <BrandAccountSelector qAccounts={brands} />
          </Suspense>
        )}
        <FileUploader
          handleInput={handleInput}
          handleTypeChange={handleTypeChange}
          selectedType={selectedType}
          handleImageVideoSelect={handleImageVideoSelect}
          fileInputRef={fileInputRef}
          uploadedImage={uploadedImageUrl}
          resetInput={resetInput}
          mediaType={mediaType}
          handleFileUpload={handleFileUpload}
          fileImage={fileImage}
          isQuickLaunched={isQuickLaunched}
          isLoading={isLoading}
          uploadStatus={uploadStatus}
          statusVisibility={statusVisibility}
          nameError={nameError}
          fileError={fileError}
          browseMedia={onMediaReady ? (bool) =>
            curAccount ? setBrowseMedia(bool) : alert('Please select a brand')
          : null}
        />
        {browseMedia && galleryImage && (
          <>
            <MediaUploadGallery
              galleryImages={galleryImage}
              brandAccount={curAccount.name}
              disableUploadMore={false}
              isSelected={isSelected}
              fileInputRef={fileInputRef}
              browseMedia={(boolean) => setBrowseMedia(boolean)}
              onMediaReady={onMediaReady}
              isMediaSelected={isSelected}
            />
            ,
          </>
        )}
      </>
    </Wrapper>
  );
}
