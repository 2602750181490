import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import React,{Suspense, useContext, useEffect, useState} from 'react';
import {AccountCard} from 'titan-ads/lib/components/AccountCard';
import {List} from 'titan-ads/lib/components/List';
import {Ad,AdSet,BrandAccount,Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {QuoraAccount} from 'titan-ads/lib/shapes/QuoraAccount';
import {CreateNewAccount} from '../components/CreateNewAccount';
import {Spinner} from '../components/Spinner';
import {ThemeContext} from '../contexts/Theme';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useSuspense} from '../utils';
import './Accounts.scss';
import * as styles from './Accounts.scss.json';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import { packageName } from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';


export default function Proxies() {

  const [proxies, setProxies] = useState<any>(null);
  useEffect(() => {
    Server.call(packageName, "getProxyStatus").then((proxies) => {
      setProxies(proxies);
    });
  },[]);
  const testProxy = async (proxy) => {
    setProxies(proxies.map((p) => {
        if(p.port === proxy.port) {
          return {...p, status:'testing...'};
        }
        return p;
      })
    );

    await Server.call(packageName, "testProxy", proxy).then((updatedProxy) => {
      //update proxy state by replacing previous entry
      setProxies(proxies.map((p) => {
          if(p.port === proxy.port) {
            return {...p, ...updatedProxy};
          }
          return p;
        })
      );
      if(updatedProxy.message) {
        alert(proxy.message);
      }
    });
  }
  const updateProxies = async () => {

    if(!proxies) return;
    //test the proxies one by one
    for(const proxy of proxies) {
      await testProxy(proxy);
      //wait 3 sec
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  }

  return (
    <DefaultLayout>
      <h1>Current Proxies</h1>
      {proxies ? <>
        <table cellPadding={5}>
          <thead>
            <tr style={{textAlign:'left'}}>
              <th>Username</th>
              <th>Port</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {proxies.map((proxy,index) => {
            return <ProxyCard key={index} active={index === 0} proxy={proxy} onTest={testProxy.bind(null,proxy)} />
          })}
          </tbody>
        </table>
      </> : <Spinner />}
      <button onClick={updateProxies}>Test & update all</button>
    </DefaultLayout>
  );
}
const ProxyCard = ({proxy,active,onTest}) => {
  let color;
  if(proxy.status === 'active' ) {
    color = 'green';
  }
  if(proxy.status === 'blocked') {
    color = 'red';
  }
  if(proxy.status === 'available') {
    color = 'blue';
  }
  return <tr key={proxy.id}>
    <td>{proxy.username}</td>
    <td>{proxy.port}</td>
    <td style={{color:color}}>{proxy.status}</td>
    <td><a href={'#'} onClick={onTest}>↻</a></td>
  </tr>

}
