import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {BrandAccount} from 'titan-ads/lib/shapes/TitanShapes';
import {Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {
  AdInputPartial,
  InputType,
  InputValues,
  Platform,
  QuoraAdType,
  QuoraCTA,
  QuoraCTAEnum,
} from 'titan-ads/lib/types';

// For reducer action
type ActionType =
  | 'added_group'
  | 'added_input'
  | 'edited_account'
  | 'edited_ad_set'
  | 'edited_campaign'
  | 'edited_input'
  | 'edited_platform'
  | 'marked_as_duplicate'
  | 'marked_as_creating_ad_set'
  | 'marked_as_creating_campaign'
  | 'removed_all_groups'
  | 'removed_group';

export type GroupID = number;
export type InputID = number;
// export type InputType = string[];
// export type InputGroup = Map<GroupID, InputType>;

/**
 *    E = Email (both mobile & desktop)
 *
 *    DF = Desktop Feed
 *
 *    DQ = Desktop Question
 *
 *    MF = Mobile Feed
 *
 *    MQ = Mobile Question
 */
export const AdSetPrefixes = <const>['E', 'DF', 'DQ', 'MF', 'MQ'];
export type AdSetPrefix = (typeof AdSetPrefixes)[number];

export type SuspenseStatus = 'error' | 'pending' | 'success';

/**
 *
 *
 *
 * QUORA TYPES
 *
 *
 *
 */
export type SetStateValue = (value: any, datakey: any) => void;

/**
 * GENERAL CONST
 */
/**
 *
 * @param idToTest If supplied, the number to test within a range (LB <= idToTest <= UB)
 * @returns [Lower Bound, Upper Bound]
 */
export const CUSTOM_CAMPAIGN_ID_THRESHOLD = (
  idToTest?: number,
): [number, number] | boolean => {
  const LB = 1000;
  const UB = 10000;
  if (Number.isInteger(idToTest)) {
    return idToTest >= LB && idToTest <= UB;
  }

  return [LB, UB];
};
export const CUSTOM_ADSET_ID_THRESHOLD = CUSTOM_CAMPAIGN_ID_THRESHOLD;

/**
 * Default dropdown options
 */
export const DefaultCampaignDropdownOptions = <const>[
  'Choose a campaign',
  'Create a new campaign',
];
export type DefaultCampaignDropdownOption =
  (typeof DefaultCampaignDropdownOptions)[number];
export const CHOOSE_CAMPAIGN = (typeof DefaultCampaignDropdownOptions)[0];
export const CREATE_CAMPAIGN = (typeof DefaultCampaignDropdownOptions)[1];

export const DefaultAdSetDropdownOptions = <const>[
  'Choose an adset',
  'Create a new adset',
];
export type DefaultAdSetDropdownOption =
  (typeof DefaultAdSetDropdownOptions)[number];
export const CHOOSE_ADSET = (typeof DefaultAdSetDropdownOptions)[0];
export const CREATE_ADSET = (typeof DefaultAdSetDropdownOptions)[1];

export type DropdownState = 'loading' | 'not_chosen' | 'chosen';

export const MIMETypes = <const>['image', 'logo', 'video'];
export type MIMEType = (typeof MIMETypes)[number];

interface TextVariation {
  id: number;
  text: string;
  description?: string;
  businessName?: string;
}

interface CTAVariation extends TextVariation {
  text: QuoraCTA;
}

interface LogoVariation {
  id: number;
  uri:string;
  logoId: string;
  quoraCDN: string;
  type: 'logo';
  name: string;
}

interface MediaVariation {
  id: number;
  uri?:string;
  logoId?: any;
  quoraCDN: string;
  type: Exclude<MIMEType, 'logo'>;
  name?: string;
}

export interface VariationContent {
  answer: TextVariation[];
  headlines: TextVariation[];
  subHeadlines: TextVariation[];
  cta: CTAVariation[];
  logo: LogoVariation[];
  media: MediaVariation[];
}

export interface AdVariationOutput {
  adSetId: number;
  name: string;
  adType: QuoraAdType;
  businessName: string;
  title: string;
  contentText: string;
  cta: QuoraCTAEnum;
  urlTemplate: string;
  answerLinkUtmsTemplate: string;
  imageUrl: string;
  videoHash: string;
  answerUrl: string;
  logoId: number;
  leadGenFormId: number | null;
  thirdPartyTrackerList: string[];
}

/**
 * REDUCERS
 */
export interface ReducerAction {
  type: ActionType;

  account?: BrandAccount;
  campaign?: Campaign;
  adSet?: AdSet;
  defaultInputs?: InputValues;
  groupId?: GroupID;
  inputId?: InputID;
  isCreatingCampaign?: boolean;
  isCreatingAdSet?: boolean;
  partial?: AdInputPartial;
  pasted?: boolean;
  platform?: Platform;
  value?: InputType;
}

/**
 * CONTEXTS
 */
export interface InputGroup {
  account: BrandAccount;
  adSet?: AdSet;
  campaign?: Campaign;
  creatingCampaign: boolean;
  creatingAdSet: boolean;
  duplicate: boolean;
  inputs: InputValues;
  pasted: boolean;
  platform: Platform;
}

export interface InputGroups {
  [key: GroupID]: InputGroup;
}

export interface IAdInputsContext {
  handleAddGroup: (
    groupId: GroupID,
    platform: Platform,
    defaultInputs: InputValues,
    campaign?: Campaign,
    adSet?: AdSet,
  ) => void;
  handleAddInput: (
    groupId: GroupID,
    partial: AdInputPartial,
    value: InputType,
  ) => void;
  handleEditAccount: (groupId: GroupID, account: BrandAccount) => void;
  handleEditAdSet: (groupId: GroupID, adSet: AdSet) => void;
  handleEditCampaign: (groupId: GroupID, campaign: Campaign) => void;
  handleEditInput: (
    groupId: GroupID,
    partial: AdInputPartial,
    value: InputType,
  ) => void;
  handleEditPlatform: (groupId: GroupID, platform: Platform) => void;
  handleMarkAsDuplicate: (groupId: GroupID) => void;
  handleMarkAsCreatingCampaign: (groupId: GroupID) => void;
  handleMarkAsCreatingAdSet: (
    groupId: GroupID,
    isCreatingAdSet?: boolean,
  ) => void;
  handleRemoveAllGroups: () => void;
  handleRemoveGroup: (groupId: GroupID) => void;
  inputGroups: InputGroups;
}

export interface IDashboardContext {
  accountsLoading: boolean;
  adSetsLoading: boolean;
  campaignsLoading: boolean;
  curAccount: BrandAccount | undefined;
  masterUrl: string;
  setCurAccount: React.Dispatch<React.SetStateAction<BrandAccount>>;
  setMasterUrl: React.Dispatch<React.SetStateAction<string>>;
  setLandingPageName: React.Dispatch<React.SetStateAction<string>>;
  landingPageName: string;
  startAccountTransition;
  startAdSetsTransition;
  startCampaignsTransition;
}

export interface IAdInputProps {
  className: string;
  disabled?: boolean;
  groupId: number;
  handleNextPriority?: (
    curPriority?: number,
    setToCurPriority?: boolean,
  ) => void;
  id: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  initialValue?: InputType;
  inputData: AdInputPartial;
  keys?: (number | string)[];
}

export interface InputFieldProps {
  className: string;
  disabled?: boolean;
  groupId: number;
  handleNextPriority: (
    curPriority?: number,
    setToCurPriority?: boolean,
  ) => void;
  id: number;
  initialValue?: InputType;
  inputPartial: AdInputPartial;
  keys?: (number | string)[];
}

export interface ImageObject {
  creditText: string;
  url: string;
  usageInfo: string;
  contentUrl: string;
  identifier: number;
  dateCreated: string;
  extension: 'jpg' | 'jpeg' | 'png' | 'mp4/mov';
  videoHash?: string;
}

export interface MediaGalleryAsset {
  data: ImageObject;
}

/**
 * TimeRangeProps use on ShapeTable for filters by time range
 */
export interface TimeRangeProps {
  value: string;
  startDate: string;
  endDate: string;
}