import React,{useEffect, useState} from 'react';
import Modal from './Modal';
import './ActionDropdown.scss';
import style from './ActionDropdown.scss.json';
import {Spinner} from './Spinner';
import { ActionField } from '../hooks/useBatchActions';

// interface ActionField
// {
//   name: string;
//   label: string;
//   type: string;
//   placeholder?: string;
//   options?: {label: string;value: string}[]; // For select fields
// }

interface ActionConfig
{
  value: string;
  method?: string; // Optional method field
  label: string;
  fields: ActionField[];
  onSubmit: (formData: {[key: string]: any},selectedAction) => Promise<boolean>; // Accept additionalData
}

export interface SelectedAction
{
  value: string;
  method: string;
}

interface ApproveActionModalProps
{
  actions: ActionConfig[];
  additionalData?: any; // New prop to accept additional data
  selectedRows?: string[];
  onComplete?: (value?:any) => void;
  buttons?: boolean;
  showApprovalModal?: {isModalOpen:boolean,batchAction:string, matchedAction: string};
}

const ApproveActionModal: React.FC<ApproveActionModalProps> = ({
                                                                 actions,
                                                                 additionalData,
                                                                 buttons,
                                                                 selectedRows,
                                                                 onComplete,
                                                                 showApprovalModal,
                                                               }) => {
  const [selectedBatchAction,setSelectedBatchAction] = useState<string>('');
  const [selectedAction,setSelectedAction] = useState<SelectedAction>();
  const [showModal,setShowModal] = useState<boolean>(false);
  const [formData,setFormData] = useState<{[key: string]: any}>({});
  const [isSubmitting,setIsSubmitting] = useState<boolean>(false); // Add spinner state

  const loadFormDataFromLocalStorage = (selectedAction) => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData)
    {
      const parsedData = JSON.parse(savedFormData);

      setFormData((prev) => ({
        ...prev,
        [selectedAction]: {
          ...prev[selectedAction] || {},
          userName: parsedData.userName,
        },
      }));
    }
  };

  useEffect(() => {
    if (showApprovalModal?.isModalOpen) {
      try {
        const matchedAction = JSON.parse(showApprovalModal.matchedAction);
        selectAction(matchedAction);
        setSelectedBatchAction(matchedAction.value);
        loadFormDataFromLocalStorage(matchedAction.value);
        setShowModal(showApprovalModal.isModalOpen);
      } catch (error) {
        console.error("Invalid JSON string in showApprovalModal.matchedAction", error);
      }
    }
  }, [showApprovalModal]);

  const handleActionButton = (selectedAction) => {
    selectAction(selectedAction);
  }
  const handleActionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;

    // Parse the JSON string into an object
    const selectedAction = JSON.parse(selectedValue);

    selectAction(selectedAction);
    setSelectedBatchAction(selectedAction.value); // Set selected value
  }
  const selectAction = (selectedAction) => {
    setSelectedAction(selectedAction);
    loadFormDataFromLocalStorage(selectedAction.value); // Load saved form data
    setShowModal(true);
  };

  const handleInputChange = (actionValue: string,e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const {name,value} = e.target;
    setFormData((prev) => ({
      ...prev,
      [actionValue]: {
        ...prev[actionValue],
        [name]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const action = actions.find((a) => a.value === selectedAction?.value);

    if (action)
    {
      setIsSubmitting(true); // Start spinner
      try
      {
        const submitAction = await action.onSubmit(formData[action.value],selectedRows);

        if (onComplete)
        {
          onComplete(); // Trigger onComplete after successful submit
        }

          if (submitAction) {
            alert(`Successfully ${action.label.toLowerCase()}d ${selectedRows?.length} action(s)`);
          }
        } catch (error) {
          console.error('Error during backend call:', error);
        } finally {
          setIsSubmitting(false); // Stop spinner
          handleCloseModal(); // Close modal after submission
        }
    }

    // Destructuring to save to localStorage
    const filteredLocalData = {
      userName: formData[action.value]?.userName,
    };
    localStorage.setItem('formData',JSON.stringify(filteredLocalData));

    // Reset state after handling all actions
    setSelectedAction(null);
    setFormData({});
    setShowModal(false);
    setSelectedBatchAction('');
  };

  // const handleSubmit = async () => {
  //   const action = actions.find((a) => a.value === selectedAction?.value);

  //   if (action) {
  //     setIsSubmitting(true); // Start spinner
  //     try {
  //       // Wait for the backend call to complete
  //       await action.onSubmit(formData[action.value], selectedRows);
  //       // Notify success and refresh table
  //       showNotification(`Successfully executed ${selectedRows.length} actions.`);
  //       refreshTable(); // Refresh the table data
  //     } catch (error) {
  //       console.error('Error during backend call:', error);
  //     } finally {
  //       setIsSubmitting(false); // Stop spinner
  //       handleCloseModal(); // Close modal after submission
  //     }
  //   }
  // };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAction(null);
    setFormData({});
    setSelectedBatchAction('');
  };

  const currentActions = actions?.filter((a) => selectedAction?.value === a.value);

  return (
    <>
      {buttons ? <>
          {actions?.map((action) => (
            // Serialize the action object as a JSON string
            <>
              <input type={'button'} value={action.label} onClick={handleActionButton.bind(this,action)} />&nbsp;
            </>
          ))}
        </> :
        <select className={style.batchActions} onChange={handleActionChange} value={selectedBatchAction}>
          <option value="">Select Action</option>
          {actions?.map((action) => (
            // Serialize the action object as a JSON string
            <option key={action.value} value={JSON.stringify({
              value: action.value,
              label: action.label,
              method: action.method,
            })}>
              {action.label}
            </option>
          ))}
        </select>}

      {showModal && currentActions?.length > 0 && (
        <Modal show={showModal} onClose={handleCloseModal} title={`${currentActions[0].label} the selected ${selectedRows?.length} changes ?`}>
          <form className={style.actionForm} onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            {currentActions?.map((currentAction) => (
              <div key={currentAction.value}>
                {/* <h3>{currentAction.label}</h3> */}
                {currentAction.fields.map((field) => (
                  <div key={field.name} className={style.formGroup}>
                    <label htmlFor={field.name}>
                      {typeof field.label === 'function' ? 
                          field.label(formData[currentAction.value] || {})
                          : field.label}
                    </label>
                    <div>
                      {field.type === 'select' && (
                        <select
                          id={field.name}
                          name={field.name}
                          className={style.selectInput}
                          value={formData[currentAction.value]?.[field.name] || ''}
                          onChange={(e) => handleInputChange(currentAction.value,e)}
                          required
                        >
                          <option value="">Select {typeof field.label === 'function' ? 
                                  field.label(formData[currentAction.value] || {})
                                  : field.label}</option>
                          {field.options?.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}

                      {field.type === 'textarea' && (
                        <textarea
                          className={style.textareaInput}
                          id={field.name}
                          name={field.name}
                          value={formData[currentAction.value]?.[field.name] || ''}
                          onChange={(e) => handleInputChange(currentAction.value,e)}
                          placeholder={
                            typeof field.placeholder === 'function'
                              ? field.placeholder(formData[currentAction.value] || {})
                              : field.placeholder || 'Type here'
                          }
                        />
                      )}

                      {field.type === 'text' && (
                        <input
                          id={field.name}
                          name={field.name}
                          type={field.type}
                          placeholder={
                            typeof field.placeholder === 'function'
                              ? field.placeholder(formData[currentAction.value] || {})
                              : field.placeholder || 'Type here'
                          }
                          value={formData[currentAction.value]?.[field.name] || ''}
                          onChange={(e) => handleInputChange(currentAction.value,e)}
                          className={style.textInput}
                          required
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className={style.buttonGroup}>
              <button type="button" onClick={handleCloseModal}>
                Cancel
              </button>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    Submitting... <Spinner />
                  </>
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default ApproveActionModal;
